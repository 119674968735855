import { ActionType } from "../actionTypes/index";

const initialState = {};

const videosReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case ActionType.GET_VIDEOS_CUSTOMIZATION_REQUESTS:
      return {
        ...state,
        type: action.type,
        payload: action.payload,
      };
    case ActionType.GET_VIDEOS_CUSTOMIZATION_REQUESTS_ERROR:
      return {
        ...state,
        type: action.type,
        payload: action.payload,
      };
    case ActionType.GET_VIDEOS_CUSTOMIZATION_REQUEST_DETAIL:
      return {
        ...state,
        type: action.type,
        payload: action.payload,
      };
    case ActionType.GET_VIDEOS_CUSTOMIZATION_REQUEST_DETAIL_ERROR:
      return {
        ...state,
        type: action.type,
        payload: action.payload,
      };

    default:
      return state;
  }
};

export default videosReducer;
