/* eslint-disable */

import Theme from "components/theme";
import { useEffect, useState } from "react";
import { currentUser } from "services/user";
import {
    FilterIcon,
    PlusIcon,
    TrashIcon,
    UserIcon,
    ArrowUpIcon,
    ArrowDownIcon,
    PencilIcon,
} from "@heroicons/react/outline";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import NewPracticeModal from "components/modals/new_practice";
import config from "config/config";
import moment from "moment-timezone";
import { CurrencyDollarIcon } from "@heroicons/react/solid";
import { getAnncouncements } from "services/announcements";
import NewAnnouncementModal from "components/modals/new_announcement";
import UpdateAnnouncementModal from "components/modals/update_announcement";


const AnnouncementsPage = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const [per_page, setPerPage] = useState(20);

    const [announcements, setAnnouncements] = useState([]);

    const [total, setTotal] = useState(0);

    const [modal, setModal] = useState(false);

    const [updateModal, setUpdateModal] = useState(false);

    const [data, setData] = useState(false);


    const handlePageClick = (event: any) => {
        console.log(event.selected + 1);

        setCurrentPage(event.selected + 1);
    };

    const fetchAnnouncements = async () => {
        console.log("Current Page: ", currentPage);

        const {
            data: { results, total },
        } = await getAnncouncements({
            page: currentPage,
            per_page,
        });

        console.log("=== Announcements ===");

        console.log(results, total)

        setAnnouncements(results);
        setTotal(total);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps

    useEffect(() => {

        console.log("=== Announcements ===");
        setLoading(true);

        const pagination = async () => {
            await fetchAnnouncements();
            setLoading(false);
            return;
        };
        pagination();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    console.log("Loading : ", loading);

    return (
        <>
            <Theme>
                <div className="px-4 sm:px-6 lg:px-8 mt-10">
                    <div className="sm:flex sm:items-center mb-5">
                        <div className="sm:flex-auto">
                            <h1 className="text-xl font-semibold text-gray-900">All Announcements</h1>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            <button
                                onClick={() => {
                                    setModal(true);
                                }}
                                type="button"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                            >
                                <PlusIcon className="mr-2 -ml-0.5 h-4 w-4" aria-hidden="true" />
                                New Announcement
                            </button>
                        </div>
                    </div>
                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50 ch-custom-thead">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="ch-custom-th py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                                >
                                                    Announcements

                                                </th>


                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >

                                                </th>

                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                >
                                                    Created On
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                                >
                                                    <span className="sr-only">Actions</span>
                                                </th>





                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {loading === true ? (
                                                <>
                                                    <tr>
                                                        <td colSpan={12}>
                                                            <p className="text-center p-10">
                                                                <strong> Loading... </strong>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </>
                                            ) : (
                                                <>
                                                    {announcements?.length ? (
                                                        <>
                                                            {announcements?.map((x: any) => (
                                                                <tr key={x.id}>
                                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ch-custom-td " colSpan={2}>
                                                                        <div className="flex items-center">
                                                                            <div className="h-11 w-11 flex-shrink-0">
                                                                                <img className="h-11 w-11 rounded-full" src={x.image} alt="" />
                                                                            </div>
                                                                            <div className="ml-4">
                                                                                <div className="font-medium text-gray-900">{x.title}</div>
                                                                                <div className="mt-1 text-gray-500 Class
Properties
whitespace-normal">{x.description}</div>
                                                                            </div>
                                                                        </div>
                                                                    </td>

                                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-bold">
                                                                            {x.created_at
                                                                                ? moment
                                                                                    .utc(x.created_at)
                                                                                    .local()
                                                                                    .startOf("seconds")
                                                                                    .fromNow()
                                                                                : "Not available"}
                                                                        </td>

                                                                        <button
                                                                            type="button"
                                                                            onClick={() => {

                                                                                setData(x);
                                                                                setUpdateModal(true);
                                                                            }}

                                                                            className="ml-3 inline-flex items-center rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                                                        >
                                                                            Edit
                                                                            <PencilIcon
                                                                                className="ml-1 -mr-0.5 h-4 w-4"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </button>

                                                            

                                                                    </td>





                                                                </tr>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <>
                                                                <p className="text-center justify-center">
                                                                    {" "}
                                                                    <strong> No records found. </strong>
                                                                </p>
                                                            </>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Pagination  */}

                    {loading === false && (
                        <>
                            <ReactPaginate
                                className="mt-5 w-100 mb-7"
                                previousClassName="g-white border-gray-300 text-gray-500 hover:bg-blue-200 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                activeClassName="bg-blue border-red-300 text-red-500 hover:bg-blue-200 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                pageClassName="g-white border-gray-300 text-gray-500 hover:bg-blue-200 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                nextClassName="g-white border-gray-300 text-gray-500 hover:bg-blue-200 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                breakClassName="g-white border-gray-300 text-gray-500 hover:bg-blue-200 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                disabledClassName="g-white border-gray-300 text-gray-500 hover:bg-blue-200 relative inline-flex items-center px-4 py-2 border text-sm font-medium disabled:opacity-50"
                                breakLabel="..."
                                nextLabel="next >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                pageCount={Math.ceil(total / per_page)}
                                previousLabel="< previous"
                                renderOnZeroPageCount={null}
                            />
                        </>
                    )}
                </div>

                <NewAnnouncementModal
                    modal={modal}
                    setModal={setModal}
                    fetchAnnouncements={fetchAnnouncements}
                />

                <UpdateAnnouncementModal
                    modal={updateModal}
                    setModal={setUpdateModal}
                    fetchAnnouncements={fetchAnnouncements}
                    announcement={data}
                />
            </Theme>
        </>
    );
};

export default AnnouncementsPage;
