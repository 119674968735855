import { useEffect, useState } from 'react';
import { Switch } from 'lib/react-switch';
import { Button, Input } from 'components';
import {
  CustomInput,
  useVideo,
  useVideoActions,
  AddButton,
} from 'features/video-to-library';

const OptionSelect = () => {
  const [fields, setFields] = useState<CustomInput>(null);
  const [descriptionError, setDescriptionError] = useState('');
  const [optionError, setOptionError] = useState('');
  const { expanded, customItems } = useVideo();
  const { setExpanded, setInput } = useVideoActions();

  const setOption = (option: string, index: number) => {
    const options = fields.options.map((opt, i) => {
      if (i === index) return option;
      return opt;
    });
    setFields({ ...fields, options });
  };

  const addOption = () =>
    setFields({ ...fields, options: [...fields.options, ''] });

  const save = () => {
    if (!fields.description || !fields.options[0]) {
      if (!fields.description) setDescriptionError('Description is required');
      if (!fields.options[0]) setOptionError('At least 1 option is required');
      return;
    }

    const options = fields.options.filter((option) => option !== '');
    if (expanded) setInput(expanded, { ...fields, options });
    setExpanded(false);
  };

  useEffect(
    () =>
      setFields(
        customItems.find(({ id, type }) => id === expanded && type === 'select')
      ),
    [expanded, customItems]
  );

  useEffect(() => {
    if (fields?.description) setDescriptionError('');
    if (fields?.options[0]) setOptionError('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields?.description, fields?.options]);

  if (fields)
    return (
      <>
        <Input
          label='Description.'
          helper='i.e / Choose a teeth whitening product you offer'
          placeholder='Write text here'
          value={fields.description}
          onChange={(e) =>
            setFields({ ...fields, description: e.target.value })
          }
          errorMessage={descriptionError}
        />
        {fields?.options.map((option, index) => (
          <Input
            key={index}
            label={`Option ${index + 1}`}
            placeholder='Write option here'
            value={option}
            onChange={(e) => setOption(e.target.value, index)}
            errorMessage={index === 0 ? optionError : ''}
          />
        ))}
        <AddButton onClick={addOption} />
        <div className='flex gap-8'>
          <Switch
            checked={fields.is_multiselect}
            onChange={() =>
              setFields({ ...fields, is_multiselect: !fields.is_multiselect })
            }
            label='Multi-select?'
          />
          {fields.is_multiselect && (
            <>
              <Input
                label='Min'
                placeholder='1'
                type='number'
                className='max-w-[151px]'
                min={0}
                value={fields.min_options}
                onChange={(e) =>
                  setFields({ ...fields, min_options: e.target.valueAsNumber })
                }
              />
              <Input
                label='Max'
                placeholder='3'
                type='number'
                className='max-w-[151px]'
                min={0}
                value={fields.max_options}
                onChange={(e) =>
                  setFields({ ...fields, max_options: e.target.valueAsNumber })
                }
              />
            </>
          )}
        </div>
        <Switch
          label='Mandatory'
          checked={fields.is_required}
          onChange={() =>
            setFields({ ...fields, is_required: !fields.is_required })
          }
        />
        <Button className='w-full' onClick={save}>
          Save and Continue
        </Button>
      </>
    );
};

export default OptionSelect;
