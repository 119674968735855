import { useEffect, useState } from 'react';
import { Switch } from 'lib/react-switch';
import { Button, Checkbox, Input, Label } from 'components';
import {
  CustomInput,
  useVideo,
  useVideoActions,
} from 'features/video-to-library';

const NewTeam = () => {
  const [fields, setFields] = useState<CustomInput>(null);
  const [textError, setTextError] = useState('');
  const [requiredError, setRequiredError] = useState('');
  const { expanded, customItems } = useVideo();
  const { setExpanded, setInput } = useVideoActions();

  const save = () => {
    if (
      !fields.description ||
      (!fields.is_photo_required &&
        !fields.is_name_required &&
        !fields.is_position_required)
    ) {
      if (!fields.description) setTextError('Text for team is required');
      if (
        !fields.is_photo_required &&
        !fields.is_name_required &&
        !fields.is_position_required
      )
        setRequiredError('At least 1 item is required');
      return;
    }
    if (expanded) setInput(expanded, fields);
    setExpanded(false);
  };

  useEffect(
    () =>
      setFields(
        customItems.find(({ id, type }) => id === expanded && type === 'member')
      ),
    [expanded, customItems]
  );

  useEffect(() => {
    if (fields?.description) setTextError('');
    if (
      fields?.is_photo_required ||
      fields?.is_name_required ||
      fields?.is_position_required
    )
      setRequiredError('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fields?.description,
    fields?.is_photo_required,
    fields?.is_name_required,
    fields?.is_position_required,
  ]);

  if (fields)
    return (
      <>
        <Input
          label='Text for team member request.'
          helper='i.e / Choose a hygienic who does whitening.'
          placeholder='Write text here'
          value={fields.description}
          onChange={(e) =>
            setFields({ ...fields, description: e.target.value })
          }
          errorMessage={textError}
        />
        <div className='space-y-3'>
          <Label helper='Can choose multiple'>What's required?</Label>
          <div className='space-y-1.5 w-fit'>
            <div className='flex gap-4'>
              <Checkbox
                label='Photo'
                value={fields.is_photo_required}
                onChange={(e) =>
                  setFields({ ...fields, is_photo_required: e.target.checked })
                }
              />
              <Checkbox
                label='Name'
                value={fields.is_name_required}
                onChange={(e) =>
                  setFields({ ...fields, is_name_required: e.target.checked })
                }
              />
              <Checkbox
                label='Position'
                value={fields.is_position_required}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    is_position_required: e.target.checked,
                  })
                }
              />
            </div>
            {requiredError && (
              <p className='text-accent-700 text-sm font-bold font-karla text-end'>
                {requiredError}
              </p>
            )}
          </div>
        </div>
        <div className='flex gap-8'>
          <Switch
            checked={fields.is_multiselect}
            label='Multi-select?'
            onChange={() =>
              setFields({ ...fields, is_multiselect: !fields.is_multiselect })
            }
          />
          {fields.is_multiselect && (
            <>
              <Input
                label='Min'
                placeholder='1'
                type='number'
                className='max-w-[151px]'
                min={0}
                value={fields.min_members_required}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    min_members_required: e.target.valueAsNumber,
                  })
                }
              />
              <Input
                label='Max'
                placeholder='3'
                type='number'
                className='max-w-[151px]'
                min={0}
                value={fields.max_members_required}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    max_members_required: e.target.valueAsNumber,
                  })
                }
              />
            </>
          )}
        </div>
        <Switch
          label='Mandatory'
          checked={fields.is_required}
          onChange={() =>
            setFields({ ...fields, is_required: !fields.is_required })
          }
        />
        <Button className='w-full' onClick={save}>
          Save and Continue
        </Button>
      </>
    );
};

export default NewTeam;
