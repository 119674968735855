import { useEffect, useState } from 'react';
import { Card, Divider, Input, Textarea, Title } from 'components';
import { Option, Select } from 'lib/react-select';
import { Switch } from 'lib/react-switch';
import { DatePicker } from 'lib/shadcn';
import { getNextDay } from 'util/get-next-day';
import { getCountries, getVideoCategories } from 'redux/actionCreators/videos';
import { useVideo, useVideoActions } from './stores';

const Overview = () => {
  const {
    name,
    description,
    primaryCategory,
    secondaryCategory,
    isCountrySpecific,
    country,
    isDateSpecific,
    startDate,
    endDate,
  } = useVideo();
  const {
    setName,
    setDescription,
    setPrimaryCategory,
    setSecondaryCategory,
    setIsCountrySpecific,
    setCountry,
    setIsDateSpecific,
    setStartDate,
    setEndDate,
  } = useVideoActions();
  const [categories, setCategories] = useState<Option[]>([]);
  const [countries, setCountries] = useState<Option[]>([]);

  useEffect(() => {
    const init = async () => {
      const _categories = await getVideoCategories();
      setCategories(
        _categories.data.results.map(({ category_id, category_name }) => ({
          value: category_id,
          label: category_name,
        }))
      );

      const _countries = await getCountries();
      setCountries(
        _countries.data.results.map(({ code, name }) => ({
          value: code,
          label: name,
        }))
      );
    };
    init();
  }, []);

  return (
    <Card>
      <Title size='lg' className='py-5 px-7'>
        Overview
      </Title>
      <Divider />
      <div className='py-5 px-7 space-y-[26px]'>
        <Input
          label='Video Name'
          placeholder='2023 Easter Chocolate Promo'
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Textarea
          label='Description'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Select
          label='Primary Category'
          onChange={(value) => setPrimaryCategory(value)}
          value={primaryCategory}
          options={categories}
        />
        <Select
          label='Secondary Category'
          isMulti
          onChange={(value) => setSecondaryCategory(value)}
          value={secondaryCategory}
          options={categories}
        />
        <div className='flex gap-8'>
          <Switch
            label='Country Specific'
            checked={isCountrySpecific}
            onChange={() => setIsCountrySpecific(!isCountrySpecific)}
            className='flex-shrink-0'
          />
          {isCountrySpecific && (
            <Select
              label='Select Country'
              className='w-full'
              onChange={(value) => setCountry(value)}
              value={country}
              options={countries}
              isMulti
            />
          )}
        </div>
        <div className='flex gap-[57px]'>
          <Switch
            label='Date Specific'
            checked={isDateSpecific}
            onChange={() => setIsDateSpecific(!isDateSpecific)}
          />
          {isDateSpecific && (
            <div className='flex gap-4 items-end'>
              <DatePicker
                label='Start Date'
                value={startDate}
                onChange={(date) => setStartDate(date)}
              />
              <p className='font-karla font-bold text-tailgrids-black mb-4'>
                to
              </p>
              <DatePicker
                label='End Date'
                value={endDate}
                onChange={(date) => setEndDate(date)}
                disabled={{ before: getNextDay(new Date(startDate)) }}
              />
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default Overview;
