import  axios from 'util/axios';
import  config from "config/config"


  export async function getAnncouncements(params) {
    return axios.get(`${config.defaults.api_url}/announcements`, { params })
  }


  export async function createAnncouncement(params) {
    return axios.post(`${config.defaults.api_url}/announcements/create`, params)
  }

  export async function updateAnncouncement(params , id) {
    return axios.post(`${config.defaults.api_url}/announcements/update/${id}`, params)
  }

  export async function deleteAnncouncement(id) {
    return axios.post(`${config.defaults.api_url}/announcements/delete/${id}`)
  }


