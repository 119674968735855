import { cn } from 'util/cn';

type PropsType = {
  className?: string;
};

const ChevronLeft = ({ className }: PropsType) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={cn('text-black', className)}
    >
      <g clipPath='url(#clip0_1061_6821)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.2377 5.90267C14.8105 6.43965 14.8105 7.3103 14.2377 7.84725L10.8748 11L14.2377 14.1527C14.8105 14.6897 14.8105 15.5603 14.2377 16.0973C13.665 16.6342 12.7363 16.6342 12.1636 16.0973L7.76356 11.9722C7.19079 11.4353 7.19079 10.5647 7.76356 10.0277L12.1636 5.90267C12.7363 5.36578 13.665 5.36578 14.2377 5.90267Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_1061_6821'>
          <rect
            width='7.33333'
            height='11'
            fill='white'
            transform='translate(7.33398 5.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChevronLeft;
