import { DragHandleProps } from 'react-beautiful-dnd';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { Body, Divider, ModalType, useModalActions } from 'components';
import { LinearMenuIcon, MiniTrashIcon } from 'icons';
import CustomInputDelete from './custom-input-delete';
import { components, icons, labels } from './constants';
import { CustomType } from './types';
import { cn } from 'util/cn';
import { useVideo, useVideoActions } from './stores';

type PropsType = {
  type: CustomType;
  id: string;
  dragHandleProps?: DragHandleProps;
};

const CustomInputItem = ({ type, id, dragHandleProps }: PropsType) => {
  const { trigger } = useModalActions();
  const { expanded } = useVideo();
  const { setExpanded } = useVideoActions();

  const Icon = icons[type];
  const Component = components[type];
  const isOpen = id === expanded;

  const customDeleteModal: ModalType = {
    component: <CustomInputDelete />,
    size: 611,
  };
  const triggerModal = (id: string) => {
    customDeleteModal.component = <CustomInputDelete id={id} />;
    trigger(customDeleteModal);
  };

  return (
    <div className='px-[15px] transition-all duration-300 ease-in-out'>
      <div className='flex justify-between py-5'>
        <div className='flex items-center gap-3.5'>
          <button {...dragHandleProps} onClick={() => setExpanded(false)}>
            <LinearMenuIcon className='text-[#637381]' />
          </button>
          <Icon className='text-black' />
          <Body size='lg'>{labels[type]}</Body>
        </div>
        <div className='flex items-center gap-2.5'>
          <button
            className='bg-[#F5F5F5] w-9 h-9 rounded-[5px] flex items-center justify-center'
            onClick={() => triggerModal(id)}
          >
            <MiniTrashIcon className='text-[#DC3545]' />
          </button>
          <button
            className='p-1 pr-0'
            onClick={() => setExpanded(isOpen ? false : id)}
          >
            <ChevronRightIcon
              className={cn(
                'text-[#0F172A] w-6 h-6 transition-all duration-200 ease-out',
                isOpen ? 'rotate-90' : 'rotate-0'
              )}
            />
          </button>
        </div>
      </div>
      <div
        className={cn(
          'transition-all duration-300 ease-in-out grid',
          isOpen ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'
        )}
      >
        <div className='overflow-hidden'>
          <Divider />
          <div className='py-5 space-y-[26px]'>
            <Component />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomInputItem;
