import { ReactNode } from 'react';
import { cn } from 'util/cn';

type PropsType = {
  className?: string;
  children?: ReactNode;
};

const Card = ({ className, children }: PropsType) => {
  return (
    <div className={cn('bg-white rounded-3xl border border-stroke', className)}>
      {children}
    </div>
  );
};

export default Card;
