/* eslint-disable */

import Theme from "components/theme";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  getPracticeAddress,
  getPracticeDetail,
  getPracticeVideos,
  getTeamMembers,
  updateAddress,
  updatePracticeBasicInfo,
  updatePracticeContactInfo,
} from "redux/actionCreators/practice";

import {
  ArrowLeftIcon as ArrowLongLeftIcon,
  CheckIcon,
  HandIcon as HandThumbUpIcon,
  UserIcon,
  ViewListIcon,
} from "@heroicons/react/solid";

import DetaultProfile from "assets/img/team-member-profile.png";

import DefaultThumbnail from "assets/img/default-thumbnail.jpg";

import moment from "moment-timezone";
import { getNotifications, impersonate } from "services/practices";
import Swal from "sweetalert2";
import { CurrencyDollarIcon, PlayIcon, SaveAsIcon, UploadIcon } from "@heroicons/react/outline";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ActionType } from "redux/actionTypes/index";
import LoadingOverlay from "react-loading-overlay";
import RecentActivityModal from "components/modals/recent_activity";
import { getPlaylist } from "services/videos";
import config from "config/config";
import VideoModal from "components/modals/video";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const eventTypes = {
  upload: { icon: UploadIcon, bgColorClass: "bg-blue-500" },
  completed: { icon: CheckIcon, bgColorClass: "bg-green-500" },
};

interface RootState {
  practice: any;
}

const EditPractice = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const state = useSelector((state: RootState) => state);

  console.log(state);

  const [practice, setPractice]: any = useState([]);

  const [loading, setLoading] = useState(false);

  const [teamMembers, setTeamMembers] = useState([]);
  const [files, setFiles] = useState([]);
  const [videos_in_playing, setVideosInPlaying] = useState([]);
  const [videos_in_production, setVideosInProduction] = useState([]);
  const [videos_in_customization, setVideosInCustomization] = useState([]);
  const [currentVideo, setCurrentVideo] = useState([]);




  useEffect(() => {

    const fetchVideos = async () => {

      const now_playing = await getPlaylist({
        practice_id: id,
        type: "NOW_PLAYING"
      });

      setVideosInPlaying(now_playing.data.results);

      const production_videos = await getPlaylist({
        practice_id: id,
        type: "IN_PRODUCTION"
      });

      setVideosInProduction(production_videos.data.results);

      const Customization_videos = await getPlaylist({
        practice_id: id,
        type: "NEEDS_CUSTOMIZATION"
      });

      setVideosInCustomization(Customization_videos.data.results);


    }

    fetchVideos();

  }, [])


  const dispatch = useDispatch();

  const [updating, setUpdating] = useState(false);

  const [updatingBasicInfo, setUpdatingBasicInfo] = useState(false);
  const [updatingContactInfo, setUpdatingContactInfo] = useState(false);
  const [updatingPhysicalAddress, setUpdatingPhysicalAddress] = useState(false);
  const [messageType, setMessageType] = useState(null);

  const [videoModal, setVideoModal] = useState(false);

  const [modal, setModal] = useState(false);

  const [message, setMessage] = useState(null);

  const [error, setError] = useState(null);

  const [videoUrl, setVideoUrl] = useState(null);

  const [videoTitle, setVideoTitle] = useState(null);

  const titles = [
    { name: "Dr" },
    { name: "Mr" },
    { name: "Ms" },
    { name: "Mrs" },
    { name: "Miss" },
  ];

  useEffect(() => {
    // Get Postal And Physcical Address
    setLoading(true);

    dispatch(getPracticeDetail(id));
    dispatch(getTeamMembers(id));
  }, [dispatch]);

  useEffect(() => {
    if (state.practice.type === "GET_PRACTICE_DETAIL") {
      setLoading(false);
      console.log("=== Practice Details ===");
      console.info(state.practice.payload.data.results);
      setPractice(state.practice.payload.data.results);

      // Get Postal And Physcical Address

      dispatch(
        getPracticeAddress(
          state?.practice?.payload?.data?.results?.PhysicalAddressID,
          "physical"
        )
      );

      setBasicInfoData({
        practice_code: state?.practice?.payload?.data?.results?.ChannelKey,
        user_title: state?.practice?.payload?.data?.results?.SubscriberTitle,
        user_first_name:
          state?.practice?.payload?.data?.results?.SubscriberFirstName,
        user_last_name:
          state?.practice?.payload?.data?.results?.SubscriberLastName,
        user_position:
          state?.practice?.payload?.data?.results?.SubscriberPosition,
        practice_name: state?.practice?.payload?.data?.results?.PracticeName,
        practice_email: state?.practice?.payload?.data?.results?.Email,
        user_phone: state?.practice?.payload?.data?.results?.Telephone,
        corp_name: state?.practice?.payload?.data?.results?.CorporateName,
      });

      setContactInfoData({
        countact_title: state?.practice?.payload?.data?.results?.ContactTitle,
        contact_first_name:
          state?.practice?.payload?.data?.results?.ContactFirstName,
        contact_last_name:
          state?.practice?.payload?.data?.results?.ContactLastName,
        contact_email: state?.practice?.payload?.data?.results?.ContactEmail,
        contact_phone:
          state?.practice?.payload?.data?.results?.ContactTelephone,
      });
    } else if (
      state.practice.type === ActionType.GET_PRACTICE_PHYSICAL_ADDRESS
    ) {
      setPhysicalAddress(state.practice.payload.data.results);

      setPhysicalAddress({
        country_1: state?.practice?.payload?.data?.results?.CountryName,
        zipcode_1: state?.practice?.payload?.data?.results?.Postcode,
        state_1: state?.practice?.payload?.data?.results?.State,
        address_1: state?.practice?.payload?.data?.results?.Street2,
        city_1: state?.practice?.payload?.data?.results?.Suburb,
        street_1: state?.practice?.payload?.data?.results?.Street1,
      });
    } else if (state.practice.type === ActionType.GET_TEAM_MEMBERS) {
      setTeamMembers(state.practice.payload.data.results);
    }
  }, [state]);

  const [userImpersonating, setUserImpersonating] = useState(false);

  const tabs = [
    {
      name: "Practice Details",
      href: "#",
      current: true,
    },
    {
      name: "Videos",
      href: "#",
      current: false,
    },
    {
      name: "Files",
      href: "#",
      current: false,
    },

    { name: "Team Members", href: "#", current: false },
  ];

  const [basicInfoData, setBasicInfoData]: any = useState({});
  const [contactInfoData, setContactInfoData]: any = useState({});
  const [physicalAddress, setPhysicalAddress]: any = useState([]);
  const [postalAddress, setPostalAddress]: any = useState([]);

  console.log("team members", teamMembers);

  const countries = [
    { id: 1, name: "Australia" },
    { id: 2, name: "USA" },
    { id: 3, name: "NZ" },
    { id: 4, name: "Australia" },

    { id: 5, name: "SG" },

    { id: 6, name: "UK" },

    { id: 7, name: "CA" },

    { id: 8, name: "Europe" },
  ];

  const [currentTab, setCurrentTab] = useState("Practice Details");

  const Initial_values_basic_info = {
    ...basicInfoData,
  };

  const initial_contact_info_data = {
    ...contactInfoData,
  };

  const initial_physical_address_data = {
    ...physicalAddress,
  };

  /***** UPDATE  *******/

  const updateBasicInfo = async (values: any) => {
    setUpdatingBasicInfo(true);

    setBasicInfoData({
      ...values,
    });

    await updatePracticeBasicInfo(
      {
        subscriber_title: values.user_title || "-",
        subscriber_first_name: values.user_first_name || "-",
        subscriber_last_name: values.user_last_name || "-",
        subscriber_position: values.user_position || "-",
        email: values.practice_email || "-",
        telephone: values.user_phone || "-",
        corp_name: values.corp_name || "-",
        practice_name: values.practice_name || "-",
      },
      practice?.ID
    );

    setMessage("Practice basic information updated successfully");
    setMessageType("basic_information");

    setUpdatingBasicInfo(false);
  };
  const updateContactInfo = async (values: any) => {
    setUpdatingContactInfo(true);

    setContactInfoData({
      ...values,
    });

    await updatePracticeContactInfo(
      {
        contact_title: values.contact_title || "-",
        contact_first_name: values.contact_first_name || "-",
        contact_last_name: values.contact_last_name || "-",
        contact_email: values.contact_email || "-",
        contact_phone: values.contact_phone || "-",
      },
      practice?.ID
    );

    setMessage("Practice contact information updated successfully");
    setMessageType("contact_information");

    setUpdatingContactInfo(false);
  };

  const updatePhysicalAddress = async (values: any) => {
    setUpdatingPhysicalAddress(true);

    setPhysicalAddress({
      ...values,
    });

    const address = await updateAddress(
      {
        street2: values.address_1 || "-",
        street1: values.street_1 || "-",
        state: values.state_1 || "-",
        city: values.city_1 || "-",
        zipcode: values.zipcode_1 || "-",
      },
      physicalAddress?.ID
    );
    setMessage("Practice physical address updated successfully");
    setMessageType("physical_address_information");

    setUpdatingPhysicalAddress(false);
  };

  console.log(id);

  const impersonateHandle = () => {
    if (userImpersonating) {
      return "Wait...";
    } else {
      return (
        <>
          Impersonate
          <UserIcon className="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
        </>
      );
    }
  };

  const validationBasicInfoSchema = Yup.object({
    practice_email: Yup.string()
      .email("Email is invalid")
      .required("Email is required"),
    user_first_name: Yup.string().required("User first name is required "),
    user_last_name: Yup.string().required("User last name  is required "),
    user_position: Yup.string().required("User position   is required "),
    practice_name: Yup.string().required("Practice name   is required"),
    user_phone: Yup.string().required("Practice phone  is required "),
  });

  const validationContactInfoSchema = Yup.object({
    contact_email: Yup.string()
      .email("Email is invalid")
      .required("Email is required"),
    contact_title: Yup.string().required("Title is required "),
    contact_first_name: Yup.string().required("First name is required "),
    contact_last_name: Yup.string().required("Last name  is required "),
    contact_phone: Yup.string().required("Phone  is required "),
  });

  const validationPhysicalAddress = Yup.object({
    country_1: Yup.string().required("Country is required "),
    city_1: Yup.string().required("City is required "),
    state_1: Yup.string().required("State  is required "),
    zipcode_1: Yup.string().required("Zip code  is required "),
    address_1: Yup.string().required("Zip code  is required "),
  });

  return (
    <>
        <Theme>
          <main className="py-10 bg-gray-200">
            {/* Page header */}
            <div className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
              <div className="flex items-center space-x-5">
                <div className="flex-shrink-0">
                  <div className="relative">
                    <img
                      className="h-16 w-16 rounded-full"
                      src={practice?.FilePath}
                      alt=""
                    />
                    <span
                      className="absolute inset-0 rounded-full shadow-inner"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div>
                  <h1 className="text-2xl font-bold text-gray-900">
                    {practice?.SubscriberFirstName}{" "}
                    {practice?.SubscriberLastName}
                  </h1>
                  <p className="text-sm font-medium text-gray-500">
                    Applied for{" "}
                    <a href="#" className="text-gray-900">
                      Practice Subscription
                    </a>{" "}
                    on{" "}
                    <time dateTime="2020-08-25" className="text-green">
                      {moment(practice?.CreatedOn)
                        .utc()
                        .format("dddd, MMMM Do YYYY, h:mm:ss a")}{" "}
                      (UTC)
                    </time>
                  </p>
                </div>
              </div>
              <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                <button
                  onClick={() => {
                    navigate("/practices");
                  }}
                  className="inline-flex items-center rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  <ArrowLongLeftIcon
                    className="mr-2 -ml-0.5 h-4 w-4"
                    aria-hidden="true"
                  />
                  Go Back
                </button>
                <button
                  type="button"
                  onClick={async () => {
                    setUserImpersonating(true);

                    console.log("ID : ", id);

                    const res: any = await impersonate({
                      practice_id: id,
                    });

                    setUserImpersonating(false);

                    if (res.data.error) {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "User not found",
                      });
                    } else {
                      window.open(
                        `${config.defaults.practice_dashboard_url}?token=${res.data.accessToken}`,
                        "_blank"
                      );
                    }
                  }}
                  className="inline-flex items-center rounded-md border border-transparent bg-red-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  {impersonateHandle()}
                </button>

                <button
                  onClick={() => {

                    const contact_id = practice?.crm_contact_id ? practice?.crm_contact_id : practice?.Contact_Id;

                    window.open(`https://app.liftengines.com/v2/location/YzcFQ6sDBPZ4Z64nJ8hp/contacts/detail/${contact_id}`,"_blank")
                  }}
                  className="inline-flex items-center rounded-md border border-transparent bg-gray-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  <ViewListIcon
                    className="mr-2 -ml-0.5 h-4 w-4"
                    aria-hidden="true"
                  />
                  Recent Activity
                </button>

                <button
                      type="button"
                      onClick={async () => {
                        window.open(
                          `https://dashboard.stripe.com/customers/${practice.StripeID}`,
                          "_blank"
                        );
                      }}
                      className="ml-3 inline-flex items-center rounded-md border border-transparent bg-blue-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Stripe
                      <CurrencyDollarIcon
                        className="ml-1 -mr-0.5 h-4 w-4"
                        aria-hidden="true"
                      />
                </button>
              </div>
            </div>

            <div className="bg-white mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
              <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                <div className="relative mx-auto max-w-4xl md:px-8 xl:px-0">
                  <section className="content-section">
                    <div className="pt-10 pb-16">
                      {/* <div className="px-4 sm:px-6 md:px-0">
                                <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                                Settings
                                </h1>
                            </div> */}
                      <div className="px-4 sm:px-6 md:px-0">
                        <div className="py-6">
                          {/* Tabs */}
                          <div className="lg:hidden">
                            <label htmlFor="selected-tab" className="sr-only">
                              Select a tab
                            </label>
                            <select
                              id="selected-tab"
                              name="selected-tab"
                              className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
                              defaultValue={
                                tabs.find((tab) => tab.current).name
                              }
                            >
                              {tabs.map((tab) => {
                                //   setCurrentTab(tab.name);
                                return (
                                  <option key={tab.name}>{tab.name}</option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="hidden lg:block">
                            <div className="border-b border-gray-200">
                              <nav className="-mb-px flex space-x-8">
                                {tabs.map((tab) => {
                                  return (
                                    <>
                                      <a
                                        onClick={(e) => {
                                          setCurrentTab(tab.name);
                                          e.preventDefault();
                                        }}
                                        key={tab.name}
                                        href={tab.href}
                                        className={classNames(
                                          tab.name === currentTab
                                            ? "border-purple-500 text-purple-600"
                                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                          "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                                        )}
                                      >
                                        {tab.name}

                                        {tab.name === "Practice Details" && (
                                          <span className="inline-flex p-4 justify-center invisible items-center ml-2 w-4 h-4 text-xs font-semibold text-white-800 bg-white-200 rounded-full">
                                            0
                                          </span>
                                        )}

                                        {tab.name === "Billing" && (
                                          <span className="inline-flex p-4 justify-center items-center invisible ml-2 w-4 h-4 text-xs font-semibold text-white-800 bg-white-200 rounded-full">
                                             0
                                          </span>
                                        )}

                                        

                                        {tab.name === "Files" && (
                                          <span className="inline-flex p-4 justify-center items-center ml-2 w-4 h-4 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                                            {files.length}
                                          </span>
                                        )}

                                        {tab.name === "Videos" && (
                                          <span className="inline-flex p-4 justify-center items-center ml-2 w-4 h-4 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                                            {videos_in_playing.length}
                                          </span>
                                        )}

                                        {tab.name === "Team Members" && (
                                          <span className="inline-flex p-4 justify-center items-center ml-2 w-4 h-4 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                                            {teamMembers.length}
                                          </span>
                                        )}
                                      </a>
                                    </>
                                  );
                                })}
                              </nav>
                            </div>
                          </div>

                          {currentTab === "Practice Details" && (
                            <>
                              {message &&
                                messageType === "basic_information" && (
                                  <>
                                    <div
                                      className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md"
                                      role="alert"
                                    >
                                      <span
                                        className="relative float-right top-0 bottom-0 right-0 px-4 py-3"
                                        onClick={() => {
                                          setMessage(null);
                                        }}
                                      >
                                        <svg
                                          className="fill-current h-6 w-6 text-red-500"
                                          role="button"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                        >
                                          <title>Close</title>
                                          <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                                        </svg>
                                      </span>
                                      <div className="flex">
                                        <div className="py-1">
                                          <svg
                                            className="fill-current h-6 w-6 text-teal-500 mr-4"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                          >
                                            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                                          </svg>
                                        </div>
                                        <div>
                                          <p className="font-bold">
                                            Practice Basic Information Updated
                                            Successfully
                                          </p>
                                          <p className="text-sm">
                                            Make sure you know how these changes
                                            affect Practice Profile.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              <Formik
                                enableReinitialize
                                initialValues={Initial_values_basic_info}
                                validationSchema={validationBasicInfoSchema}
                                onSubmit={updateBasicInfo}
                              >
                                <Form>
                                  <div className="grid grid-cols-1 gap-y-6 pt-8 sm:grid-cols-6 sm:gap-x-6">
                                    <div className="sm:col-span-6">
                                      <h2 className="text-xl font-medium text-blue-gray-900">
                                        Basic Information
                                      </h2>
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="email-address"
                                        className="block text-sm font-medium text-blue-gray-900"
                                      >
                                        Practice Name
                                      </label>
                                      <Field
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        id="practice_name"
                                        name="practice_name"
                                        placeholder="Practice name"
                                      />

                                      <ErrorMessage
                                        component="a"
                                        className="error-msg text-red"
                                        name="practice_name"
                                      />
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="phone-number"
                                        className="block text-sm font-medium text-blue-gray-900"
                                      >
                                        Practice Code
                                      </label>
                                      <Field
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        id="practice_code"
                                        name="practice_code"
                                        placeholder="Practice code"
                                        disabled
                                      />

                                      <ErrorMessage
                                        component="a"
                                        className="error-msg text-red"
                                        name="practice_code"
                                      />
                                    </div>

                                    <div className="sm:col-span-6">
                                      <label
                                        htmlFor="language"
                                        className="block text-sm font-medium text-blue-gray-900"
                                      >
                                        Organization{" "}
                                        <span className="text-base">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Field
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        id="corp_name"
                                        name="corp_name"
                                        placeholder="Name of Organization"
                                      />

                                      <ErrorMessage
                                        component="a"
                                        className="error-msg text-red"
                                        name="corp_name"
                                      />
                                    </div>

                                    <div className="sm:col-span-6">
                                      <label
                                        htmlFor="photo"
                                        className="block text-sm font-medium text-blue-gray-900"
                                      >
                                        Practice Logo
                                      </label>
                                      <div className="mt-1 flex items-center">
                                        <img
                                          className="inline-block h-12 w-12 "
                                          src={practice?.FilePath}
                                          alt=""
                                        />
                                        <div className="ml-4 flex">
                                          <div className="relative flex cursor-pointer items-center rounded-md border border-blue-gray-300 bg-white py-2 px-3 shadow-sm focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 hover:bg-blue-gray-50">
                                            <label
                                              htmlFor="user-photo"
                                              className="pointer-events-none relative text-sm font-medium text-blue-gray-900"
                                            >
                                              <span>Change</span>
                                              <span className="sr-only">
                                                {" "}
                                                user photo
                                              </span>
                                            </label>
                                            <input
                                              id="user-photo"
                                              name="user-photo"
                                              type="file"
                                              className="absolute inset-0 h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="sm:col-span-6">
                                      <label
                                        htmlFor="location"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        User Title
                                      </label>
                                      <Field
                                        as="select"
                                        id="user_title_"
                                        name="user_title"
                                        className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                      >
                                        {titles.map((title) => {
                                          return (
                                            <option
                                              selected={
                                                basicInfoData.user_title ==
                                                  title.name
                                                  ? true
                                                  : false
                                              }
                                              value={title.name}
                                              key={title.name}
                                            >
                                              {title.name}
                                            </option>
                                          );
                                        })}
                                      </Field>
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="language"
                                        className="block text-sm font-medium text-blue-gray-900"
                                      >
                                        User First Name
                                      </label>
                                      <Field
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        id="user_first_name"
                                        name="user_first_name"
                                        placeholder="First name"
                                      />

                                      <ErrorMessage
                                        component="a"
                                        className="error-msg text-red"
                                        name="user_first_name"
                                      />
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="language"
                                        className="block text-sm font-medium text-blue-gray-900"
                                      >
                                        User Last Name
                                      </label>
                                      <Field
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        id="user_last_name"
                                        name="user_last_name"
                                        placeholder="Last name"
                                      />

                                      <ErrorMessage
                                        component="a"
                                        className="error-msg text-red"
                                        name="user_last_name"
                                      />
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="language"
                                        className="block text-sm font-medium text-blue-gray-900"
                                      >
                                        Practice Email
                                      </label>
                                      <Field
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        id="practice_email"
                                        name="practice_email"
                                        placeholder="Practice Email"
                                      />

                                      <ErrorMessage
                                        component="a"
                                        className="error-msg text-red"
                                        name="practice_email"
                                      />
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label className="block text-sm font-medium text-blue-gray-900">
                                        User Position
                                      </label>
                                      <Field
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        id="user_position"
                                        name="user_position"
                                        placeholder="User Position"
                                      />

                                      <ErrorMessage
                                        component="a"
                                        className="error-msg text-red"
                                        name="user_position"
                                      />
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label className="block text-sm font-medium text-blue-gray-900">
                                        Telephone
                                      </label>
                                      <Field
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        id="user_phone"
                                        name="user_phone"
                                        placeholder="User Phone"
                                      />

                                      <ErrorMessage
                                        component="a"
                                        className="error-msg text-red"
                                        name="user_phone"
                                      />
                                    </div>
                                  </div>
                                  <button
                                    type="submit"
                                    disabled={updatingBasicInfo}
                                    className="mt-8 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                  >
                                    {updatingBasicInfo
                                      ? "Saving..."
                                      : "Save Changes"}
                                    <SaveAsIcon
                                      className="ml-2 -mr-0.5 h-4 w-4"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </Form>
                              </Formik>
                            </>
                          )}

                          {currentTab === "Practice Details" && (
                            <>
                              {message &&
                                messageType === "contact_information" && (
                                  <>
                                    <div
                                      className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md"
                                      role="alert"
                                    >
                                      <span
                                        className="relative float-right top-0 bottom-0 right-0 px-4 py-3"
                                        onClick={() => {
                                          setMessage(null);
                                        }}
                                      >
                                        <svg
                                          className="fill-current h-6 w-6 text-red-500"
                                          role="button"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                        >
                                          <title>Close</title>
                                          <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                                        </svg>
                                      </span>
                                      <div className="flex">
                                        <div className="py-1">
                                          <svg
                                            className="fill-current h-6 w-6 text-teal-500 mr-4"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                          >
                                            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                                          </svg>
                                        </div>
                                        <div>
                                          <p className="font-bold">
                                            Practice Contact Information Updated
                                            Successfully
                                          </p>
                                          <p className="text-sm">
                                            Make sure you know how these changes
                                            affect Practice Profile.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              <Formik
                                enableReinitialize
                                initialValues={initial_contact_info_data}
                                validationSchema={validationContactInfoSchema}
                                onSubmit={updateContactInfo}
                              >
                                <Form>
                                  <div className="grid grid-cols-1 gap-y-6 pt-8 sm:grid-cols-6 sm:gap-x-6">
                                    <div className="sm:col-span-6">
                                      <h2 className="text-xl font-medium text-blue-gray-900">
                                        Contact Information
                                      </h2>
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="location"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Contact Title
                                      </label>
                                      <Field
                                        as="select"
                                        id="contact_title_"
                                        name="contact_title"
                                        className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                      >
                                        {titles.map((title) => {
                                          return (
                                            <option
                                              selected={
                                                contactInfoData.contact_title ==
                                                  title.name
                                                  ? true
                                                  : false
                                              }
                                              value={title.name}
                                              key={title.name}
                                            >
                                              {title.name}
                                            </option>
                                          );
                                        })}
                                      </Field>
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="language"
                                        className="block text-sm font-medium text-blue-gray-900"
                                      >
                                        Contact First Name
                                      </label>
                                      <Field
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        id="contact_first_name"
                                        name="contact_first_name"
                                        placeholder="Contact first name"
                                      />

                                      <ErrorMessage
                                        component="a"
                                        className="error-msg text-red"
                                        name="contact_first_name"
                                      />
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="language"
                                        className="block text-sm font-medium text-blue-gray-900"
                                      >
                                        Contact Last Name
                                      </label>
                                      <Field
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        id="contact_last_name"
                                        name="contact_last_name"
                                        placeholder="Contact last name"
                                      />

                                      <ErrorMessage
                                        component="a"
                                        className="error-msg text-red"
                                        name="contact_last_name"
                                      />
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="language"
                                        className="block text-sm font-medium text-blue-gray-900"
                                      >
                                        Contact Email
                                      </label>
                                      <Field
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        id="contact_email"
                                        name="contact_email"
                                        placeholder="Contact Email"
                                      />

                                      <ErrorMessage
                                        component="a"
                                        className="error-msg text-red"
                                        name="contact_email"
                                      />
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label className="block text-sm font-medium text-blue-gray-900">
                                        Contact Phone
                                      </label>
                                      <Field
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        id="contact_phone"
                                        name="contact_phone"
                                        placeholder="Contact Phone"
                                      />

                                      <ErrorMessage
                                        component="a"
                                        className="error-msg text-red"
                                        name="contact_phone"
                                      />
                                    </div>
                                  </div>
                                  <button
                                    type="submit"
                                    disabled={updatingContactInfo}
                                    className="mt-8 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                  >
                                    {updatingContactInfo
                                      ? "Saving..."
                                      : "Save Changes"}

                                    <SaveAsIcon
                                      className="ml-2 -mr-0.5 h-4 w-4"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </Form>
                              </Formik>
                            </>
                          )}

                          {currentTab === "Practice Details" && (
                            <>
                              {message &&
                                messageType ===
                                "postal_address_information" && (
                                  <>
                                    <div
                                      className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md"
                                      role="alert"
                                    >
                                      <span
                                        className="relative float-right top-0 bottom-0 right-0 px-4 py-3"
                                        onClick={() => {
                                          setMessage(null);
                                        }}
                                      >
                                        <svg
                                          className="fill-current h-6 w-6 text-red-500"
                                          role="button"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                        >
                                          <title>Close</title>
                                          <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                                        </svg>
                                      </span>
                                      <div className="flex">
                                        <div className="py-1">
                                          <svg
                                            className="fill-current h-6 w-6 text-teal-500 mr-4"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                          >
                                            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                                          </svg>
                                        </div>
                                        <div>
                                          <p className="font-bold">
                                            Practice Physical Address Updated
                                            Successfully
                                          </p>
                                          <p className="text-sm">
                                            Make sure you know how these changes
                                            affect Practice Profile.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              <Formik
                                enableReinitialize
                                initialValues={initial_physical_address_data}
                                validationSchema={validationPhysicalAddress}
                                onSubmit={updatePhysicalAddress}
                              >
                                <Form>
                                  <div className="grid grid-cols-1 gap-y-6 pt-8 sm:grid-cols-6 sm:gap-x-6">
                                    <div className="sm:col-span-6">
                                      <h2 className="text-xl font-medium text-blue-gray-900">
                                        Physical Address
                                      </h2>
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="location"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Country
                                      </label>
                                      <Field
                                        as="select"
                                        id="country_1"
                                        name="country_1"
                                        className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                      >
                                        {countries.map((country) => {
                                          return (
                                            <option
                                              selected={
                                                physicalAddress.country_1 ==
                                                  country.id
                                                  ? true
                                                  : false
                                              }
                                              value={country.id}
                                              key={country.id}
                                            >
                                              {country.name}
                                            </option>
                                          );
                                        })}
                                      </Field>
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="language"
                                        className="block text-sm font-medium text-blue-gray-900"
                                      >
                                        Zip Code
                                      </label>
                                      <Field
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        id="zipcode_1"
                                        name="zipcode_1"
                                        placeholder="Zipcode"
                                      />

                                      <ErrorMessage
                                        component="a"
                                        className="error-msg text-red"
                                        name="zipcode_1"
                                      />
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="language"
                                        className="block text-sm font-medium text-blue-gray-900"
                                      >
                                        State
                                      </label>
                                      <Field
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        id="state_1"
                                        name="state_1"
                                        placeholder="State"
                                      />

                                      <ErrorMessage
                                        component="a"
                                        className="error-msg text-red"
                                        name="state_1"
                                      />
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="language"
                                        className="block text-sm font-medium text-blue-gray-900"
                                      >
                                        Address
                                      </label>
                                      <Field
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        id="address_1"
                                        name="address_1"
                                        placeholder="Address"
                                      />

                                      <ErrorMessage
                                        component="a"
                                        className="error-msg text-red"
                                        name="address_1"
                                      />
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label className="block text-sm font-medium text-blue-gray-900">
                                        City
                                      </label>
                                      <Field
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        id="city_1"
                                        name="city_1"
                                        placeholder="City"
                                      />

                                      <ErrorMessage
                                        component="a"
                                        className="error-msg text-red"
                                        name="city_1"
                                      />
                                    </div>

                                    <div className="sm:col-span-3">
                                      <label className="block text-sm font-medium text-blue-gray-900">
                                        Street
                                      </label>
                                      <Field
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        id="street_1"
                                        name="street_1"
                                        placeholder="Street"
                                      />

                                      <ErrorMessage
                                        component="a"
                                        className="error-msg text-red"
                                        name="street_1"
                                      />
                                    </div>
                                  </div>
                                  <button
                                    type="submit"
                                    disabled={updatingPhysicalAddress}
                                    className="mt-8 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                  >
                                    {updatingPhysicalAddress
                                      ? "Saving..."
                                      : "Save Changes"}

                                    <SaveAsIcon
                                      className="ml-2 -mr-0.5 h-4 w-4"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </Form>
                              </Formik>
                            </>
                          )}

                          {currentTab === "Team Members" && (
                            <>
                              <ul
                                role="list"
                                className="mt-5 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
                              >
                                {teamMembers.map((person) => (
                                  <li
                                    key={person.email}
                                    className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
                                  >
                                    <div className="flex flex-1 flex-col p-8">
                                      <img
                                        className="mx-auto h-32 w-32 flex-shrink-0 rounded-full"
                                        src={person.FilePath || DetaultProfile}
                                        alt=""
                                      />
                                      <h3 className="mt-6 text-sm font-medium text-gray-900">
                                        {person.MemberName}
                                      </h3>
                                      <dl className="mt-1 flex flex-grow flex-col justify-between">
                                        <dt className="sr-only">Title</dt>
                                        <dd className="text-sm text-gray-500">
                                          {person.ShortName}
                                        </dd>
                                        <dt className="sr-only">Role</dt>
                                        <dd className="mt-3">
                                          <span className="rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800">
                                            Team Member
                                          </span>
                                        </dd>
                                      </dl>
                                    </div>
                                    <div></div>
                                  </li>
                                ))}
                              </ul>
                            </>
                          )}

                          {currentTab === "Videos" && (
                            <>
                              <Tabs className="mt-10 tabs-video-cust">
                                <TabList>
                                  <Tab default={true}>Current</Tab>
                                  <Tab>In Production</Tab>
                                  <Tab>Needs Customization</Tab>
                                </TabList>

                                <TabPanel>

                                  {videos_in_playing.length ? (
                                    <>
                                      <ul
                                        role="list"
                                        className="mt-4 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
                                      >
                                        {videos_in_playing.map((video: any) => (
                                          <>
                                            <li key={video.video_id} className="relative mb-5 mt-4">
                                              <div className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                                                <img
                                                  src={video.thumbnail_file}
                                                  alt=""
                                                  className="pointer-events-none object-cover group-hover:opacity-75"
                                                />
                                              </div>
                                              <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
                                                {video.title}
                                              </p>
                                              <p className="pointer-events-none block text-sm font-medium text-gray-500">
                                                {video.category_name}
                                              </p>

                                              <button
                                                type="button"
                                                className="flex space-x-2 items-center mt-6 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                                                onClick={async () => {
                                                  setCurrentVideo(video);
                                                  setVideoModal(true);
                                                }}
                                              >
                                                <PlayIcon
                                                  className="-mr-0.5 h-4 w-4 mt-1"
                                                  aria-hidden="true"
                                                />
                                                <span> Play </span>
                                              </button>
                                            </li>
                                          </>
                                        ))}
                                      </ul>
                                    </>
                                  ) : (
                                    <>
                                      <>
                                        <p className="text-center justify-center">
                                          {" "}
                                          <strong> No records found. </strong>
                                        </p>
                                      </>
                                    </>
                                  )}

                                </TabPanel>
                                <TabPanel>
                                {videos_in_production.length ? (
                                    <>
                                      <ul
                                        role="list"
                                        className="mt-4 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
                                      >
                                        {videos_in_production.map((video: any) => (
                                          <>
                                            <li key={video.video_id} className="relative mb-5 mt-4">
                                              <div className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                                                <img
                                                  src={video.thumbnail_file}
                                                  alt=""
                                                  className="pointer-events-none object-cover group-hover:opacity-75"
                                                />
                                              </div>
                                              <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
                                                {video.title}
                                              </p>
                                              <p className="pointer-events-none block text-sm font-medium text-gray-500">
                                                {video.category_name}
                                              </p>

                                              <button
                                                type="button"
                                                className="flex space-x-2 items-center mt-6 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                                                onClick={async () => {
                                                  setCurrentVideo(video);
                                                  setVideoModal(true);
                                                }}
                                              >
                                                <PlayIcon
                                                  className="-mr-0.5 h-4 w-4 mt-1"
                                                  aria-hidden="true"
                                                />
                                                <span> Play </span>
                                              </button>
                                            </li>
                                          </>
                                        ))}
                                      </ul>
                                    </>
                                  ) : (
                                    <>
                                      <>
                                        <p className="text-center justify-center">
                                          {" "}
                                          <strong> No records found. </strong>
                                        </p>
                                      </>
                                    </>
                                  )}
                                </TabPanel>
                                <TabPanel>
                                {videos_in_customization.length ? (
                                    <>
                                      <ul
                                        role="list"
                                        className="mt-4 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
                                      >
                                        {videos_in_customization.map((video: any) => (
                                          <>
                                            <li key={video.video_id} className="relative mb-5 mt-4">
                                              <div className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                                                <img
                                                  src={video.thumbnail_file}
                                                  alt=""
                                                  className="pointer-events-none object-cover group-hover:opacity-75"
                                                />
                                              </div>
                                              <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
                                                {video.title}
                                              </p>
                                              <p className="pointer-events-none block text-sm font-medium text-gray-500">
                                                {video.category_name}
                                              </p>

                                              <button
                                                type="button"
                                                className="flex space-x-2 items-center mt-6 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                                                onClick={async () => {
                                                  setCurrentVideo(video);
                                                  setVideoModal(true);
                                                }}
                                              >
                                                <PlayIcon
                                                  className="-mr-0.5 h-4 w-4 mt-1"
                                                  aria-hidden="true"
                                                />
                                                <span> Play </span>
                                              </button>
                                            </li>
                                          </>
                                        ))}
                                      </ul>
                                    </>
                                  ) : (
                                    <>
                                      <>
                                        <p className="text-center justify-center">
                                          {" "}
                                          <strong> No records found. </strong>
                                        </p>
                                      </>
                                    </>
                                  )}
                                </TabPanel>
                              </Tabs>
                            </>
                          )}

                          {currentTab === "Billing" && <></>}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </main>
        </Theme>

        <VideoModal

          video={currentVideo}
          videoModal={videoModal}
          setVideoModal={setVideoModal}

        />

      <RecentActivityModal open={modal} setOpen={setModal} id={id} />
    </>
  );
};

export default EditPractice;
