import { cn } from 'util/cn';

type PropsType = {
  className?: string;
};

const NewQuestionIcon = ({ className }: PropsType) => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={cn('text-[#D6D6D6]', className)}
    >
      <rect width='40' height='40' rx='8' fill='currentColor' />
      <path
        d='M19.9141 16.9883H25.8203'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.1797 16.9883L14.0234 17.832L16.5547 15.3008'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.9141 24.8633H25.8203'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.1797 24.8633L14.0234 25.707L16.5547 23.1758'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.125 31.75H22.875C28.5 31.75 30.75 29.5 30.75 23.875V17.125C30.75 11.5 28.5 9.25 22.875 9.25H16.125C10.5 9.25 8.25 11.5 8.25 17.125V23.875C8.25 29.5 10.5 31.75 16.125 31.75Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default NewQuestionIcon;
