import { useEffect, useState } from 'react';
import { Button, Input, Label } from 'components';
import {
  useVideo,
  useVideoActions,
  CustomInput,
} from 'features/video-to-library';

const NewImage = () => {
  const [fields, setFields] = useState<CustomInput>(null);
  const [textError, setTextError] = useState('');
  const [sizeError, setSizeError] = useState('');
  const { expanded, customItems } = useVideo();
  const { setExpanded, setInput } = useVideoActions();

  const save = () => {
    if (
      !fields.description ||
      !fields.image_max_height ||
      !fields.image_max_width
    ) {
      if (!fields.description) setTextError('Text for image is required');
      if (!fields.image_max_height || !fields.image_max_width)
        setSizeError('Minimum image size is required');
      return;
    }
    if (expanded) setInput(expanded, fields);
    setExpanded(false);
  };

  useEffect(
    () =>
      setFields(
        customItems.find(({ id, type }) => id === expanded && type === 'image')
      ),
    [expanded, customItems]
  );

  useEffect(() => {
    if (fields?.description) setTextError('');
    if (fields?.image_max_height && fields?.image_max_width) setSizeError('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields?.description, fields?.image_max_height, fields?.image_max_width]);

  if (fields)
    return (
      <>
        <Input
          label='Text for image request.'
          helper='i.e / Before photo of aligners'
          placeholder='Write text here'
          value={fields.description}
          onChange={(e) =>
            setFields({ ...fields, description: e.target.value })
          }
          errorMessage={textError}
        />
        <div className='space-y-3'>
          <Label>Minimum image size</Label>
          <div className='space-y-1.5 w-fit'>
            <div className='flex gap-2.5'>
              <Input
                placeholder='1080'
                type='number'
                className='max-w-[114px]'
                min={0}
                value={fields.image_max_height}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    image_max_height: e.target.valueAsNumber,
                  })
                }
              />
              <Input
                placeholder='1920'
                type='number'
                className='max-w-[114px]'
                min={0}
                value={fields.image_max_width}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    image_max_width: e.target.valueAsNumber,
                  })
                }
              />
            </div>
            {sizeError && (
              <p className='text-accent-700 text-sm font-bold font-karla text-end'>
                {sizeError}
              </p>
            )}
          </div>
        </div>
        <Button className='w-full' onClick={save}>
          Save and Continue
        </Button>
      </>
    );
};

export default NewImage;
