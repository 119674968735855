import { Body, Button, Headline, useModalActions } from 'components';
import { useVideoActions } from './stores';

type PropsType = {
  id?: string;
};

const CustomInputDelete = ({ id = '' }: PropsType) => {
  const { close } = useModalActions();
  const { removeCustomItem } = useVideoActions();

  const removeItem = () => {
    removeCustomItem(id);
    close();
  };

  return (
    <div className='space-y-6'>
      <div className='space-y-4'>
        <Headline size='lg'>Delete Custom User Input</Headline>
        <Body>Are you sure you want to delete this custom user input?</Body>
      </div>
      <div className='space-y-4'>
        <Button className='block w-full bg-delete' onClick={removeItem}>
          Delete
        </Button>
        <Button variant='secondary' onClick={close} className='block w-full'>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default CustomInputDelete;
