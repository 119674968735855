import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { createPractice } from "redux/actionCreators/practice";
import Swal from "sweetalert2";

const NewPracticeModal = (props: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const countries = [
    { id: null, name: "Select" },
    { id: 1, name: "Australia" },
    { id: 2, name: "USA" },
    { id: 4, name: "NZ" },

    { id: 5, name: "SG" },

    { id: 6, name: "UK" },

    { id: 7, name: "CA" },

    { id: 8, name: "Europe" },
  ];

  const Initial_values = {
    first_name: "",
    last_name: "",
    email: "",
    practice_name: "",
    country: "",
    state: "",
    postcode: "",
    address: "",
  };

  const validation = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    first_name: Yup.string().required("First name is required "),
    last_name: Yup.string().required("Last name  is required "),
    practice_name: Yup.string().required("Practice name   is required"),
    country: Yup.string().required("Country  is required "),
    state: Yup.string().required("State  is required "),
    postcode: Yup.string().required("Postcode  is required "),
    address: Yup.string().required("Country  is required "),
  });

  const create = async (values: any) => {
    console.log(values);

    setIsSubmitting(true);

    const newPractice = await createPractice({
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      practice_name: values.practice_name,
      country: values.country,
      state: values.state,
      postcode: values.postcode,
      address: values.address,
    });

    console.log(newPractice);

    const error = newPractice.data.error;

    console.log("error :", error);

    if (error) {
      props.setModal(false);

      setIsSubmitting(false);

      Swal.fire({
        icon: "error",
        title: "Error Occurred",
        text: newPractice.data.message,
      });

      return;
    } else {
      setIsSubmitting(false);
      props.setModal(false);

      props.fetchPractices();

      Swal.fire({
        icon: "success",
        title: "Practice created successfully",
        text: "Email has been sent to the user to follow up the next steps",
      });

      return;
    }
  };

  return (
    <>
      <Transition.Root show={props.modal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => props.setModal(false)}
        >
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">
                              New Practice
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={() => props.setModal(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-indigo-300">
                              Get started by filling in the information below to
                              create new practice.
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-200 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <Formik
                                enableReinitialize
                                initialValues={Initial_values}
                                validationSchema={validation}
                                onSubmit={create}
                              >
                                <Form>
                                  <div className="mt-3 sm:col-span-3">
                                    <label className="block text-sm font-medium text-blue-gray-900">
                                      Practice Name
                                    </label>
                                    <Field
                                      type="text"
                                      className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                      id="practice_name"
                                      name="practice_name"
                                      placeholder="Practice name"
                                    />

                                    <ErrorMessage
                                      component="a"
                                      className="error-msg text-red"
                                      name="practice_name"
                                    />
                                  </div>

                                  <div className="mt-3 sm:col-span-3">
                                    <label className="block text-sm font-medium text-blue-gray-900">
                                      First Name
                                    </label>
                                    <Field
                                      type="text"
                                      className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                      id="first_name"
                                      name="first_name"
                                      placeholder="User first Name"
                                    />

                                    <ErrorMessage
                                      component="a"
                                      className="error-msg text-red"
                                      name="first_name"
                                    />
                                  </div>

                                  <div className="mt-3 sm:col-span-3">
                                    <label className="block text-sm font-medium text-blue-gray-900">
                                      Last Name
                                    </label>
                                    <Field
                                      type="text"
                                      className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                      id="last_name"
                                      name="last_name"
                                      placeholder="USer last name"
                                    />

                                    <ErrorMessage
                                      component="a"
                                      className="error-msg text-red"
                                      name="last_name"
                                    />
                                  </div>

                                  <div className="mt-3 sm:col-span-3">
                                    <label className="block text-sm font-medium text-blue-gray-900">
                                      Email
                                    </label>
                                    <Field
                                      type="text"
                                      className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                      id="email"
                                      name="email"
                                      placeholder="Email"
                                    />

                                    <ErrorMessage
                                      component="a"
                                      className="error-msg text-red"
                                      name="email"
                                    />
                                  </div>

                                  <div className="mt-3 sm:col-span-3">
                                    <label className="block text-sm font-medium text-blue-gray-900">
                                      Country
                                    </label>

                                    <Field
                                      as="select"
                                      id="country"
                                      name="country"
                                      className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    >
                                      {countries.map((country, i) => {
                                        return (
                                          <option
                                            value={country.id}
                                            key={country.id}
                                          >
                                            {country.name}
                                          </option>
                                        );
                                      })}
                                    </Field>

                                    <ErrorMessage
                                      component="a"
                                      className="error-msg text-red"
                                      name="country"
                                    />
                                  </div>

                                  <div className="mt-3 sm:col-span-3">
                                    <label className="block text-sm font-medium text-blue-gray-900">
                                      State
                                    </label>
                                    <Field
                                      type="text"
                                      className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                      id="state"
                                      name="state"
                                      placeholder="State name"
                                    />

                                    <ErrorMessage
                                      component="a"
                                      className="error-msg text-red"
                                      name="state"
                                    />
                                  </div>

                                  <div className="mt-3 sm:col-span-3">
                                    <label className="block text-sm font-medium text-blue-gray-900">
                                      Postcode
                                    </label>
                                    <Field
                                      type="text"
                                      className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                      id="postcode"
                                      name="postcode"
                                      placeholder="Postcode"
                                    />

                                    <ErrorMessage
                                      component="a"
                                      className="error-msg text-red"
                                      name="postcode"
                                    />
                                  </div>

                                  <div className="mt-3 sm:col-span-3">
                                    <label className="block text-sm font-medium text-blue-gray-900">
                                      Address
                                    </label>
                                    <Field
                                      type="text"
                                      className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                      id="address"
                                      name="address"
                                      placeholder="Address"
                                    />

                                    <ErrorMessage
                                      component="a"
                                      className="error-msg text-red"
                                      name="address"
                                    />
                                  </div>

                                  <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                    <button
                                      type="button"
                                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="submit"
                                      disabled={isSubmitting}
                                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                      {isSubmitting ? 'Creating..' : 'Create'}
                                    </button>
                                  </div>
                                </Form>
                              </Formik>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default NewPracticeModal;
