import CustomRadioItem from './custom-radio-item';
import {
  ImageFileIcon,
  NewQuestionIcon,
  OptionSelectIcon,
  TeamMemberIcon,
} from './icons';
import { CustomRadioType } from './types';

const CustomRadioGrid = () => {
  const customRadios: CustomRadioType[] = [
    {
      label: 'Add new question',
      value: 'question',
      icon: <NewQuestionIcon className='mx-auto text-current' />,
    },
    {
      label: 'Option select',
      value: 'select',
      icon: <OptionSelectIcon className='mx-auto text-current' />,
    },
    {
      label: 'Request an image file',
      value: 'image',
      icon: <ImageFileIcon className='mx-auto text-current' />,
    },
    {
      label: 'Select a team member',
      value: 'member',
      icon: <TeamMemberIcon className='mx-auto text-current' />,
    },
  ];

  return (
    <ul className='grid grid-cols-3 gap-3'>
      {customRadios.map(({ label, value, icon }, index) => (
        <li key={index}>
          <CustomRadioItem label={label} value={value} icon={icon} />
        </li>
      ))}
    </ul>
  );
};

export default CustomRadioGrid;
