import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  useVideo,
  CustomInputItem,
  useVideoActions,
} from 'features/video-to-library';
import { cn } from 'util/cn';

const CustomInputList = () => {
  const { customItems } = useVideo();
  const { setInputs } = useVideoActions();

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(customItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setInputs(items);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='customItems'>
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              {customItems.map((customItem, index) => {
                const { id, type } = customItem;

                return (
                  <Draggable key={id} draggableId={id} index={index}>
                    {(provided, snapshot) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={cn(
                          'mt-3 rounded-[10px] border bg-white shadow-custom-input transition-colors duration-150',
                          snapshot.isDragging
                            ? 'border-black'
                            : 'border-[#E7E7E7]'
                        )}
                      >
                        <CustomInputItem
                          id={id}
                          type={type}
                          key={index}
                          dragHandleProps={provided.dragHandleProps}
                        />
                      </li>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default CustomInputList;
