import { ReactNode } from 'react';
import { cn } from 'util/cn';
import { generateId } from 'util/generated-id';

type PropsType = {
  className?: string;
  htmlFor?: string;
  children?: ReactNode;
  helper?: string;
};

const Label = ({
  className,
  htmlFor = generateId(),
  children,
  helper,
}: PropsType) => {
  return (
    <label
      htmlFor={htmlFor}
      className={cn(
        'text-tailgrids-black font-karla font-bold block leading-[19px]',
        className
      )}
    >
      {children}
      {helper && <span className='text-[#9C9D9E]'> {helper}</span>}
    </label>
  );
};

export default Label;
