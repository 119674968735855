import { computed, signal } from '@preact/signals-react'
import { formatDate } from 'util/format-date'

export const didPublish = signal(false)

// fields
export const title = signal('')
export const primary = signal('')
export const secondary = signal('')
export const description = signal('')
export const code = signal('')
export const thumbnail = signal<number>(null)
export const video = signal<number>(null)
export const sample = signal<number>(null)
export const isCountry = signal(false)
export const country = signal('')
export const isDate = signal(false)
export const startDate = signal('')
export const endDate = signal('')

// errors
const checkRequired = (value: string | number, field: string) =>
	didPublish.value && !value ? `${field} is required` : ''
const dateRequired = (date: string, field: string) =>
	didPublish.value && isDate.value && !date ? `${field} is required` : ''

export const titleMessage = computed(() => checkRequired(title.value, 'Title'))
export const primaryMessage = computed(() => checkRequired(primary.value, 'Primary Category'))
export const secondaryMessage = computed(() => checkRequired(secondary.value, 'Secondary Category'))
export const descriptionMessage = computed(() => checkRequired(description.value, 'Description'))
export const codeMessage = computed(() => checkRequired(code.value, 'Video Code'))
export const thumbnailMessage = computed(() => checkRequired(thumbnail.value, 'Thumbnail'))
export const videoMessage = computed(() => checkRequired(video.value, 'Video'))
export const sampleMessage = computed(() => checkRequired(sample.value, 'Video Sample'))
export const countryMessage = computed(() => checkRequired(country.value, 'Country'))
export const startDateMessage = computed(() => dateRequired(startDate.value, 'Start Date'))
export const endDateMessage = computed(() => dateRequired(endDate.value, 'End Date'))

export const isValid = computed(() => {
	let valid =
		!!title.value &&
		!!primary.value &&
		!!secondary.value &&
		!!description.value &&
		!!code.value &&
		!!thumbnail.value &&
		!!video.value &&
		!!sample.value
	if (isCountry.value) valid = valid && !!country.value
	if (isDate.value) valid = valid && !!startDate.value && !!endDate.value
	return valid
})

export const getVariables = (published: boolean) => {
	const variables: Record<string, any> = {
		is_published: published,
		video_type_id: 2,
		add_logo: false,
		add_practice_name: false,
		country_specific: isCountry.value,
		date_specific: isDate.value,
	}
	if (title.value) variables.video_name = title.value
	if (primary.value) variables.primary_category = primary.value.toString()
	if (secondary.value)
		variables.secondary_category = secondary.value.split(', ').map((id) => parseInt(id))
	if (description.value) variables.description = description.value
	if (code.value) variables.practice_ids = code.value
	if (thumbnail.value) variables.video_thumbnail = thumbnail.value
	if (video.value) variables.video = video.value
	if (sample.value) variables.sample_video = sample.value
	if (isCountry.value && country.value) variables.country = country.value
	if (isDate.value && startDate.value) variables.start_date = formatDate(startDate.value)
	if (isDate.value && endDate.value) variables.end_date = formatDate(endDate.value)
	return variables
}

export const resetFields = () => {
	didPublish.value = false
	title.value = ''
	primary.value = ''
	secondary.value = ''
	description.value = ''
	code.value = ''
	thumbnail.value = null
	video.value = null
	sample.value = null
	isCountry.value = false
	country.value = ''
	isDate.value = false
	startDate.value = ''
	endDate.value = ''
}
