/* eslint-disable @typescript-eslint/no-unused-vars */
import { Disclosure } from '@headlessui/react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  HomeIcon,
  UsersIcon,
  XIcon,
  ViewListIcon,
  DesktopComputerIcon,
  CogIcon,
  ServerIcon,
  VideoCameraIcon,
  SpeakerphoneIcon,
  ViewGridIcon,
} from '@heroicons/react/outline';

import logo from 'assets/img/logo.svg';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserIcon } from '@heroicons/react/solid';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

interface Props {
  sidebarOpen: boolean;
  setSidebarOpen: any;
}

const SideBar = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  console.log();

  const navigation = [
    { name: 'Home', icon: HomeIcon, current: false, href: '/#' },
    {
      name: 'Practices',
      icon: DesktopComputerIcon,
      current: location.pathname === '/practices' ? true : false,
      children: [
        { name: 'Practice List', href: '/practices', icon: ViewListIcon },
      ],
    },
    {
      name: 'Videos',
      icon: VideoCameraIcon,
      current: location.pathname === '/templates' ? true : false,
      children: [{ name: 'Templates', href: '/templates', icon: ViewGridIcon }],
    },

    {
      name: 'Announcements',
      icon: SpeakerphoneIcon,
      current: location.pathname === '/announcements' ? true : false,
      href: '/announcements',
    },

    {
      name: 'Production',
      icon: DesktopComputerIcon,
      current:
        location.pathname === '/videos/customization/request' ? true : false,
      href: '/videos/customization/requests',
    },
    {
      name: 'Administration',
      icon: ServerIcon,
      current: false,
      children: [
        { name: 'Users', href: '/#', icon: UsersIcon },
        { name: 'Settings', href: '/#', icon: CogIcon },
      ],
    },
  ];

  return (
    <>
      <Transition.Root show={props.sidebarOpen} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-40 md:hidden'
          onClose={props.setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
          </Transition.Child>

          <div className='fixed inset-0 flex z-40'>
            <Transition.Child
              as={Fragment}
              enter='transition ease-in-out duration-300 transform'
              enterFrom='-translate-x-full'
              enterTo='translate-x-0'
              leave='transition ease-in-out duration-300 transform'
              leaveFrom='translate-x-0'
              leaveTo='-translate-x-full'
            >
              <Dialog.Panel className='relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-in-out duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in-out duration-300'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <div className='absolute top-0 right-0 -mr-12 pt-2'>
                    <button
                      type='button'
                      className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                      onClick={() => props.setSidebarOpen(false)}
                    >
                      <span className='sr-only'>Close sidebar</span>
                      <XIcon
                        className='h-6 w-6 text-white'
                        aria-hidden='true'
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className='flex-shrink-0 flex items-center px-4'>
                  <Link to='/'>
                    <img className='h-8 w-auto' src={logo} alt='Fcom' />
                  </Link>
                </div>
                <div className='mt-5 flex-1 h-0 overflow-y-auto'>
                  <nav className='px-2 space-y-1'>
                    {navigation.map((item) =>
                      !item.children ? (
                        <div key={item.name}>
                          <a
                            href={item.href}
                            className={classNames(
                              item.current
                                ? 'bg-gray-100 text-gray-900'
                                : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                              'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md'
                            )}
                          >
                            <item.icon
                              className={classNames(
                                item.current
                                  ? 'text-gray-500'
                                  : 'text-gray-400 group-hover:text-gray-500',
                                'mr-3 flex-shrink-0 h-6 w-6'
                              )}
                              aria-hidden='true'
                            />
                            {item.name}
                          </a>
                        </div>
                      ) : (
                        <Disclosure
                          as='div'
                          key={item.name}
                          className='space-y-1'
                        >
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={classNames(
                                  item.current
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                  'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500'
                                )}
                              >
                                <item.icon
                                  className='mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500'
                                  aria-hidden='true'
                                />
                                <span className='flex-1'>{item.name}</span>
                                <svg
                                  className={classNames(
                                    open
                                      ? 'text-gray-400 rotate-90'
                                      : 'text-gray-300',
                                    'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400'
                                  )}
                                  viewBox='0 0 20 20'
                                  aria-hidden='true'
                                >
                                  <path
                                    d='M6 6L14 10L6 14V6Z'
                                    fill='currentColor'
                                  />
                                </svg>
                              </Disclosure.Button>
                              <Disclosure.Panel className='space-y-1'>
                                {item.children.map((subItem) => (
                                  <Disclosure.Button
                                    key={subItem.name}
                                    as='a'
                                    onClick={(e: any) => {
                                      e.preventDefault();

                                      navigate(subItem.href);
                                    }}
                                    href='/'
                                    className='group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                                  >
                                    <subItem.icon
                                      className={classNames(
                                        item.current
                                          ? 'text-gray-500'
                                          : 'text-gray-400 group-hover:text-gray-500',
                                        'mr-3 flex-shrink-0 h-6 w-6'
                                      )}
                                      aria-hidden='true'
                                    />
                                    {subItem.name}
                                  </Disclosure.Button>
                                ))}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      )
                    )}
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className='flex-shrink-0 w-14' aria-hidden='true'>
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className='hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0'>
        <div className='flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5 pb-4'>
          <div className='flex flex-shrink-0 items-center px-4'>
            <img className='h-8 w-auto' src={logo} alt='logo' />
          </div>
          <div className='mt-5 flex flex-grow flex-col'>
            <nav
              className='flex-1 space-y-1 bg-white px-2'
              aria-label='Sidebar'
            >
              {navigation.map((item) =>
                !item.children ? (
                  <div key={item.name}>
                    <a
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-gray-100 text-gray-900'
                          : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md'
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current
                            ? 'text-gray-500'
                            : 'text-gray-400 group-hover:text-gray-500',
                          'mr-3 flex-shrink-0 h-6 w-6'
                        )}
                        aria-hidden='true'
                      />
                      {item.name}
                    </a>
                  </div>
                ) : (
                  <Disclosure as='div' key={item.name} className='space-y-1'>
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={classNames(
                            item.current
                              ? 'bg-gray-100 text-gray-900'
                              : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500'
                          )}
                        >
                          <item.icon
                            className='mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500'
                            aria-hidden='true'
                          />
                          <span className='flex-1'>{item.name}</span>
                          <svg
                            className={classNames(
                              open
                                ? 'text-gray-400 rotate-90'
                                : 'text-gray-300',
                              'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400'
                            )}
                            viewBox='0 0 20 20'
                            aria-hidden='true'
                          >
                            <path d='M6 6L14 10L6 14V6Z' fill='currentColor' />
                          </svg>
                        </Disclosure.Button>
                        <Disclosure.Panel className='space-y-1'>
                          {item.children.map((subItem) => (
                            <Disclosure.Button
                              key={subItem.name}
                              as='a'
                              onClick={(e: any) => {
                                e.preventDefault();

                                navigate(subItem.href);
                              }}
                              href='/'
                              className='group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                            >
                              <subItem.icon
                                className={classNames(
                                  item.current
                                    ? 'text-gray-500'
                                    : 'text-gray-400 group-hover:text-gray-500',
                                  'mr-3 flex-shrink-0 h-6 w-6'
                                )}
                                aria-hidden='true'
                              />
                              {subItem.name}
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )
              )}
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
