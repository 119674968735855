import { cn } from 'util/cn';

type PropsType = {
  className?: string;
};

const TeamMemberIcon = ({ className }: PropsType) => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={cn('text-[#D6D6D6]', className)}
    >
      <rect width='40' height='40' rx='8' fill='currentColor' />
      <path
        d='M18.8878 21.4475C20.6337 21.4475 22.0491 20.0322 22.0491 18.2862C22.0491 16.5403 20.6337 15.125 18.8878 15.125C17.1419 15.125 15.7266 16.5403 15.7266 18.2862C15.7266 20.0322 17.1419 21.4475 18.8878 21.4475Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24.7283 28.7275C24.7283 26.1063 22.1183 23.9688 18.8895 23.9688C15.6608 23.9688 13.0508 26.095 13.0508 28.7275'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M29.625 20.0625C29.625 25.9688 24.8438 30.75 18.9375 30.75C13.0312 30.75 8.25 25.9688 8.25 20.0625C8.25 14.1562 13.0312 9.375 18.9375 9.375C20.4112 9.375 21.8175 9.66749 23.1 10.2075C22.9538 10.6575 22.875 11.13 22.875 11.625C22.875 12.4688 23.1113 13.2675 23.5275 13.9425C23.7525 14.325 24.045 14.6737 24.3825 14.9662C25.17 15.6862 26.2163 16.125 27.375 16.125C27.87 16.125 28.3425 16.0462 28.7812 15.8887C29.3212 17.1712 29.625 18.5888 29.625 20.0625Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M31.875 11.625C31.875 11.985 31.83 12.3337 31.74 12.6712C31.6387 13.1212 31.4587 13.56 31.2225 13.9425C30.6825 14.8537 29.8163 15.5625 28.7812 15.8887C28.3425 16.0462 27.87 16.125 27.375 16.125C26.2163 16.125 25.17 15.6862 24.3825 14.9662C24.045 14.6737 23.7525 14.325 23.5275 13.9425C23.1113 13.2675 22.875 12.4688 22.875 11.625C22.875 11.13 22.9538 10.6575 23.1 10.2075C23.3138 9.55499 23.6738 8.97002 24.1463 8.48627C24.9675 7.64252 26.115 7.125 27.375 7.125C28.7025 7.125 29.9063 7.69877 30.7163 8.62127C31.4363 9.42002 31.875 10.4775 31.875 11.625Z'
        stroke='white'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M29.0517 11.6016H25.6992'
        stroke='white'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M27.375 9.96094V13.3247'
        stroke='white'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default TeamMemberIcon;
