import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { uploadFile } from 'redux/actionCreators/videos'
import { cn } from 'util/cn'

type PropsType = {
	className?: string
	label?: string
	isMulti?: boolean
	accept?: 'all' | 'image' | 'video' | 'zip'
	onChange?: (value?: any) => void
	required?: boolean
	errorMessage?: string
}

const Dropzone = ({
	className,
	label,
	isMulti = false,
	accept = 'all',
	onChange,
	required = false,
	errorMessage = '',
}: PropsType) => {
	const [uploading, setUploading] = useState(false)
	const accepts = {
		all: {},
		image: {
			'image/png': ['.png', '.gif', '.jpeg', '.jpg', '.webp', '.avif', '.apng'],
		},
		video: {
			'video/mp4': ['.mp4', '.MP4', '.mov', '.avi', '.mkv', '.wmv', '.avchd', '.flv'],
		},
		zip: {
			'application/zip': ['.zip', '.rar', '.7zip'],
		},
	}

	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		accept: accepts[accept],
		multiple: isMulti,
	})

	useEffect(() => {
		const upload = async (file: File) => {
			setUploading(true)
			const res = await uploadFile(file)
			if (res) {
				setUploading(false)
				onChange(res.result.ID)
			}
		}
		if (acceptedFiles.length > 0) upload(acceptedFiles[0])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [acceptedFiles])

	return (
		<div className={cn('space-y-3', className)}>
			{label && (
				<label className='text-tailgrids-black font-karla font-bold block leading-[19px]'>
					{required ? <span className='text-accent-700 inline-block mr-0.5'>*</span> : ''}
					{label}
				</label>
			)}

			<div className='space-y-1.5'>
				<div
					{...getRootProps({ className: 'dropzone' })}
					className={cn(
						'flex items-center justify-center h-[54px] px-4 rounded-lg hover:bg-sky-gray cursor-pointer',
						errorMessage ? 'bg-dashed-error' : 'bg-dashed'
					)}
				>
					<input {...getInputProps()} />
					<p
						className={cn(
							'font-karla font-bold leading-normal',
							acceptedFiles.length > 0 ? 'text-tailgrids-black' : 'text-text-300'
						)}
					>
						{acceptedFiles.length > 0
							? `${uploading ? 'Uploading' : 'Uploaded'} ${acceptedFiles[0].name}${
									acceptedFiles.length > 1 ? ` and ${acceptedFiles.length - 1} more.` : ''
							  }`
							: `Drop ${isMulti ? 'files' : 'a file'} to add or Choose File${
									isMulti ? 's' : ''
							  } (optional)`}
					</p>
				</div>
				{errorMessage && (
					<p className='text-accent-700 text-sm font-bold font-karla text-end'>{errorMessage}</p>
				)}
			</div>
		</div>
	)
}

export default Dropzone
