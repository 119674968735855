import ReactDOM from 'react-dom/client';
import Application from './application';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import './assets/css/global.scss';
import './assets/css/custom.scss';
import 'react-tabs/style/react-tabs.css';
import 'react-toastify/dist/ReactToastify.css';
import '@fontsource-variable/karla';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <Application />
  </Provider>
);
