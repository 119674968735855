import { Card, Divider, Title } from 'components';
import ProductionRequirements from './production-requirements';
import CustomInputs from './custom-inputs';

const TemplateInputs = () => {
  return (
    <Card>
      <Title size='lg' className='py-5 px-7'>
        Template Inputs
      </Title>
      <Divider />
      <div className='py-5 px-7 space-y-[26px]'>
        <ProductionRequirements />
        <CustomInputs />
      </div>
    </Card>
  );
};

export default TemplateInputs;
