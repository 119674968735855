import { cn } from 'util/cn';

type PropsType = {
  className?: string;
};

const LinearMenuIcon = ({ className }: PropsType) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={cn('text-black', className)}
    >
      <g id='vuesax/linear/menu'>
        <g id='menu'>
          <g id='Group'>
            <path
              id='Vector'
              d='M15.3819 7.92C16.7405 7.92 17.8419 6.81862 17.8419 5.46C17.8419 4.10138 16.7405 3 15.3819 3C14.0232 3 12.9219 4.10138 12.9219 5.46C12.9219 6.81862 14.0232 7.92 15.3819 7.92Z'
              fill='currentColor'
            />
            <path
              id='Vector_2'
              d='M8.46001 7.92C9.81863 7.92 10.92 6.81862 10.92 5.46C10.92 4.10138 9.81863 3 8.46001 3C7.10139 3 6 4.10138 6 5.46C6 6.81862 7.10139 7.92 8.46001 7.92Z'
              fill='currentColor'
            />
            <path
              id='Vector_3'
              d='M15.3819 14.8419C16.7405 14.8419 17.8419 13.7405 17.8419 12.3819C17.8419 11.0233 16.7405 9.92188 15.3819 9.92188C14.0232 9.92188 12.9219 11.0233 12.9219 12.3819C12.9219 13.7405 14.0232 14.8419 15.3819 14.8419Z'
              fill='currentColor'
            />
            <path
              id='Vector_4'
              d='M15.3819 21.5294C16.7405 21.5294 17.8419 20.428 17.8419 19.0694C17.8419 17.7108 16.7405 16.6094 15.3819 16.6094C14.0232 16.6094 12.9219 17.7108 12.9219 19.0694C12.9219 20.428 14.0232 21.5294 15.3819 21.5294Z'
              fill='currentColor'
            />
            <path
              id='Vector_5'
              d='M8.46001 14.8419C9.81863 14.8419 10.92 13.7405 10.92 12.3819C10.92 11.0233 9.81863 9.92188 8.46001 9.92188C7.10139 9.92188 6 11.0233 6 12.3819C6 13.7405 7.10139 14.8419 8.46001 14.8419Z'
              fill='currentColor'
            />
            <path
              id='Vector_6'
              d='M8.46001 21.5294C9.81863 21.5294 10.92 20.428 10.92 19.0694C10.92 17.7108 9.81863 16.6094 8.46001 16.6094C7.10139 16.6094 6 17.7108 6 19.0694C6 20.428 7.10139 21.5294 8.46001 21.5294Z'
              fill='currentColor'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LinearMenuIcon;
