import { useEffect, useState } from 'react';
import { Button, Input } from 'components';
import { Switch } from 'lib/react-switch';
import { CustomInput } from 'features/video-to-library';
import { useVideo, useVideoActions } from './stores';

const NewQuestions = () => {
  const [fields, setFields] = useState<CustomInput>(null);
  const [questionCount, setQuestionCount] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const { expanded, customItems } = useVideo();
  const { setExpanded, setInput } = useVideoActions();

  const save = () => {
    if (!fields.description) return setErrorMessage('Description is required');
    if (expanded) setInput(expanded, fields);
    setExpanded(false);
  };

  useEffect(() => {
    const questionInput = customItems.find(
      ({ id, type }) => id === expanded && type === 'question'
    );
    const questionInputs = customItems.filter(
      ({ type }) => type === 'question'
    );
    const index = questionInputs.indexOf(questionInput);
    setFields(questionInput);
    setQuestionCount(index + 1);
  }, [expanded, customItems]);

  useEffect(() => {
    if (fields?.description) setErrorMessage('');
  }, [fields?.description]);

  if (fields)
    return (
      <>
        <div className='space-y-4'>
          <Input
            label={`Question #${questionCount} Description.`}
            helper='i.e / What services do you offer?'
            placeholder='Write text here'
            value={fields.description}
            onChange={(e) =>
              setFields({ ...fields, description: e.target.value })
            }
            required
            errorMessage={errorMessage}
          />
          <Switch
            checked={fields.is_required}
            onChange={(checked) =>
              setFields({ ...fields, is_required: checked })
            }
            label='Mandatory'
          />
        </div>
        <Button className='w-full' onClick={save}>
          Save and Continue
        </Button>
      </>
    );
};

export default NewQuestions;
