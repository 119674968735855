import { cn } from 'util/cn';

type PropsType = {
  className?: string;
};

const ImageFileIcon = ({ className }: PropsType) => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={cn('text-[#D6D6D6]', className)}
    >
      <rect width='40' height='40' rx='8' fill='currentColor' />
      <path
        d='M16.125 18.25C17.3676 18.25 18.375 17.2426 18.375 16C18.375 14.7574 17.3676 13.75 16.125 13.75C14.8824 13.75 13.875 14.7574 13.875 16C13.875 17.2426 14.8824 18.25 16.125 18.25Z'
        fill='#D6D6D6'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.625 9.25H16.125C10.5 9.25 8.25 11.5 8.25 17.125V23.875C8.25 29.5 10.5 31.75 16.125 31.75H22.875C28.5 31.75 30.75 29.5 30.75 23.875V18.25'
        fill='currentColor'
      />
      <path
        d='M20.625 9.25H16.125C10.5 9.25 8.25 11.5 8.25 17.125V23.875C8.25 29.5 10.5 31.75 16.125 31.75H22.875C28.5 31.75 30.75 29.5 30.75 23.875V18.25'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M23.7188 12.625H29.9062' stroke='white' strokeLinecap='round' />
      <path d='M26.8125 15.7188V9.53125' stroke='white' strokeLinecap='round' />
      <path
        d='M9.00391 28.3177L14.5502 24.5939C15.4389 23.9977 16.7214 24.0652 17.5202 24.7514L17.8914 25.0777C18.7689 25.8314 20.1864 25.8314 21.0639 25.0777L25.7439 21.0614C26.6214 20.3077 28.0389 20.3077 28.9164 21.0614L30.7502 22.6364'
        fill='currentColor'
      />
      <path
        d='M9.00391 28.3177L14.5502 24.5939C15.4389 23.9977 16.7214 24.0652 17.5202 24.7514L17.8914 25.0777C18.7689 25.8314 20.1864 25.8314 21.0639 25.0777L25.7439 21.0614C26.6214 20.3077 28.0389 20.3077 28.9164 21.0614L30.7502 22.6364'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ImageFileIcon;
