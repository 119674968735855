import { useVideoActions } from './stores';
import { CustomRadioType } from './types';

const CustomRadioItem = ({ label, value, icon }: CustomRadioType) => {
  const { setCustom } = useVideoActions();

  return (
    <label className='block'>
      <input
        name='custom-input'
        type='radio'
        className='sr-only peer'
        value={value}
        onChange={() => setCustom(value)}
      />
      <div className='h-[125px] w-full space-y-[15px] bg-[#FAFAFA] rounded-lg pt-7 text-tailgrids-gray [&>svg]:text-[#D6D6D6] [&>svg]:peer-checked:text-black peer-checked:bg-[#F2F5F9] border-2 border-transparent peer-checked:border-black'>
        {icon}
        <p className='font-karla text-sm font-bold leading-normal text-center'>
          {label}
        </p>
      </div>
    </label>
  );
};

export default CustomRadioItem;
