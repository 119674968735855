/* eslint-disable @typescript-eslint/no-unused-vars */

import Theme from "components/theme";
import { useEffect, useState } from "react";
import { currentUser } from "services/user";

import {
  DesktopComputerIcon,
  UsersIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";


const DashboardPage = () => {
  const [welcomeMessage, setWelcomeMessage]: any = useState("Dashboard");

  useEffect(() => {
    const initDashboard = async () => {
      // const user = await currentUser();

      // if (user.data.firstName) {
      //   setWelcomeMessage(`Welcome ${user.data.firstName}`);
      // }
    };

    initDashboard();
  });

 

  const stats = [
    { id: 1, name: 'Total Practices', stat: '306', icon: DesktopComputerIcon, change: '122', changeType: 'increase' },
    { id: 2, name: 'Total Trials', stat: '1', icon: UsersIcon, change: '5.4%', changeType: 'increase' },
    { id: 3, name: 'Total Videos', stat: '138', icon: UserGroupIcon, change: '3.2%', changeType: 'decrease' },
  ]
  
  return (
    <>
      <Theme>
        <main className="flex-1">
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <h1 className="text-2xl font-semibold text-gray-900">
                {welcomeMessage}
              </h1>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">

              <div>
      <h3 className="text-lg font-medium leading-6 text-gray-900 mt-8">Last 30 days</h3>

      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6"
          >
            <dt>
              <div className="absolute rounded-md bg-indigo-500 p-3">
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
            
              

              <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                <div className="text-sm">
                  <a href="/practices" className="font-medium text-indigo-600 hover:text-indigo-500">
                    {' '}
                    View all<span className="sr-only"> {item.name} stats</span>
                  </a>
                </div>
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>                

              </div>
            </div>
          </div>
        </main>
      </Theme>
    </>
  );
};

export default DashboardPage;
