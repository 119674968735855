/* eslint-disable */

import Theme from "components/theme";
import { useEffect, useState } from "react";
import { currentUser } from "services/user";
import {
  FilterIcon,
  PlusIcon,
  TrashIcon,
  UserIcon,
  ArrowUpIcon,
  ArrowDownIcon,
} from "@heroicons/react/outline";
import { getPractices, impersonate } from "services/practices";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import NewPracticeModal from "components/modals/new_practice";
import config from "config/config";
import moment from "moment-timezone";
import { CurrencyDollarIcon } from "@heroicons/react/solid";

import StripeIcon from "assets/img/stripe.png";
import EditIcon from "assets/img/edit.png";
import ImpersonateIcon from "assets/img/impersonate.png";





const PracticesPage = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [per_page, setPerPage] = useState(20);

  const [practices, setPractices] = useState([]);

  const [total, setTotal] = useState(0);

  const [userImpersonating, setUserImpersonating] = useState(false);

  const [applyFilters, setApplyFilters] = useState(false);

  const [modal, setModal] = useState(false);



  const [filters, setFilters]: any = useState({
    channel_key: null,
    practice_name: null,
    status: null,
    stripe: null,
    sort_by:null,
    sort_column:null,
  });

  const [impersonateId, setImpersonateId] = useState(0);

  const handlePageClick = (event: any) => {
    console.log(event.selected + 1);

    setCurrentPage(event.selected + 1);
  };

  const fetchPractices = async () => {
    console.log("Current Page: ", currentPage);

    const {
      data: { results, total },
    } = await getPractices({
      page: currentPage,
      per_page,
      ...filters,
    });

    setPractices(results);
    setTotal(total);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const impersonateHandle = (practice_id) => {
    if (impersonateId === practice_id) {
      return "Wait...";
    } else {
      return (
        <>
          
          <img src={ImpersonateIcon} alt="" className="icon-action" />

        </>
      );
    }
  };

  useEffect(() => {
    setLoading(true);

    const pagination = async () => {
      await fetchPractices();
      setLoading(false);
      return;
    };
    pagination();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  console.log("Loading : ", loading);

  return (
    <>
      <Theme>
        <div className="px-4 sm:px-6 lg:px-8 mt-10">
          <div className="sm:flex sm:items-center mb-5">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Practices</h1>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                onClick={() => {
                  setModal(true);
                }}
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                <PlusIcon className="mr-2 -ml-0.5 h-4 w-4" aria-hidden="true" />
                New Practice
              </button>
            </div>
          </div>

          

          <form className="w-full max-w-6xl	">
            <div className="flex  -mx-3 mb-2">
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Practice Name
                </label>
                <input
                  onChange={(e: any) => {
                    setFilters({
                      ...filters,
                      practice_name: e.target.value,
                    });
                  }}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-city"
                  type="text"
                />
              </div>
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Practice Status
                </label>
                <div className="relative">
                  <select
                    onChange={(e: any) => {
                      setFilters({
                        ...filters,
                        status: e.target.value,
                      });
                    }}
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-state"
                  >
                    <option value="" selected disabled>
                      Choose
                    </option>
                    <option value="1">Active</option>
                    <option value="4">Suspended</option>
                    <option value="5">Gone</option>
                    <option value="6">Admin</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Practice Code
                </label>
                <input
                  onChange={(e: any) => {
                    setFilters({
                      ...filters,
                      channel_key: e.target.value,
                    });
                  }}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-zip"
                  type="text"
                />
              </div>

              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Stripe Code
                </label>
                <input
                  onChange={(e: any) => {
                    setFilters({
                      ...filters,
                      stripe: e.target.value,
                    });
                  }}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-zip"
                  type="text"
                />
              </div>


              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Sort Column
                </label>
                <div className="relative">
                  <select
                    onChange={(e: any) => {
                      setFilters({
                        ...filters,
                        sort_column: e.target.value,
                      });
                    }}
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-state"
                  >
                    <option value="" selected disabled>
                      Choose
                    </option>
                    <option value="PracticeName">Practice Name</option>
                    <option value="total_practice_videos_active">Videos</option>
                    <option value="total_team_members">Team Members</option>
                    <option value="practiceId">Practice Id</option>


                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Sort Order
                </label>
                <div className="relative">
                  <select
                    onChange={(e: any) => {
                      setFilters({
                        ...filters,
                        sort_by: e.target.value,
                      });
                    }}
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-state"
                  >
                    <option value="" selected disabled>
                      Choose
                    </option>
                    <option value="ASC">Ascending</option>
                    <option value="DESC">Descending</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
              

              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <button
                  type="button"
                  className="flex space-x-2 items-center mt-6 focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                  onClick={async () => {
                    setApplyFilters(true);
                    setLoading(true);

                    const {
                      data: { results, total },
                    } = await getPractices({
                      page: currentPage,
                      per_page,
                      ...filters,
                    });

                    setPractices(results);
                    setTotal(total);

                    setApplyFilters(false);

                    setLoading(false);
                  }}
                >
                  <FilterIcon
                    className="-mr-0.5 h-4 w-4 mt-1"
                    aria-hidden="true"
                  />
                  <span>Apply Filters</span>
                </button>
              </div>
            </div>
          </form>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50 ch-custom-thead">
                      <tr>
                      <th
                          scope="col"
                          className="ch-custom-th py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                           Practice ID
                          
                        </th>
                        <th
                          colSpan={3}
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6 border-col"
                        >
                          Actions
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6 border-col"
                        >
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6 border-col"
                        >
                        </th>
                       
                        <th
                          scope="col"
                          className="ch-custom-th py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Practice
                          <div className="sorting">
                            <ArrowUpIcon
                              className="sort-icon"
                              aria-hidden="true"
                            />
                            <ArrowDownIcon
                              className="sort-icon font-bold"
                              aria-hidden="true"
                            />
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Code
                          <div className="sorting">
                            <ArrowUpIcon
                              className="sort-icon"
                              aria-hidden="true"
                            />
                            <ArrowDownIcon
                              className="sort-icon font-bold"
                              aria-hidden="true"
                            />
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Country
                          <div className="sorting">
                            <ArrowUpIcon
                              className="sort-icon"
                              aria-hidden="true"
                            />
                            <ArrowDownIcon
                              className="sort-icon font-bold"
                              aria-hidden="true"
                            />
                          </div>
                        </th>
                        {/* <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Stripe Code
                        </th> */}

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Team Members
                          <div className="sorting">
                            <ArrowUpIcon
                              className="sort-icon"
                              aria-hidden="true"
                            />
                            <ArrowDownIcon
                              className="sort-icon font-bold"
                              aria-hidden="true"
                            />
                          </div>
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Videos
                          <div className="sorting">
                            <ArrowUpIcon
                              className="sort-icon"
                              aria-hidden="true"
                            />
                            <ArrowDownIcon
                              className="sort-icon font-bold"
                              aria-hidden="true"
                            />
                          </div>
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Contact Name
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Status
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Offline
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          In-active Content
                        </th>

                        
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {loading === true ? (
                        <>
                          <tr>
                            <td colSpan={12}>
                              <p className="text-center p-10">
                                <strong> Loading... </strong>
                              </p>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          {practices.length ? (
                            <>
                              {practices.map((practice: any) => (
                                <tr key={practice.practiceId}>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ch-custom-td ">
                                    {practice.practiceId}
                                  </td>
                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                     <a
                                      onClick={async (e) => {
                                        e.preventDefault();
                                        setImpersonateId(practice.practiceId);

                                        setUserImpersonating(true);

                                        const res: any = await impersonate({
                                          practice_id: practice.practiceId,
                                        });

                                        setUserImpersonating(false);

                                        if (res.data.error) {
                                          setImpersonateId(0);

                                          Swal.fire({
                                            icon: "error",
                                            title: "Oops...",
                                            text: "User not found",
                                          });
                                        } else {
                                          setImpersonateId(0);
                                          window.open(
                                            `${config.defaults.practice_dashboard_url}?token=${res.data.accessToken}`,
                                            "_blank"
                                          );
                                        }

                                        console.log(res);
                                      }}
                                      className="btn-action btn"
                                    >
                                      {impersonateHandle(practice.practiceId)}
                                    </a>

                                    

                                    
                                  </td>
                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <a
                                      onClick={async (e) => {
                                        e.preventDefault();
                                        navigate(
                                          `/practices/edit/${practice.practiceId}`
                                        );
                                      }}
                                      className="btn-action btn"
                                    >
                                     <img src={EditIcon} alt="" className="icon-action cursor-fpointer" />

                                    </a>
                                  
                                  </td>
                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <a
                                      onClick={async (e) => {
                                        e.preventDefault();
                                        window.open(
                                          `https://dashboard.stripe.com/customers/${practice.StripeID}`,
                                          "_blank"
                                        );
                                      }}
                                      className="btn-action btn"
                                    >
                                     <img src={StripeIcon} alt="" className="icon-action cursor-pointer"/>

                                    </a>
                                  
                                  </td>
                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  </td>
                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ch-custom-td ">
                                    {practice.PracticeName}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ch-custom-td font-bold">
                                    {practice.PracticeBCode}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ch-custom-td ">
                                    {practice.CountryName}
                                  </td>
                                  {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {practice.StripeID}
                                  </td> */}
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ch-custom-td ">
                                    <span className="inline-flex p-3 justify-center items-center ml-2 w-4 h-4 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                                      {practice.total_team_members}
                                    </span>
                                  </td>

                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ch-custom-td ">
                                    <span className="inline-flex p-3 justify-center items-center ml-2 w-4 h-4 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                                      {practice.total_practice_videos_active}
                                    </span>
                                  </td>

                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {practice.contact_name}
                                  </td>

                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {practice.status}
                                  </td>

                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-bold">
                                    {practice.ModifiedOn
                                      ? moment
                                          .utc(practice.ModifiedOn)
                                          .local()
                                          .startOf("seconds")
                                          .fromNow()
                                      : "Not available"}
                                  </td>

                                  <td
                                    className={`whitespace-nowrap px-3 py-4 text-sm font-bold ${
                                      practice.inactive_content
                                        ? "text-gray-500"
                                        : "text-red-500"
                                    }`}
                                  >
                                    {practice.inactive_content
                                      ? moment
                                          .utc(practice.inactive_content)
                                          .local()
                                          .startOf("seconds")
                                          .fromNow()
                                      : "Playlist not available"}
                                  </td>

                                  
                                </tr>
                              ))}
                            </>
                          ) : (
                            <>
                              <>
                                <p className="text-center justify-center">
                                  {" "}
                                  <strong> No records found. </strong>
                                </p>
                              </>
                            </>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* Pagination  */}

          {loading === false && (
            <>
              <ReactPaginate
                className="mt-5 w-100 mb-7"
                previousClassName="g-white border-gray-300 text-gray-500 hover:bg-blue-200 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                activeClassName="bg-blue border-red-300 text-red-500 hover:bg-blue-200 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                pageClassName="g-white border-gray-300 text-gray-500 hover:bg-blue-200 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                nextClassName="g-white border-gray-300 text-gray-500 hover:bg-blue-200 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                breakClassName="g-white border-gray-300 text-gray-500 hover:bg-blue-200 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                disabledClassName="g-white border-gray-300 text-gray-500 hover:bg-blue-200 relative inline-flex items-center px-4 py-2 border text-sm font-medium disabled:opacity-50"
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={Math.ceil(total / per_page)}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
              />
            </>
          )}
        </div>

        <NewPracticeModal
          modal={modal}
          setModal={setModal}
          fetchPractices={fetchPractices}
        />
      </Theme>
    </>
  );
};

export default PracticesPage;
