import { ChangeEvent } from 'react';
import { cn } from 'util/cn';

type PropsType = {
  className?: string;
  label?: string;
  value?: boolean;
  onChange?: (e?: ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox = ({ className, label, value, onChange }: PropsType) => {
  return (
    <label
      className={cn(
        'flex gap-[7px] items-center max-w-fit font-karla text-sm font-bold text-tailgrids-gray leading-normal',
        className
      )}
    >
      <input
        type='checkbox'
        checked={value}
        onChange={onChange}
        className='sr-only peer'
      />
      <div className='w-5 h-5 rounded border border-tailgrids-gray peer-checked:border-tailgrids-indigo flex items-center justify-center [&>div]:hidden [&>div]:peer-checked:block'>
        <div className='w-2.5 h-2.5 bg-tailgrids-indigo rounded-sm' />
      </div>
      {label && label}
    </label>
  );
};

export default Checkbox;
