import { ActionType } from "../actionTypes/index";

const initialState = {};

const practiceReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case ActionType.GET_PRACTICE_DETAIL:
      return {
        ...state,
        type: action.type,
        payload: action.payload,
      };
    case ActionType.GET_PRACTICE_DETAIL_ERROR:
      return {
        ...state,
        type: action.type,
        payload: action.payload,
      };
    case ActionType.GET_PRACTICE_POSTAL_ADDRESS:
      return {
        ...state,
        type: action.type,
        payload: action.payload,
      };
    case ActionType.GET_PRACTICE_POSTAL_ADDRESS_ERROR:
      return {
        ...state,
        type: action.type,
        payload: action.payload,
      };

    case ActionType.GET_PRACTICE_PHYSICAL_ADDRESS:
      return {
        ...state,
        type: action.type,
        payload: action.payload,
      };
    case ActionType.GET_PRACTICE_PHYSICAL_ADDRESS_ERROR:
      return {
        ...state,
        type: action.type,
        payload: action.payload,
      };
    case ActionType.GET_TEAM_MEMBERS:
      return {
        ...state,
        type: action.type,
        payload: action.payload,
      };

    case ActionType.GET_TEAM_MEMBERS_ERROR:
      return {
        ...state,
        type: action.type,
        payload: action.payload,
      };

    case ActionType.GET_PRACTICE_VIDEOS:
      return {
        ...state,
        type: action.type,
        payload: action.payload,
      };

    case ActionType.GET_PRACTICE_VIDEOS_ERROR:
      return {
        ...state,
        type: action.type,
        payload: action.payload,
      };
    default:
      return state;
  }
};

export default practiceReducer;
