export const ActionType: any = {
  // User Actions

  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAIL: "USER_LOGIN_FAIL",

  // Practice

  GET_PRACTICE_DETAIL: "GET_PRACTICE_DETAIL",

  GET_PRACTICE_DETAIL_ERROR: "GET_PRACTICE_DETAIL_ERROR",

  GET_PRACTICE_PHYSICAL_ADDRESS: "GET_PRACTICE_PHYSICAL_ADDRESS",

  GET_PRACTICE_PHYSICAL_ADDRESS_ERROR: "GET_PRACTICE_PHYSICAL_ADDRESS_ERROR",

  GET_PRACTICE_POSTAL_ADDRESS: "GET_PRACTICE_POSTAL_ADDRESS",

  GET_PRACTICE_POSTAL_ADDRESS_ERROR: "GET_PRACTICE_POSTAL_ADDRESS_ERROR",

  GET_TEAM_MEMBERS: "GET_TEAM_MEMBERS",

  GET_TEAM_MEMBERS_ERROR: "GET_TEAM_MEMBERS_ERROR",

  GET_PRACTICE_VIDEOS: "GET_PRACTICE_VIDEOS",

  GET_PRACTICE_VIDEOS_ERROR: "GET_PRACTICE_VIDEOS_ERROR",

  GET_VIDEOS_CUSTOMIZATION_REQUESTS: "GET_VIDEOS_CUSTOMIZATION_REQUESTS",

  GET_VIDEOS_CUSTOMIZATION_REQUESTS_ERROR:
    "GET_VIDEOS_CUSTOMIZATION_REQUESTS_ERROR",

  GET_VIDEOS_CUSTOMIZATION_REQUEST_DETAIL:
    "GET_VIDEOS_CUSTOMIZATION_REQUEST_DETAIL",

  GET_VIDEOS_CUSTOMIZATION_REQUEST_DETAIL_ERROR:
    "GET_VIDEOS_CUSTOMIZATION_REQUEST_DETAIL_ERROR",
};

export default ActionType;
