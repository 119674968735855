import { Body, Button, Headline, useModalActions } from 'components';
import CustomRadioGrid from './custom-radio-grid';
import { useVideo, useVideoActions } from './stores';

const AddCustomModal = () => {
  const { close } = useModalActions();
  const { custom } = useVideo();
  const { addCustomItem, setCustom } = useVideoActions();

  const addItem = () => {
    if (custom) {
      addCustomItem(custom);
      setCustom('');
      return close();
    }
  };

  return (
    <div className='space-y-6'>
      <div className='space-y-4'>
        <Headline size='lg'>Request a custom user input</Headline>
        <Body>Select an option below to confirm details for this request</Body>
      </div>

      <CustomRadioGrid />

      <div className='space-y-4'>
        <Button className='block w-full' onClick={addItem}>
          Add
        </Button>
        <Button variant='secondary' onClick={close} className='block w-full'>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddCustomModal;
