import axios from "util/axios";
import config from "config/config";

export async function getPlaylist(params) {
  return axios.get(`${config.defaults.api_url}/videos/playlist`, { params });
}

export async function getCategories() {
  return axios.get(`${config.defaults.api_url}/videos/categories`);
}

export async function getVideos(params) {
  return axios.get(`${config.defaults.api_url}/videos/templates`, { params });
}

export async function changeCustomizationRequestEditor(id, params) {
  return axios.post(
    `${config.defaults.api_url}/videos/customization/request/change/editor/${id}`,
    params
  );
}

export async function ChangeCustomizationRequestStatus(id, params) {
  return axios.post(
    `${config.defaults.api_url}/videos/customization/request/status/${id}`,
    params
  );
}

export async function deleteCustomizationRequest(id) {
  return axios.post(
    `${config.defaults.api_url}/videos/customization/request/delete/${id}`);
}

export async function addCommentToCustomizationRequest(params) {
  return axios.post(
    `${config.defaults.api_url}/videos/customization/request/add/comment`,
    params
  );
}

export async function getCustomizationRequestComments(id) {
  return axios.get(
    `${config.defaults.api_url}/videos/customization/request/comments/${id}`);
}

export async function uploadCustomizedVideo(params) {
  return axios.post(
    `${config.defaults.api_url}/videos/customization/request/upload/video`,
    params
  );
}

export async function uploadFile(params) {
  return axios.post(
    `${config.defaults.api_url}/videos/customization/request/upload/file`,
    params
  );
}