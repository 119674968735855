/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import IPage from "interfaces/page";
import logging from "config/logging";
import logo from "assets/img/logo.svg";
import AccountImage from "assets/img/account-img.png";
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { login } from "redux/actionCreators/auth";
import { useNavigate } from "react-router-dom";
import cookie from "js-cookie";


interface RootState {
  auth: any;
}

const LoginPage: React.FunctionComponent<IPage> = props => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state);
  const [errors, setErrors] = useState(false);
  const navigate = useNavigate();
  const [forgotPassword,setForgotPassword] = useState(false);


  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props.name]);

  useEffect(() => {
    if (cookie.get("token")) {
      
       navigate("/dashboard");

      return;
    }
    if (user.auth.type === "USER_LOGIN_SUCCESS" && cookie.get("token")) {
      navigate("/dashboard");
      setIsSubmitting(false);
      setErrors(false);
    } else if (user.auth.type === "USER_LOGIN_FAIL") {
      setIsSubmitting(false);
      setErrors(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const initialValues = { email: "", password: "" };
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = (values: any) => {
    setIsSubmitting(true);

    const email = values.email;
    const password = values.password;

    setIsSubmitting(true);
    dispatch(
      login({
        email,
        password,
      })
    );
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const styles = {
    label: 'block text-gray-700 text-sm font-bold pt-2 pb-1',
    field:
      'bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none',
    button:
      'w-full disabled:cursor-not-allowed disabled:shadow-none disabled:text-white disabled:transform-none disabled:transition-none  cursor-pointer flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
    errorMsg: 'text-red-500 text-sm pb-3',
  }
  return (
    <React.Fragment>
      <main className="d-flex flex-col pt-3 px-4 px-md-5 h-screen text-center justify-center">
        <section className="d-flex align-items-center justify-content-between">
          <a href="/">
            <div className="brand-logo">
              <img src={logo} alt="Logo" />
            </div>
          </a>
        </section>
        <section className="grid gap-3 grid-cols-12 align-items-center pt-3 w-full h-full bg-right-bottom bg-no-repeat">
          <div className="col-span-12  sign-up-holder">
            <h1 className="title-welcome mb-4">Admin Channel D</h1>


            {errors && (

              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-5" role="alert">
                <span className="block sm:inline">Sorry, we don't recognize your credentials</span>
                <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => {
                  setErrors(false);
                }}>
                </span>
              </div>
            )}
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form className="grid gap-2">
                <div className="field-wel">
                  <Field className="form-control" id='email' name='email' placeholder='Email'/>
                </div>
                <ErrorMessage component='a' className={styles.errorMsg} name='email' />
                <div className="field-wel">

                  <Field className="form-control" id='password' name='password' type="password" placeholder="Password" />

                </div>
                <ErrorMessage
                  component='a'
                  className={styles.errorMsg}
                  name='password'
                />


                <button className="btn btn-block btn-sign-in button-bg-black"

                  type="submit"

                  disabled={isSubmitting}
                >

                  {isSubmitting && (

                    <svg className=" text-center w-4 h-4 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
                      viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path className="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                      </path>
                    </svg>
                  )}


                  {isSubmitting ? "" : "Sign in"}


                </button>


              </Form>
            </Formik>

            {/* <a href="/#" className="pt-3 forgot-link justify-center text-center">Forgot password?</a> */}
          </div>
          {/* <div className="d-none d-sm-block col-span-7 align-self-end lg:block">
            <div className="account-bg">
              <div className="account-bg-inner"></div>
              <img src={AccountImage} alt=""/>
            </div>
          </div> */}
        </section>
      </main>
    </React.Fragment>
  );
};

export default LoginPage;
