import type { ChangeEvent, ComponentPropsWithoutRef } from 'react';
import { cn } from 'util/cn';
import { generateId } from 'util/generated-id';

interface PropsType extends ComponentPropsWithoutRef<'input'> {
  className?: string;
  label?: string;
  helper?: string;
  type?: 'text' | 'number' | 'email' | 'password';
  placeholder?: string;
  id?: string;
  value?: string | number;
  onChange?: (e?: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  errorMessage?: string;
}

const Input = ({
  className,
  label,
  helper,
  type = 'text',
  placeholder = 'Type here',
  id = generateId(),
  value = '',
  onChange,
  required = false,
  errorMessage = '',
  ...rest
}: PropsType) => {
  return (
    <div className={cn('space-y-3', className)}>
      {label && (
        <label
          htmlFor={id}
          className='text-tailgrids-black font-karla font-bold block leading-[19px]'
        >
          {required ? (
            <span className='text-accent-700 inline-block mr-0.5'>*</span>
          ) : (
            ''
          )}
          {label}
          {helper && <span className='text-[#9C9D9E]'> {helper}</span>}
        </label>
      )}
      <div className='space-y-1.5'>
        <input
          type={type}
          id={id}
          className={cn(
            'text-base font-karla font-bold placeholder:text-tailgrids-gray text-tailgrids-black caret-tailgrids-indigo w-full border-2 rounded-lg h-[50px] flex items-center px-[22px] focus:border-tailgrids-indigo focus:ring-0',
            errorMessage ? 'border-error-900' : 'border-black'
          )}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          {...rest}
          required={required}
        />
        {errorMessage && (
          <p className='text-accent-700 text-sm font-bold font-karla text-end'>
            {errorMessage}
          </p>
        )}
      </div>
    </div>
  );
};

export default Input;
