import { cn } from 'util/cn';

type PropsType = {
  className?: string;
};

const OptionSelectIcon = ({ className }: PropsType) => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={cn('text-[#D6D6D6]', className)}
    >
      <rect width='40' height='40' rx='8' fill='currentColor' />
      <path
        d='M18.375 28.9375H29.625'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.375 21.0625H29.625'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.375 13.1875H29.625'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.375 13.1875L10.5 14.3125L13.875 10.9375'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default OptionSelectIcon;
