import axios from 'axios';
import { ActionType } from 'redux/actionTypes/index';
import { Dispatch } from 'redux';
import config from 'config/config';

export function getVideoCustomizationRequests(params) {
  return async function (dispatch: Dispatch) {
    try {
      await axios
        .get(`${config.defaults.api_url}/videos/customization/requests`, {
          params,
        })
        .then((res: any) => {
          dispatch({
            type: ActionType.GET_VIDEOS_CUSTOMIZATION_REQUESTS,
            payload: res,
          });
        });
    } catch (error) {
      console.log(error);

      dispatch({
        type: ActionType.GET_VIDEOS_CUSTOMIZATION_REQUESTS_ERROR,
        payload: {
          message: 'Unable to get video customization requests',
        },
      });
    }
  };
}

export async function getVideoQuestions(id) {
  return await axios.get(`${config.defaults.api_url}/videos/${id}/questions`);
}

export async function getVideoCustomizationQuestions(id) {
  return await axios.get(
    `${config.defaults.api_url}/videos/customization/questions/${id}`
  );
}

export async function getVideoCustomizationMembers(id) {
  return await axios.get(
    `${config.defaults.api_url}/videos/customization/members/${id}`
  );
}

export async function createCustomVideo(params: any) {
  return await axios.post(
    `${config.defaults.api_url}/videos/create/custom`,
    params
  );
}

export async function getVideoDescriptions(id) {
  return await axios.get(
    `${config.defaults.api_url}/videos/${id}/descriptions`
  );
}

export async function getVideoCategories() {
  return await axios.get(`${config.defaults.api_url}/videos/categories`);
}

export async function getCountries() {
  return await axios.get(`${config.defaults.api_url}/misc/countries`);
}

export async function getTemplates(params) {
  return await axios.get(`${config.defaults.api_url}/videos/templates`, {
    params,
  });
}

export async function uploadFile(file: File) {
  const formData = new FormData();
  formData.append('file', file);
  const result = await axios.post(
    `${config.defaults.api_url}/misc/upload`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  );
  return result.data;
}

export async function createTemplate(variables) {
  return await axios.post(
    `${config.defaults.api_url}/videos/templates`,
    variables
  );
}
