import { Body, Label, ModalType, useModalActions } from 'components';
import { cn } from 'util/cn';
import CustomInputList from './custom-input-list';
import CustomRadioGrid from './custom-radio-grid';
import AddButton from './add-button';
import AddCustomModal from './add-custom-modal';
import { useVideo } from './stores';

type PropsType = {
  className?: string;
};

const CustomInputs = ({ className }: PropsType) => {
  const { customItems } = useVideo();
  const { trigger } = useModalActions();

  const addCustomModal: ModalType = {
    component: <AddCustomModal />,
    size: 745,
  };

  return (
    <div className={cn('space-y-2.5', className)}>
      <Label>Request a custom user input</Label>
      <Body size='md' className='text-tailgrids-gray'>
        Select an option below to confirm details for this request
      </Body>
      {customItems.length > 0 ? (
        <div className='space-y-3.5'>
          <CustomInputList />
          <AddButton label='input' onClick={() => trigger(addCustomModal)} />
        </div>
      ) : (
        <CustomRadioGrid />
      )}
    </div>
  );
};

export default CustomInputs;
