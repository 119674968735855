import { cn } from 'util/cn';

type PropsType = {
  className?: string;
  steps: string[];
  currentStep: number;
  setStep?: (step: number) => void;
};

const Stepper = ({ className, steps, currentStep, setStep }: PropsType) => {
  return (
    <ol
      className={cn(
        'flex items-center max-w-[478px] w-full justify-between pb-[27px]',
        className
      )}
    >
      {steps.map((step, index) => (
        <li
          className={cn(index !== 0 && 'flex items-center w-full')}
          key={index}
        >
          {index !== 0 && (
            <div
              className={cn(
                'h-[2px] w-full',
                currentStep < index + 1 ? 'bg-[#E7E7E7]' : 'bg-black'
              )}
            />
          )}
          <button
            className={cn(
              'font-karla text-sm font-bold w-[26px] min-w-[26px] aspect-square rounded-full flex items-center justify-center relative',
              currentStep > index + 1 &&
                'text-white bg-black border-2 border-black',
              currentStep === index + 1 && 'text-black border-2 border-black',
              currentStep < index + 1 && 'text-black border-2 border-[#E7E7E7]'
            )}
            onClick={() => setStep && setStep(index + 1)}
          >
            {index + 1}
            <p className='font-karla text-sm font-bold text-tailgrids-black mt-[7px] absolute top-[26px] w-max'>
              {step}
            </p>
          </button>
        </li>
      ))}
    </ol>
  );
};

export default Stepper;
