import { cn } from 'util/cn';

type PropsType = {
  className?: string;
};

const Divider = ({ className }: PropsType) => {
  return <div className={cn('w-full h-[1px] bg-stroke', className)} />;
};

export default Divider;
