import { useState } from 'react';

export const useStepper = (...stepsArgs: string[]) => {
  const steps = stepsArgs;
  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () =>
    currentStep < steps.length && setCurrentStep(currentStep + 1);
  const prevStep = () => currentStep > 1 && setCurrentStep(currentStep - 1);
  const setStep = (step: number) => setCurrentStep(step);

  return { steps, currentStep, nextStep, prevStep, setStep };
};
