import { ChangeEvent } from 'react'
import { cn } from 'util/cn'
import { generateId } from 'util/generated-id'

type PropsType = {
	className?: string
	label?: string
	placeholder?: string
	id?: string
	value?: string
	onChange?: (e?: ChangeEvent<HTMLTextAreaElement>) => void
	required?: boolean
	errorMessage?: string
}

const Textarea = ({
	className,
	label,
	placeholder = 'Type here',
	id = generateId(),
	value = '',
	onChange,
	required = false,
	errorMessage = '',
}: PropsType) => {
	return (
		<div className={cn('space-y-3', className)}>
			{label && (
				<label
					htmlFor={id}
					className='text-tailgrids-black font-karla font-bold block leading-[19px]'
				>
					{required ? <span className='text-accent-700 inline-block mr-0.5'>*</span> : ''}
					{label}
				</label>
			)}
			<div className='space-y-1.5'>
				<textarea
					id={id}
					className={cn(
						'text-base font-karla font-bold placeholder:text-tailgrids-gray text-tailgrids-black caret-tailgrids-indigo w-full border-2 rounded-lg h-[118px] flex items-center px-[22px] focus:border-tailgrids-indigo focus:ring-0',
						errorMessage ? 'border-error-900' : 'border-black'
					)}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
				/>
				{errorMessage && (
					<p className='text-accent-700 text-sm font-bold font-karla text-end'>{errorMessage}</p>
				)}
			</div>
		</div>
	)
}

export default Textarea
