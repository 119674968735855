/* eslint-disable */

import Theme from "components/theme";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";


import {
  ArrowLeftIcon as ArrowLongLeftIcon,
  CheckIcon,
  HandIcon as HandThumbUpIcon,
  UserIcon,
  ViewListIcon,
  ClockIcon,
} from "@heroicons/react/solid";
import moment from "moment-timezone";

import Board from "react-trello";
import { useDispatch, useSelector } from "react-redux";
import { getVideoCustomizationRequests } from "redux/actionCreators/videos";
import ActionType from "redux/actionTypes";
import { getPracticesList } from "services/practices";
import { changeCustomizationRequestEditor, deleteCustomizationRequest } from "services/videos";
import { ToastContainer, toast } from "react-toastify";
import config from "config/config";


interface RootState {
  auth;
  practice: any;
  videos: any;
}

const VideosCustomizationRequests = () => {
  const navigate = useNavigate();

  const state = useSelector((state: RootState) => state);

  const [loading,setLoading]  = useState(false);

  const [showThumbnail,setShowThumbnail]  = useState(true);

  const [practices,setPractices] = useState([]);




  const [cards , setCards] = useState([]);

  const pending = [];
  const chris = [];
  const kris = [];
  const ryan = [];


  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(getVideoCustomizationRequests({}));

    const init  = async () => {

       const response = await getPracticesList();

       setPractices(response.data.results);       
    }

    init();

  }, []);

  useEffect(() => {
    if (
      state.videos &&
      state.videos.type === ActionType.GET_VIDEOS_CUSTOMIZATION_REQUESTS
    ) {
      const data = [];
      state.videos?.payload?.data?.results.forEach((x) => {
        console.log("==== Pending ====");
        // console.log(state.videos?.payload?.data?.results)
        if (x.customization_status === "Pending" && x.editor_id === null) {
          data.push({
            video_thumbnail: x.video_thumbnail,
            body: "",
            description: "",
            id: x.customization_id,
            label: "",
            laneId: "PENDING",
            title: x.video_title,
            practice_name: x.practice_name,
            editor_id: x.editor_id,
            video_id: x.video_id,
            practice_id: x.practice_id,
            customization_status: x.customization_status,
            customization_created_on : x.customization_created_on,
          });
        } else  if (x.customization_status === "Complete") {
          data.push({
            video_thumbnail: x.video_thumbnail,
            body: "",
            description: "",
            id: x.customization_id,
            label: "",
            laneId: "COMPLETED",
            title: x.video_title,
            practice_name: x.practice_name,
            editor_id: x.editor_id,
            video_id: x.video_id,
            practice_id: x.practice_id,
            customization_status: x.customization_status,
            customization_created_on : x.customization_created_on,
          });
        }

        else  {
          data.push({
            video_thumbnail: x.video_thumbnail,
            body: "",
            description: "",
            id: x.customization_id,
            label: "",
            laneId: x.editor_id,
            title: x.video_title,
            practice_name: x.practice_name,
            editor_id: x.editor_id,
            video_id: x.video_id,
            practice_id: x.practice_id,
            customization_status: x.customization_status,
            customization_created_on : x.customization_created_on,

          });
        }


        setLoading(false);

        setCards(data);

      });
    }
  }, [state]);

  
  const changeEditor = (id,editor_id) => {
    changeCustomizationRequestEditor(id, { editor_id }).then(() => {
       
      if(editor_id === "ec5a9915-0065-4eff-8b9f-b2a96a9226cb") {
        toast.success("Customization request assigned to Christoffer Dionisio!")
      }

      if(editor_id === "dc32bd65-3da7-468d-8a70-26580141c095") {
        toast.success("Customization request assigned to Kristoffer Rhey de Jesus!")
      }

      if(editor_id === "2bb96e52-72de-4ba1-a8d4-8699dbc6069c") {
        toast.success("Customization request assigned to Ryan Castilar!")
      }

    });
  };

  console.log("==== Customization Video Requests Cards ====");

  console.log(cards);


  const Card = (props) => {
    console.log("==== Props ====");
    console.log(props);
    return (
      <>
        {props.laneId === "PENDING" && (
          <>
            <div
              onClick={props.onClick}
              className="card-border cursor-pointer max-w-[300px] m-[auto] relative  mt-[20px] rounded overflow-hidden border shadow-sm rouned-[8px]"
            >
              { showThumbnail ? <img className="w-full" src={props.video_thumbnail} /> : null}
              <div className="p-[8px] whitespace-normal">
                <div className="text-[#212B36] font-[600] text-[13px] mb-[8px]">
                  {props.title}
                </div>

                <div className="text-[#212B36] text-[12px] mb-[8px]">
                 <strong>Practice : </strong> {props.practice_name}
                </div>
                <p className="text-[#212B36] text-[13px] font-[400]">
                Requested : {moment(props.customization_created_on)
                        .utc()
                        .format("DD/MM/YYYY, h:mm:ss a")}{" "}
                      (UTC)
                </p>
                {/* <button 
                  className="bg-[#DC3545] text-center w-[46px] h-[28px] my-[20px] rounded-full inline-block"
                  onClick={ async () => {
                    const deleteRequest = confirm("Are you sure you want to delete request?");
                    if(deleteRequest) {

                       await deleteCustomizationRequest(props.id);
                      
                       toast.success("Customization request removed successfully!")
                    }
                  }}
                  >
                  <img
                    className="inline-flex w-[15px]"
                    src="https://rollingscents.wpengine.com/wp-content/uploads/2023/05/Vector.png"
                  />
                </button> */}
              </div>
            </div>
          </>
        )}

        {props.laneId === "COMPLETED" && (
          <>
            <div
              onClick={props.onClick}
              className="card-border cursor-pointer max-w-[300px] m-[auto] relative  mt-[20px] rounded overflow-hidden border shadow-sm rouned-[8px]"
            >
              { showThumbnail ? <img className="w-full" src={props.video_thumbnail} /> : null}
              <div className="p-[8px] whitespace-normal">
                <div className="text-[#212B36] font-[600] text-[13px] mb-[8px]">
                  {props.title}
                </div>

                <div className="text-[#212B36] text-[12px] mb-[8px]">
                 <strong>Practice : </strong> {props.practice_name}
                </div>
                <p className="text-[#212B36] text-[13px] font-[400]">
                Requested : {moment(props.customization_created_on)
                        .utc()
                        .format("DD/MM/YYYY, h:mm:ss a")}{" "}
                      (UTC)
                </p>
                {/* <button 
                  className="bg-[#DC3545] text-center w-[46px] h-[28px] my-[20px] rounded-full inline-block"
                  onClick={ async () => {
                    const deleteRequest = confirm("Are you sure you want to delete request?");
                    if(deleteRequest) {

                       await deleteCustomizationRequest(props.id);
                      
                       toast.success("Customization request removed successfully!")
                    }
                  }}
                  >
                  <img
                    className="inline-flex w-[15px]"
                    src="https://rollingscents.wpengine.com/wp-content/uploads/2023/05/Vector.png"
                  />
                </button> */}
              </div>
            </div>
          </>
        )}

        {props.laneId === "ec5a9915-0065-4eff-8b9f-b2a96a9226cb" && (
          <>
            <div
              onClick={props.onClick}
              className="card-border cursor-pointer max-w-[300px] m-[auto] relative  mt-[20px] rounded overflow-hidden border shadow-sm rouned-[8px]"
            >
              { showThumbnail ? <img className="w-full" src={props.video_thumbnail} /> : null}
              <div className=" p-[8px] whitespace-normal">
                <div className="text-[#212B36] font-[600] text-[13px] mb-[8px]">
                  {props.title}
                </div>
                <div className="text-[#212B36] text-[12px] mb-[8px]">
                 <strong>Practice : </strong> {props.practice_name}
                </div>
                <p className="text-[#212B36] text-[13px] font-[400]">
                Requested : {moment(props.customization_created_on)
                        .utc()
                        .format("DD/MM/YYYY, h:mm:ss a")}{" "}
                      (UTC)
                </p>
              </div>
            </div>
          </>
        )}

        {props.laneId === "dc32bd65-3da7-468d-8a70-26580141c095" && (
          <>
            <div
              onClick={props.onClick}
              className="card-border cursor-pointer max-w-[300px] m-[auto] relative  mt-[20px] rounded overflow-hidden border shadow-sm rouned-[8px]"
            >
              { showThumbnail ? <img className="w-full" src={props.video_thumbnail} /> : null}
              <div className=" p-[8px] whitespace-normal">
                <div className="text-[#212B36] font-[600] text-[13px] mb-[8px]">
                  {props.title}
                </div>
                <div className="text-[#212B36] text-[12px] mb-[8px]">
                 <strong>Practice : </strong> {props.practice_name}
                </div>
                <p className="text-[#212B36] text-[13px] font-[400]">
                Requested : {moment(props.customization_created_on)
                        .utc()
                        .format("DD/MM/YYYY, h:mm:ss a")}{" "}
                      (UTC)
                </p>
              </div>
            </div>
          </>
        )}

        {props.laneId === "2bb96e52-72de-4ba1-a8d4-8699dbc6069c" && (
          <>
            <div
              onClick={props.onClick}
              className="card-border cursor-pointer max-w-[300px] m-[auto] relative  mt-[20px] rounded overflow-hidden border shadow-sm rouned-[8px]"
            >
              { showThumbnail ? <img className="w-full" src={props.video_thumbnail} /> : null}
              <div className=" p-[8px] whitespace-normal">
                <div className="text-[#212B36] font-[600] text-[13px] mb-[8px]">
                  {props.title}
                </div>
                <div className="text-[#212B36] text-[12px] mb-[8px]">
                 <strong>Practice : </strong> {props.practice_name}
                </div>
                <p className="text-[#212B36] text-[13px] font-[400]">
                 Requested : {moment(props.customization_created_on)
                        .utc()
                        .format("DD/MM/YYYY, h:mm:ss a")}{" "}
                      (UTC)
                </p>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  const handleCardClick = (cardId, metadata, laneId) => {
    console.log("cardid", cardId);
    console.log("laneid", laneId);

    window.open(
      `${config.defaults.admin_url}/videos/customization/requests/${cardId}`,
      "_blank"
    )
  };

  const Header = (props) => {
    console.log("==== Props Header ====");
    console.log(props);
    return (
      <>
        <>
          <div className="flex text-center">
            <h5 className="font-[600] mr-[230px] text-[15px] inline-block text-[#212B36]">
              {props.title}
            </h5>
          </div>
        </>
      </>
    );
  };

  const components = {
    Card: (props) => Card({ ...props }),
    LaneHeader: (props) => Header({ ...props }),
  };

  return (
    <>
      <Theme>
        <div className="container font-['inter'] ml-5  py-[90px]">
          <h4 className="font-[600] text-[24px] text-[#212B36]">Production</h4>
          <p className="font-[500] mb-[20px] pb-[25px] border border-b-1 border-x-0 border-t-0 text-[14px] pt-[7px] text-[#637381]">
            Current videos/photos in production
          </p>
          <div className="inline-flex items-center">
            <h5 className="font-[600] text-[16px] text-[#212B36]">Filter by</h5>
            <div className="relative inline-block text-left">
              <div className="items-center">
                <select
                  onChange={(e) => {

                     const id = parseInt(e.target.value);

                     setLoading(true);

                     dispatch(getVideoCustomizationRequests({
                        practice_id:id
                     }));
                  }}
                  className="inline-flex cursor-pointer ml-[25px] w-[250px] h-[50px] items-center justify-center gap-x-2.5 rounded-md bg-white px-3 py-2 text-sm font-[500] text-[#637381] ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  <option selected disabled> Choose Practice </option>

                  {practices && practices.map((x) => {


                      return (

                         <>
                            <option value={x.practice_id}>{x.practice_name}</option>
                         </>
                      )
                  })}
                </select>
                <select
                   onChange={(e) => {

                    const id = e.target.value;

                    setLoading(true);

                    dispatch(getVideoCustomizationRequests({
                       editor_id:id
                    }));
                 }}
                  className="inline-flex cursor-pointer ml-[6px] w-[200px] h-[50px] items-center justify-center gap-x-2.5 rounded-md bg-white px-3 py-2 text-sm font-[500] text-[#637381] ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  <option selected disabled>Editor</option>
                  <option value="ec5a9915-0065-4eff-8b9f-b2a96a9226cb">
                    Christoffer Dionisio
                  </option>
                  <option value="dc32bd65-3da7-468d-8a70-26580141c095">
                    Kristoffer Rhey de Jesus
                  </option>
                  <option value="2bb96e52-72de-4ba1-a8d4-8699dbc6069c">
                    Ryan Castilar
                  </option>
                </select>
                <select
                   onChange={(e) => {

                    const id = e.target.value;

                    setLoading(true);

                    dispatch(getVideoCustomizationRequests({
                       status:id
                    }));
                 }}
                  className="inline-flex cursor-pointer mr-[95px] ml-[6px] w-[115px] h-[50px] items-center justify-center gap-x-2.5 rounded-md bg-white px-3 py-2 text-sm font-[500] text-[#637381] ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  <option selected disabled>Status</option>
                  <option value="1">Pending</option>
                  <option value="2">Approved</option>
                  <option value="3">Cancelled</option>
                  <option value="4">Edited</option>
                  <option value="5">Rejected</option>
                </select>
                <h5 className="font-[600] text-[16px] inline-block text-[#212B36]">
                  Show thumbnail
                </h5>
                <label className="relative top-[6px] ml-[20px] inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={showThumbnail}
                    className="sr-only peer"
                    onChange={() => setShowThumbnail(!showThumbnail)}

                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                </label>
              </div>
            </div>
          </div>

          {cards.length > 0 && (
            <Board
              onCardClick={handleCardClick}
              onCardDelete={(cardId, laneId) => {

                alert("Card Deleted")
                console.log(cardId, laneId)

              }}
              onCardMoveAcrossLanes={(fromLaneId, toLaneId, cardId, index) => {

                console.log("=== Card Move ===")
                console.log(fromLaneId, toLaneId, cardId, index);
                changeEditor(cardId,toLaneId);
              }}
              className="mt-8 rounded"
              style={{
                backgroundColor: "#f0f0f0",
                fontFamily: "Verdana",
                padding: "30px 20px",
              }}
              data={{
                lanes: [
                  {
                    cards: cards.filter(x => x.laneId === "PENDING"),
                    currentPage: 1,
                    disallowAddingCard: true,
                    id: "PENDING",
                    label: "",
                    style: {
                      //   boxShadow: "2px 2px 4px 0px rgba(0,0,0,0.50)",
                      width: 300,
                      backgroundColor: "white",
                    },
                    title: "Unassigned",
                  },
                  {
                    cards: cards.filter(x => x.laneId === "ec5a9915-0065-4eff-8b9f-b2a96a9226cb"),
                    currentPage: 1,
                    id: "ec5a9915-0065-4eff-8b9f-b2a96a9226cb",
                    label: "",
                    style: {
                      // boxShadow: "2px 2px 4px 0px rgba(0,0,0,0.50)",
                      width: 300,
                      backgroundColor: "white",
                    },
                    disallowAddingCard: true,
                    title: "Christoffer Dionisio",
                  },
                  {
                    cards: cards.filter(x => x.laneId === "dc32bd65-3da7-468d-8a70-26580141c095"),
                    currentPage: 1,
                    id: "dc32bd65-3da7-468d-8a70-26580141c095",
                    label: "",
                    style: {
                      // boxShadow: "2px 2px 4px 0px rgba(0,0,0,0.50)",
                      width: 300,
                      backgroundColor: "white",
                    },
                    disallowAddingCard: true,
                    title: "Kristoffer Rhey de Jesus",
                  },
                  {
                    cards: cards.filter(x => x.laneId === "2bb96e52-72de-4ba1-a8d4-8699dbc6069c"),
                    currentPage: 1,
                    id: "2bb96e52-72de-4ba1-a8d4-8699dbc6069c",
                    label: "",
                    style: {
                      // boxShadow: "2px 2px 4px 0px rgba(0,0,0,0.50)",
                      width: 300,
                      backgroundColor: "white",
                    },
                    disallowAddingCard: true,
                    title: "Ryan Castilar",
                  
                  },

                  {
                    cards: cards.filter(x => x.laneId === "COMPLETED"),
                    currentPage: 1,
                    disallowAddingCard: true,
                    id: "COMPLETED",
                    label: "",
                    style: {
                      //   boxShadow: "2px 2px 4px 0px rgba(0,0,0,0.50)",
                      width: 300,
                      backgroundColor: "white",
                    },
                    title: "Completed",
                  },
                  
                ],
              }}
              components={components}
            />
          )}

          { loading  && (

              <>
                 <div className="container font-['inter'] mx-auto text-center py-[90px] block  loader-board">

                 <svg
                    className=" text-center w-[25px] h-[25px] mr-3 -ml-1 text-black animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                    ></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                    </svg>
                    </div>
              
              </>
          )}


        </div>
      </Theme>
    </>
  );
};

export default VideosCustomizationRequests;
