import { Checkbox, Label } from 'components';
import { cn } from 'util/cn';
import { useVideo, useVideoActions } from './stores';

type PropsType = {
  className?: string;
};

const ProductionRequirements = ({ className }: PropsType) => {
  const { isAddLogo, isAddPracticeName } = useVideo();
  const { setIsAddLogo, setIsAddPracticeName } = useVideoActions();

  return (
    <div className={cn('space-y-3', className)}>
      <Label>Production Requirements</Label>
      <ul className='space-y-3'>
        <li>
          <Checkbox
            label='Add logo'
            value={isAddLogo}
            onChange={(e) => setIsAddLogo(e.target.checked)}
          />
        </li>
        <li>
          <Checkbox
            label='Add practice name'
            value={isAddPracticeName}
            onChange={(e) => setIsAddPracticeName(e.target.checked)}
          />
        </li>
      </ul>
    </div>
  );
};

export default ProductionRequirements;
