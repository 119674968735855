/* eslint-disable */

import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import Theme from 'components/theme';
import StandardVideoModal from 'components/modals/standard_video';
import { getTemplates } from 'redux/actionCreators/videos';
import { getCategories } from 'services/videos';

const NEW_TEMPLATE_DROPDOWNS: { name: string; href: string }[] = [
  {
    name: 'Individual',
    href: '/videos/new-individual',
  },
  {
    name: 'Instant-play',
    href: '/videos/new-instant-play',
  },
  {
    name: 'Custom Template',
    href: '/videos/new',
  },
];

const VideosPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [per_page] = useState(20);
  const [templates, setTemplates] = useState([]);
  const [total, setTotal] = useState(0);
  const [_, setCategories] = useState([]);
  const [standardVideoModal, setStandardVideoModal] = useState(false);
  const [filters]: any = useState({
    category_id: null,
    title: null,
    status: null,
  });

  const handlePageClick = (event: any) => setCurrentPage(event.selected + 1);

  const fetchTemplates = async () => {
    setLoading(true);
    const {
      data: { results, _ },
    } = await getTemplates({
      page: currentPage,
      per_page,
      ...filters,
    });
    setLoading(false);
    setTotal(results.total);
    setTemplates(results.data);
  };

  useEffect(() => {
    const init = async () => {
      const {
        data: { results },
      } = await getCategories();
      setCategories(results);
    };
    init();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const pagination = async () => {
      await fetchTemplates();
      return;
    };
    pagination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <Theme>
      <div className='px-4 sm:px-6 lg:px-8 mt-10'>
        <div className='sm:flex sm:items-center mb-5'>
          <div className='sm:flex-auto'>
            <h1 className='text-xl font-semibold text-gray-900'>Templates</h1>
          </div>
          <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
            <Menu as='div' className='relative inline-block text-left'>
              <div>
                <Menu.Button className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'>
                  New Template &nbsp;
                  <ChevronDownIcon
                    className='-mr-1 h-5 w-5 text-gray-400'
                    aria-hidden='true'
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <Menu.Items className='absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                  <div className='py-1'>
                    {NEW_TEMPLATE_DROPDOWNS.map(({ name, href }) => (
                      <Menu.Item key={name}>
                        <button
                          onClick={() => navigate(href)}
                          className='text-gray-700 block px-4 py-2 text-sm hover:bg-gray-50 w-full text-start'
                        >
                          {name}
                        </button>
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>

        <div className='bg-white'>
          <div className='px-4 sm:px-8 lg:px-8 mb-5'>
            <div className='mt-8 flow-root'>
              <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead>
                      <tr>
                        <th
                          scope='col'
                          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                        >
                          Title
                        </th>
                        <th
                          scope='col'
                          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                        >
                          Category
                        </th>
                        <th
                          scope='col'
                          className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                        >
                          Status
                        </th>

                        <th
                          scope='col'
                          className='relative py-3.5 pl-3 pr-4 sm:pr-0'
                        >
                          <span className='sr-only'>Edit</span>
                        </th>
                      </tr>
                    </thead>
                    {loading ? (
                      <>
                        <tbody className='pt-4'>
                          <tr>
                            <td className='whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0'>
                              Loading...
                            </td>
                          </tr>
                        </tbody>
                      </>
                    ) : (
                      <>
                        {templates.length > 0 ? (
                          <>
                            <tbody className='divide-y divide-gray-200 bg-white'>
                              {templates.map((x, index) => (
                                <tr key={index}>
                                  <td className='whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0'>
                                    <div className='flex items-center'>
                                      <div className='h-11 w-11 flex-shrink-0'>
                                        <img
                                          className='h-11 w-11 rounded-full'
                                          src={x.thumbnail_file}
                                          alt=''
                                        />
                                      </div>
                                      <div className='ml-4'>
                                        <div className='font-medium text-gray-900 '>
                                          {x.title}
                                        </div>
                                        {/* <div className="mt-1 text-gray-500 break-words keep-all">
                                            <div
                                              dangerouslySetInnerHTML={{ __html: x.description }}
                                            />

                                          </div> */}
                                      </div>
                                    </div>
                                  </td>
                                  <td className='whitespace-nowrap px-3 py-5 text-sm text-gray-500'>
                                    <div className='text-gray-900'>
                                      {x.category_name}
                                    </div>
                                  </td>
                                  <td className='whitespace-nowrap px-3 py-5 text-sm text-gray-500'>
                                    <span className='inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
                                      Active
                                    </span>
                                  </td>
                                  <td className='relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0'>
                                    <a
                                      href='#'
                                      className='text-indigo-600 hover:text-indigo-900'
                                    ></a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </>
                        ) : (
                          <>
                            <tbody>
                              <tr>
                                <td colSpan={3}>No templates found</td>
                              </tr>
                            </tbody>
                          </>
                        )}
                      </>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Pagination  */}

        {templates.length > 0 && loading === false && (
          <>
            <ReactPaginate
              className='mt-5 w-100 mb-7'
              previousClassName='g-white border-gray-300 text-gray-500 hover:bg-blue-200 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
              activeClassName='bg-blue border-red-300 text-red-500 hover:bg-blue-200 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
              pageClassName='g-white border-gray-300 text-gray-500 hover:bg-blue-200 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
              nextClassName='g-white border-gray-300 text-gray-500 hover:bg-blue-200 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
              breakClassName='g-white border-gray-300 text-gray-500 hover:bg-blue-200 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
              disabledClassName='g-white border-gray-300 text-gray-500 hover:bg-blue-200 relative inline-flex items-center px-4 py-2 border text-sm font-medium disabled:opacity-50'
              breakLabel='...'
              nextLabel='next >'
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={Math.ceil(total / per_page)}
              previousLabel='< previous'
              renderOnZeroPageCount={null}
            />
          </>
        )}
      </div>

      <StandardVideoModal
        standardVideoModal={standardVideoModal}
        setStandardVideoModal={setStandardVideoModal}
      />
    </Theme>
  );
};

export default VideosPage;
