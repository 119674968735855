import { computed, signal } from '@preact/signals-react'
import { formatDate } from 'util/format-date'

export const didPublish = signal(false)

// fields
export const title = signal('')
export const category = signal('')
export const subCategory = signal('')
export const description = signal('')
export const practice = signal('')
export const thumbnail = signal<number>(null)
export const video = signal<number>(null)
export const isDate = signal(false)
export const startDate = signal('')
export const endDate = signal('')

// errors
const checkRequired = (value: string | number, field: string) =>
	didPublish.value && !value ? `${field} is required` : ''
const dateRequired = (date: string, field: string) =>
	didPublish.value && isDate.value && !date ? `${field} is required` : ''

export const titleMessage = computed(() => checkRequired(title.value, 'Title'))
export const categoryMessage = computed(() => checkRequired(category.value, 'Category'))
export const subCategoryMessage = computed(() => checkRequired(subCategory.value, 'Sub-category'))
export const descriptionMessage = computed(() => checkRequired(description.value, 'Description'))
export const practiceMessage = computed(() => checkRequired(practice.value, 'Practices'))
export const thumbnailMessage = computed(() => checkRequired(thumbnail.value, 'Thumbnail'))
export const videoMessage = computed(() => checkRequired(video.value, 'Video'))
export const startDateMessage = computed(() => dateRequired(startDate.value, 'Start Date'))
export const endDateMessage = computed(() => dateRequired(endDate.value, 'End Date'))

export const isValid = computed(() => {
	const valid =
		!!title.value &&
		!!category.value &&
		!!subCategory.value &&
		!!description.value &&
		!!practice.value &&
		!!thumbnail.value &&
		!!video.value
	if (isDate.value) {
		return valid && !!startDate.value && !!endDate.value
	} else {
		return valid
	}
})

export const getVariables = (published: boolean) => {
	const variables: Record<string, any> = {
		is_published: published,
		video_type_id: 1,
		add_logo: false,
		add_practice_name: false,
		date_specific: isDate.value,
	}
	if (title.value) variables.video_name = title.value
	if (category.value) variables.primary_category = category.value.toString()
	if (subCategory.value)
		variables.secondary_category = subCategory.value.split(', ').map((id) => parseInt(id))
	if (description.value) variables.description = description.value
	if (practice.value) variables.practice_ids = practice.value.split(', ').map((id) => parseInt(id))
	if (thumbnail.value) variables.video_thumbnail = thumbnail.value
	if (video.value) variables.video = video.value
	if (isDate.value && startDate.value) variables.start_date = formatDate(startDate.value)
	if (isDate.value && endDate.value) variables.end_date = formatDate(endDate.value)
	return variables
}

export const resetFields = () => {
	didPublish.value = false
	title.value = ''
	category.value = ''
	subCategory.value = ''
	description.value = ''
	practice.value = ''
	thumbnail.value = null
	video.value = null
	isDate.value = false
	startDate.value = ''
	endDate.value = ''
}
