import { cn } from 'util/cn';

type PropsType = {
  className?: string;
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  size?: 'md' | 'lg';
  children?: string;
};

const Title = ({ className, tag = 'h4', size = 'md', children }: PropsType) => {
  const Tag = tag;
  const sizeClass = {
    md: 'text-base font-medium leading-[24px]',
    lg: 'text-[20px] font-bold',
  };

  return (
    <Tag
      className={cn(
        'font-karla text-tailgrids-black',
        sizeClass[size],
        className
      )}
    >
      {children}
    </Tag>
  );
};

export default Title;
