import { Card, Divider, Title } from 'components';
import { Dropzone } from 'lib/react-dropzone';
import { useVideoActions } from './stores';

const VideoFiles = () => {
  const { setThumbnail, setSampleVideo, setVideo } = useVideoActions();

  return (
    <Card>
      <Title size='lg' className='py-5 px-7'>
        Video Files
      </Title>
      <Divider />
      <div className='py-5 px-7 space-y-[26px]'>
        <Dropzone
          label='Upload Thumbnail'
          accept='image'
          onChange={(value) => setThumbnail(value)}
        />
        <Dropzone
          label='Upload Video Sample'
          accept='video'
          onChange={(value) => setSampleVideo(value)}
        />
        <Dropzone
          label='Upload Video Source Files (.zip)'
          accept='zip'
          onChange={(value) => setVideo(value)}
        />
      </div>
    </Card>
  );
};

export default VideoFiles;
