'use client'

import { Matcher } from 'react-day-picker'
import { format } from 'date-fns'
import { Popover, PopoverContent, PopoverTrigger } from './popover'
import { Button } from './button'
import { cn } from 'util/cn'
import { Calendar } from './calendar'
import { CalendarOutline } from 'icons'

type PropsType = {
	className?: string
	label?: string
	value?: string
	onChange?: (date: string) => void
	disabled?: Matcher | Matcher[]
	required?: boolean
	errorMessage?: string
}

const DatePicker = ({
	className,
	label,
	value,
	onChange,
	disabled,
	required = false,
	errorMessage = '',
}: PropsType) => {
	const date = value ? new Date(value) : undefined

	return (
		<div className={cn('space-y-3', className)}>
			{label && (
				<label className='text-tailgrids-black font-karla font-bold block leading-[19px]'>
					{required ? <span className='text-accent-700 inline-block mr-0.5'>*</span> : ''}
					{label}
				</label>
			)}
			<div className='space-y-1.5'>
				<Popover>
					<PopoverTrigger asChild>
						<Button
							variant={'outline'}
							className={cn(
								'w-[188px] h-[50px] text-left justify-between items-center py-0 px-[22px] border-2 rounded-lg font-karla text-base font-bold',
								errorMessage ? 'border-error-900' : 'border-black'
							)}
						>
							{date ? (
								<span className='text-tailgrids-black'>{format(date, 'dd/MM/yyyy')}</span>
							) : (
								<span className='text-tailgrids-gray'>DD/MM/YYYY</span>
							)}
							<CalendarOutline />
						</Button>
					</PopoverTrigger>
					<PopoverContent className='w-[502px] bg-white p-4 mb-1'>
						<Calendar
							mode='single'
							selected={date}
							onSelect={(d) => {
								const newDate = new Date(d)
								onChange(format(newDate, 'MM/dd/yyyy'))
							}}
							initialFocus
							disabled={disabled}
						/>
					</PopoverContent>
				</Popover>
				{errorMessage && (
					<p className='text-accent-700 text-sm font-bold font-karla text-end'>{errorMessage}</p>
				)}
			</div>
		</div>
	)
}
export default DatePicker
