import { cn } from 'util/cn';

type PropsType = {
  className?: string;
  onClick?: () => void;
  label?: 'option' | 'input';
};

const AddButton = ({ className, onClick, label = 'option' }: PropsType) => {
  return (
    <button
      className={cn(
        'flex items-center justify-center w-full h-[54px] bg-dashed rounded-lg hover:bg-sky-gray font-karla font-bold leading-normal text-text-300',
        className
      )}
      onClick={onClick}
    >
      + Add another {label}
    </button>
  );
};

export default AddButton;
