import axios from 'axios';
import  { ActionType }   from 'redux/actionTypes/index';
import  { Dispatch } from 'redux';
import  cookie from "js-cookie";
import  config from "config/config"


export function login(params:any) {
    return async function (dispatch:Dispatch) {
      try {
        const data = {
          email: params.email,
          password: params.password,
        };
  
        await axios
          .post(`${config.defaults.api_url}/users/login`, data, {
            method: "POST",
          })
          .then((user:any) => {
            console.log("==== USER LOGIN====")
            console.log(user.data)

            if(user.data.error === false) {

              const expiry_time = new Date(new Date().getTime() + 60 * 60 * 1000);

              cookie.set("token", user.data.results.accessToken,{ expires: expiry_time});

              dispatch({
                type: ActionType.USER_LOGIN_SUCCESS,
                payload: user,
              });
            } else {

              dispatch({
                type: ActionType.USER_LOGIN_FAIL,
                payload: {
                  message:
                    "The Email Address or Password entered is invalid. Please try again.",
                },
              });
            }

          });
      } catch (error) {
        console.log(error)

        dispatch({
          type: ActionType.USER_LOGIN_FAIL,
          payload: {
            message:
              "The Email Address or Password entered is invalid. Please try again.",
          },
        });
      }
    };
  }
