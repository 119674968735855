import { cn } from 'util/cn';

type PropsType = {
  className?: string;
};

const ProgressSpinner = ({ className }: PropsType) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={cn('w-[16px] h-[16px] text-white animate-spin', className)}
    >
      <path
        d='M14.2394 5.4C14.8718 5.13645 15.1787 4.4033 14.8206 3.81915C14.2938 2.95971 13.6072 2.20431 12.7943 1.59574C11.6489 0.738296 10.2946 0.203716 8.87232 0.0477044C7.45008 -0.108307 6.01203 0.119961 4.70801 0.708721C3.40399 1.29748 2.28173 2.22519 1.45818 3.39517C0.634619 4.56515 0.139904 5.93459 0.0255814 7.36078C-0.0887408 8.78698 0.181514 10.2177 0.808185 11.504C1.43485 12.7902 2.39501 13.8848 3.58859 14.6738C4.43573 15.2338 5.37846 15.6252 6.36523 15.8312C7.03594 15.9712 7.62259 15.435 7.65732 14.7507C7.69206 14.0664 7.15797 13.4971 6.49865 13.3107C5.9537 13.1566 5.43332 12.9189 4.95678 12.6039C4.13339 12.0597 3.47103 11.3045 3.03872 10.4172C2.60641 9.52991 2.41998 8.5429 2.49884 7.55904C2.57771 6.57517 2.91899 5.63047 3.48712 4.82335C4.05525 4.01624 4.82944 3.37626 5.72902 2.97011C6.6286 2.56395 7.62064 2.40648 8.60177 2.5141C9.58291 2.62173 10.5172 2.99051 11.3074 3.58202C11.7647 3.92435 12.164 4.33404 12.4928 4.79506C12.8907 5.35285 13.6069 5.66355 14.2394 5.4Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default ProgressSpinner;
