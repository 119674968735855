/* eslint-disable @typescript-eslint/no-unused-vars */
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { createAnncouncement } from "services/announcements";
import { FileUploader } from "react-drag-drop-files";
import { ToastContainer, toast } from "react-toastify";


const NewAnnouncementModal = (props: any) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [image, setImage] = useState(null);
    const [imageName, setImageName] = useState(null);
    const [imageType, setImageType] = useState(null);
    const [previewUrl, setPreviewUrl] = useState("");

    const fileTypes = ["JPG", "PNG", "JPEG"];

    const handleChange = (file) => {
        let img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = () => {
            setPreviewUrl(URL.createObjectURL(file));

            setImageName(file.name);
            setImageType(file.type);

            const reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = () => {
                setImage(reader.result);
            };
        };
    };

    const Initial_values = {
        title: "",
        descriiption: "",
    };

    const validation = Yup.object({
        title: Yup.string().required("Title is required"),
        description: Yup.string().required("Description name is required "),
    });

    const create = async (values: any) => {
        console.log(values);

        setIsSubmitting(true);

        const params: any = {};

        params.title = values.title;

        params.description = values.description;

        if (image) {
            params.image = image;
            params.imageName = imageName;
            params.imageType = imageType;
        }

        const newAnnouncement = await createAnncouncement(params);

        console.log(newAnnouncement);

        const error = newAnnouncement.data.error;

        console.log("error :", error);

        if (error) {
            props.setModal(false);

            setIsSubmitting(false);

            toast.error("Error: unable to create announcement");

            return;


        } else {
            setIsSubmitting(false);

            props.setModal(false);

            props.fetchAnnouncements();

            toast.success("Announcement created successfully.")

            return;
        }
    };

    return (
        <>
            <Transition.Root show={props.modal} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={() => props.setModal(false)}
                >
                    <div className="fixed inset-0" />

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                        <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                            <div className="h-0 flex-1 overflow-y-auto">
                                                <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                                                    <div className="flex items-center justify-between">
                                                        <Dialog.Title className="text-lg font-medium text-white">
                                                            New Announcement
                                                        </Dialog.Title>
                                                        <div className="ml-3 flex h-7 items-center">
                                                            <button
                                                                type="button"
                                                                className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                                onClick={() => props.setModal(false)}
                                                            >
                                                                <span className="sr-only">Close panel</span>
                                                                <XIcon className="h-6 w-6" aria-hidden="true" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-1 flex-col justify-between">
                                                    <div className="divide-y divide-gray-200 px-4 sm:px-6">
                                                        <div className="space-y-6 pt-6 pb-5">
                                                            <Formik
                                                                enableReinitialize
                                                                initialValues={Initial_values}
                                                                validationSchema={validation}
                                                                onSubmit={create}
                                                            >
                                                                <Form>
                                                                    <div className="mt-3 sm:col-span-3">
                                                                        <label className="block text-sm font-medium text-blue-gray-900">
                                                                            Title
                                                                        </label>
                                                                        <Field
                                                                            type="text"
                                                                            className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                                                            id="title"
                                                                            name="title"
                                                                            placeholder="Title"
                                                                        />

                                                                        <ErrorMessage
                                                                            component="a"
                                                                            className="error-msg text-red"
                                                                            name="Title"
                                                                        />
                                                                    </div>

                                                                    <div className="mt-3 sm:col-span-3">
                                                                        <label className="block text-sm font-medium text-blue-gray-900">
                                                                            Description
                                                                        </label>
                                                                        <Field
                                                                            component="textarea"
                                                                            className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                                                            id="description"
                                                                            name="description"
                                                                            placeholder="Description"
                                                                        />

                                                                        <ErrorMessage
                                                                            component="a"
                                                                            className="error-msg text-red"
                                                                            name="description"
                                                                        />
                                                                    </div>

                                                                    <div className="mt-3 sm:col-span-3">
                                                                        <label className="block text-sm font-medium text-blue-gray-900 mb-5">
                                                                            Image
                                                                        </label>

                                                                        <FileUploader
                                                                            name="file"
                                                                            handleChange={handleChange}
                                                                            types={fileTypes}
                                                                        >
                                                                            <div className="w-[19%] inline-block text-right cursor-pointer">
                                                                                <div className="w-[165px] h-[92px] inline-flex justify-center flex-col items-center border-dashed border-2 border-[#000] rounded-[12px]">
                                                                                    <input
                                                                                        className="video w-[20px] leading-[0px] text-[30px] cursor-pointer before:content-[url('https://cartronicsautorepair.com/staging/wp-content/uploads/2023/07/upload.svg')]"
                                                                                        type="file"
                                                                                        disabled
                                                                                    />
                                                                                    <p className="text-[12px] font-[400] pt-[4px] text-[#637381]">
                                                                                        {imageName ? imageName : 'Upload image here'}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </FileUploader>
                                                                    </div>

                                                                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                                                        
                                                                        <button
                                                                            type="submit"
                                                                            disabled={isSubmitting}
                                                                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                        >
                                                                            {isSubmitting ? "Creating.." : "Create"}
                                                                        </button>
                                                                    </div>
                                                                </Form>
                                                            </Formik>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};

export default NewAnnouncementModal;
