import { useEffect, useState } from "react";

import { MenuAlt2Icon } from "@heroicons/react/outline";
import Sidebar from "./sidebar";
import Header from "./header";
import cookie from "js-cookie";
import { useNavigate } from "react-router-dom";
import { currentUser } from "services/user";
import { ToastContainer } from "react-toastify";


export default function Theme(props: any) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const [user, setUser]: any = useState(null);
  const [interval, setInterval] = useState(0);

  const token = cookie.get("token");

  useEffect(() => {
    if (!token) {
      navigate("/");

      return;
    }
    const init = async () => {
      console.log("=== Init theme ===");

      if (interval === 0) {
        setInterval(1);

        const user = await currentUser();

        if (user.data) {
          setUser(user.data);
        }
      }
    };
    init();
  }, [navigate, token, interval]);  

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div>
        <div className="md:pl-64 flex flex-col flex-1">
          <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <Header user={user} />
          </div>

          {token ? props.children : "....."}
        </div>
      </div>
      <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          theme="dark"
        />
    </>
  );
}
