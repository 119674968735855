'use client';

import * as React from 'react';
import { DayPicker } from 'react-day-picker';
import { ChevronLeft } from 'icons';
import { cn } from 'util/cn';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn(className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4 w-full',
        caption:
          'flex justify-between items-center pb-8 border-b border-[#F5F5F5]',
        caption_label: 'text-[22px] font-karla font-bold leading-[28.16px]',
        nav: 'flex',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell:
          'rounded-full w-full h-12 text-black font-karla text-lg font-bold p-2 flex items-center justify-center',
        row: 'flex w-full',
        cell: 'h-12 w-full text-center p-0 relative [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-black [&:has([aria-selected])]:text-white [&:has([aria-selected])]:hover:bg-black focus-within:relative focus-within:z-20 font-karla text-lg text-[#3F3F3F] font-bold flex items-center justify-center rounded-full hover:bg-[#F2F5F9]',
        day: 'w-full h-full',
        day_range_end: 'day-range-end',
        day_selected: 'text-white',
        day_outside: 'day-outside text-[#979FA1] aria-selected:text-white',
        day_disabled: 'opacity-50',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => <ChevronLeft className='text-[#636363]' />,
        IconRight: ({ ...props }) => (
          <ChevronLeft className='text-[#636363] rotate-180' />
        ),
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
