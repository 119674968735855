/* eslint-disable */

import Theme from "components/theme";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";


import {
    ArrowLeftIcon as ArrowLongLeftIcon,
    CheckIcon,
    HandIcon as HandThumbUpIcon,
    UserIcon,
    ViewListIcon,
} from "@heroicons/react/solid";

import DetaultProfile from "assets/img/team-member-profile.png";

import DefaultThumbnail from "assets/img/default-thumbnail.jpg";

import moment from "moment-timezone";
import { getNotifications, impersonate } from "services/practices";
import Swal from "sweetalert2";
import { SaveAsIcon, UploadIcon } from "@heroicons/react/outline";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ActionType } from "redux/actionTypes/index";
import LoadingOverlay from "react-loading-overlay";
import RecentActivityModal from "components/modals/recent_activity";
import { getVideos } from "services/videos";
import config from "config/config";


function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const eventTypes = {
    upload: { icon: UploadIcon, bgColorClass: "bg-blue-500" },
    completed: { icon: CheckIcon, bgColorClass: "bg-green-500" },
};

interface RootState {
    practice: any;
}

const EditVideo = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    const state = useSelector((state: RootState) => state);

    const dispatch = useDispatch();

    /***** UPDATE  *******/

    console.log(id);

    return (
        <>

            <Theme>
                <main className="py-10 bg-gray-200">
                    <div className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">

                            <div className="flex items-start space-x-5">
                                <div className="flex-shrink-0">
                                    <div className="relative">
                                        <img
                                            className="h-16 w-16 rounded-full"
                                            src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                                            alt=""
                                        />
                                        <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
                                    </div>
                                </div>
   
                                <div className="pt-1.5">
                                    <h1 className="text-2xl font-bold text-gray-900">Ricardo Cooper</h1>
                                    <p className="text-sm font-medium text-gray-500">
                                        Applied for{' '}
                                        <a href="#" className="text-gray-900">
                                            Front End Developer
                                        </a>{' '}
                                        on <time dateTime="2020-08-25">August 25, 2020</time>
                                    </p>
                                </div>
                            </div>
                            <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                    Disqualify
                                </button>
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Advance to offer
                                </button>
                            </div>
                        </div>
                    
                </main>
            </Theme>

        </>
    );
};

export default EditVideo;
