import { effect, signal } from '@preact/signals-react'
import { Card, Divider, Input, Textarea, Title } from 'components'
import {
	code,
	codeMessage,
	country,
	countryMessage,
	description,
	descriptionMessage,
	endDate,
	endDateMessage,
	isCountry,
	isDate,
	primary,
	primaryMessage,
	sample,
	sampleMessage,
	secondary,
	secondaryMessage,
	startDate,
	startDateMessage,
	thumbnail,
	thumbnailMessage,
	title,
	titleMessage,
	video,
	videoMessage,
} from 'features/instant-play'
import { Dropzone } from 'lib/react-dropzone'
import { Option, Select } from 'lib/react-select'
import { Switch } from 'lib/react-switch'
import { DatePicker } from 'lib/shadcn'
import { getCountries, getVideoCategories } from 'redux/actionCreators/videos'
import { getNextDay } from 'util/get-next-day'

const categories = signal<Option[]>([])
const countries = signal<Option[]>([])

effect(async () => {
	const _categories = await getVideoCategories()
	categories.value = _categories.data.results.map(({ category_id, category_name }) => ({
		value: category_id,
		label: category_name,
	}))

	const _countries = await getCountries()
	countries.value = _countries.data.results.map(({ code, name }) => ({
		value: code,
		label: name,
	}))
})

const InstantForm = () => {
	return (
		<Card>
			<Title size='lg' className='py-5 px-7'>
				Instant-play Inputs
			</Title>
			<Divider />
			<div className='py-5 px-7 space-y-[26px]'>
				<Input
					label='Title'
					value={title.value}
					onChange={(e) => (title.value = e.target.value)}
					errorMessage={titleMessage.value}
					required
				/>
				<Select
					label='Primary Category'
					onChange={(value) => (primary.value = value)}
					errorMessage={primaryMessage.value}
					options={categories.value}
					required
					isSearchable
				/>
				<Select
					label='Secondary Category'
					onChange={(value) => (secondary.value = value)}
					errorMessage={secondaryMessage.value}
					options={categories.value}
					required
					isMulti
					isSearchable
				/>
				<Textarea
					label='Description'
					value={description.value}
					onChange={(e) => (description.value = e.target.value)}
					errorMessage={descriptionMessage.value}
					required
				/>
				<Input
					label='Video Code'
					value={code.value}
					onChange={(e) => (code.value = e.target.value)}
					errorMessage={codeMessage.value}
					required
				/>
				<Dropzone
					label='Upload Thumbnail'
					accept='image'
					onChange={(value) => (thumbnail.value = value)}
					errorMessage={thumbnailMessage.value}
					required
				/>
				<Dropzone
					label='Upload Video'
					accept='video'
					onChange={(value) => (video.value = value)}
					errorMessage={videoMessage.value}
					required
				/>
				<Dropzone
					label='Upload Video Sample'
					accept='video'
					onChange={(value) => (sample.value = value)}
					errorMessage={sampleMessage.value}
					required
				/>
				<div className='flex gap-8'>
					<Switch
						label='Country Specific'
						checked={isCountry.value}
						onChange={() => (isCountry.value = !isCountry.value)}
						className='flex-shrink-0'
					/>
					{isCountry.value && (
						<Select
							label='Select Country'
							className='w-full'
							onChange={(value) => (country.value = value)}
							value={country.value}
							options={countries.value}
							isMulti
							required
							errorMessage={countryMessage.value}
						/>
					)}
				</div>
				<div className='flex gap-[57px]'>
					<Switch
						label='Date Specific'
						checked={isDate.value}
						onChange={() => (isDate.value = !isDate.value)}
					/>
					{isDate.value && (
						<div className='flex gap-4 items-start'>
							<DatePicker
								label='Start Date'
								value={startDate.value}
								onChange={(date) => (startDate.value = date)}
								errorMessage={startDateMessage.value}
								required
							/>
							<p className='font-karla font-bold text-tailgrids-black mt-[46px]'>to</p>
							<DatePicker
								label='End Date'
								value={endDate.value}
								onChange={(date) => (endDate.value = date)}
								disabled={{ before: getNextDay(new Date(startDate.value)) }}
								errorMessage={endDateMessage.value}
								required
							/>
						</div>
					)}
				</div>
			</div>
		</Card>
	)
}

export default InstantForm
