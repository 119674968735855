import { XIcon } from '@heroicons/react/outline';
import ReactSwitch from 'react-switch';
import { cn } from 'util/cn';
import { generateId } from 'util/generated-id';

type PropsType = {
  label?: string;
  className?: string;
  id?: string;
  checked: boolean;
  onChange: (checked?: boolean) => void;
};

const Switch = ({
  label,
  className,
  id = generateId(),
  checked,
  onChange,
}: PropsType) => {
  return (
    <label htmlFor={id} className={cn('space-y-3 block', className)}>
      {label && (
        <p className='text-tailgrids-black font-karla font-bold block leading-[19px]'>
          {label}
        </p>
      )}
      <ReactSwitch
        checked={checked}
        onChange={(checked) => onChange(checked)}
        handleDiameter={28}
        offColor='#E5E7EB'
        onColor='#E5E7EB'
        offHandleColor='#fff'
        onHandleColor='#000'
        height={32}
        width={55}
        borderRadius={100}
        activeBoxShadow='0px 0px 0px 0px'
        uncheckedIcon={<></>}
        checkedIcon={<></>}
        uncheckedHandleIcon={
          <div className='flex items-center justify-center h-full w-full'>
            <XIcon className='w-4 h-4 text-tailgrids-gray' />
          </div>
        }
        checkedHandleIcon={
          <div className='flex items-center justify-center w-full h-full'>
            <svg
              width='11'
              height='8'
              viewBox='0 0 11 8'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z'
                fill='white'
                stroke='white'
                strokeWidth='0.4'
              />
            </svg>
          </div>
        }
        id={id}
      />
    </label>
  );
};

export default Switch;
