import AnnouncementsPage from 'pages/announcements';
import IRoute from '../interfaces/route';
import LoginPage from '../pages/login';
import DashboardPage from 'pages/dashboard';
import PracticesPage from 'pages/practices';
import EditPractice from 'pages/practices/edit';
import VideosPage from 'pages/videos';
import CustomizationVideoDetail from 'pages/videos/customization/detail';
import VideosCustomizationRequests from 'pages/videos/customization/requests';
import EditVideo from 'pages/videos/edit';
import NewVideo from 'pages/videos/new';
import { NewIndividual, NewInstantPlay } from 'pages';

const routes: IRoute[] = [
  {
    path: '/',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardPage,
  },
  {
    path: '/practices',
    name: 'Practices',
    component: PracticesPage,
  },

  {
    path: '/templates',
    name: 'Templates',
    component: VideosPage,
  },
  {
    path: '/videos/edit/:id',
    name: 'Videos',
    component: EditVideo,
  },

  {
    path: '/videos/new-individual',
    name: 'New Individual Inputs',
    component: NewIndividual,
  },
  {
    path: '/videos/new-instant-play',
    name: 'New Instant Play Inputs',
    component: NewInstantPlay,
  },
  {
    path: '/videos/new',
    name: 'Videos',
    component: NewVideo,
  },

  {
    path: '/videos/customization/requests',
    name: 'Videos Customization Requests',
    component: VideosCustomizationRequests,
  },

  {
    path: '/videos/customization/requests/:id',
    name: 'Videos Customization Request Detail',
    component: CustomizationVideoDetail,
  },

  {
    path: '/practices/edit/:id',
    name: 'Practice Edit',
    component: EditPractice,
  },

  {
    path: '/announcements',
    name: 'Announcements',
    component: AnnouncementsPage,
  },
];

export default routes;
