import { signal } from '@preact/signals-react'
import Theme from 'components/theme'
import { Body, Button, Headline } from 'components'
import { IndividualForm, didPublish, getVariables, resetFields, isValid } from 'features/individual'
import { createTemplate } from 'redux/actionCreators/videos'
import { useNavigate } from 'react-router-dom'

const publishing = signal(false)
const saving = signal(false)

const NewIndividual = () => {
	const navigate = useNavigate()

	const save = async (published: boolean) => {
		let valid = true
		published ? (publishing.value = true) : (saving.value = true)
		if (published) {
			didPublish.value = true
			valid = isValid.value
		}
		const variables = getVariables(published)
		if (valid) {
			const result = await createTemplate(variables)
			if (result) {
				published ? (publishing.value = false) : (saving.value = false)
				resetFields()
				navigate('/templates')
			}
		} else {
			published ? (publishing.value = false) : (saving.value = false)
		}
	}

	return (
		<Theme>
			<div className='px-[30px] py-[34px]'>
				<Headline tag='h2' size='lg'>
					Add New Video to Library
				</Headline>
				<Body className='text-tailgrids-gray mt-4'>Add video template with custom inputs</Body>
			</div>
			<div className='max-w-[737px] w-full mx-auto mt-[60px] mb-[34px]'>
				<IndividualForm />
				<div className='mt-[24px]'>
					<Button className='w-full' onClick={() => save(true)} isLoading={publishing.value}>
						Save and Publish to Library
					</Button>
					<div className='mt-[16px] flex gap-[16px]'>
						<Button className='w-full' variant='secondary' isDisabled={!isValid.value}>
							Preview Pop up
						</Button>
						<Button
							className='w-full'
							variant='secondary'
							onClick={() => save(false)}
							isLoading={saving.value}
						>
							Save and Exit
						</Button>
					</div>
				</div>
			</div>
		</Theme>
	)
}

export default NewIndividual
