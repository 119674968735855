import { ActionType } from '../actionTypes/index';

const initialState = {}

const authReducer = (state=initialState, action: any):any => {
    switch(action.type) {
        case ActionType.USER_LOGIN_SUCCESS:
            return {
                ...state,
                type:action.type,
                payload:action.payload
            } 
        case ActionType.USER_LOGIN_FAIL:
            return {
                ...state,
                type:action.type,
                payload:action.payload
            }
        default: 
            return state;
    }
}

export default authReducer;