import  axios from 'util/axios';
import  config from "config/config"


  export async function getPractices(params) {
    return axios.get(`${config.defaults.api_url}/practices`, { params })
  }

  
  export async function impersonate(params) {
    return axios.post(`${config.defaults.api_url}/users/impersonate`,params)
  }


  export async function getNotifications(params) {
    return axios.get(`${config.defaults.api_url}/practices/notifications`, { params })
  }


  export async function getPracticesList() {
    return axios.get(`${config.defaults.api_url}/practices/list`)
  }
