const isLocalhost =
  window.location.hostname === 'localhost' ||
  window.location.hostname === '127.0.0.1'
    ? true
    : false;
const isTesting =
  window.location.hostname === 'admin-test.channeld.com' ? true : false;

const config = {
  defaults: {
    admin_url: isLocalhost
      ? 'http://localhost:3001'
      : 'https://admin.channeld.com',
    namespace: 'Application',
    api_url: isLocalhost
      ? 'https://api-staging.channeld.com:3000'
      : isTesting
      ? 'https://api-staging.channeld.com:3000'
      : 'https://api-staging.channeld.com',
    practice_dashboard_url: isLocalhost
      ? 'http://localhost:3000'
      : 'https://v2-staging.channeld.com',
  },
};

export default config;
