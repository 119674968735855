import axios from "axios";
import { ActionType } from "redux/actionTypes/index";
import { Dispatch } from "redux";
import config from "config/config";

export function getPracticeDetail(id:any) {
  return async function (dispatch: Dispatch) {
    try {

      await axios
        .get(`${config.defaults.api_url}/practices/detail?practice_id=${id}`)
        .then((res: any) => {
          console.log(res);

          dispatch({
            type: ActionType.GET_PRACTICE_DETAIL,
            payload: res,
          });
        });
    } catch (error) {
      console.log(error);

      dispatch({
        type: ActionType.GET_PRACTICE_DETAIL_ERROR,
        payload: {
          message: "Unable to get practice detail",
        },
      });
    }
  };
}

export function getPracticeAddress(id, type) {
  return async function (dispatch: Dispatch) {
    try {
      await axios
        .get(`${config.defaults.api_url}/practices/address/${id}`)
        .then((res: any) => {
          console.log(res);

          if (type === "postal") {
            dispatch({
              type: ActionType.GET_PRACTICE_POSTAL_ADDRESS,
              payload: res,
            });
          } else if (type === "physical") {
            dispatch({
              type: ActionType.GET_PRACTICE_PHYSICAL_ADDRESS,
              payload: res,
            });
          }
        });
    } catch (error) {
      console.log(error);

      if (type === "postal") {
        dispatch({
          type: ActionType.GET_PRACTICE_POSTAL_ADDRESS_ERROR,
          payload: {
            message: "Unable to get practice postal address",
          },
        });
      } else if (type === "physical") {
        dispatch({
          type: ActionType.GET_PRACTICE_DETAIL_ERROR,
          payload: {
            message: "Unable to get practice physical address",
          },
        });
      }
    }
  };
}

export async function updateAddress(data: any, id: any) {
  return axios.put(`${config.defaults.api_url}/practices/address/${id}`, data);
}

export async function updatePracticeBasicInfo(data: any, id: any) {
  return axios.put(
    `${config.defaults.api_url}/practices/basic/info?practice_id=${id}`,
    data
  );
}

export async function updatePracticeContactInfo(data: any, id: any) {
  return axios.put(
    `${config.defaults.api_url}/practices/contact/info?practice_id=${id}`,
    data
  );
}

export async function createPractice(data:any) {
    return axios.post(
      `${config.defaults.api_url}/practices/create`,
      data
    );
  }

export async function createPracticeTeamMember(data: any, id: any, file: any) {
  const body = new FormData();

  body.append("name", data.name);
  body.append("informal_name", data.informal_name);
  body.append("position", data.position);
  body.append("name", data.name);
  body.append("image", file);

  return axios({
    method: "post",
    url: `${config.defaults.api_url}/practices/team-members/new/${id}`,
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function getTeamMembers(id) {
  return async function (dispatch: Dispatch) {
    try {
      await axios
        .get(`${config.defaults.api_url}/practices/team-members?practice_id=${id}`)
        .then((res: any) => {
          dispatch({
            type: ActionType.GET_TEAM_MEMBERS,
            payload: res,
          });
        });
    } catch (error) {
      console.log(error);

      dispatch({
        type: ActionType.GET_TEAM_MEMBERS,
        payload: {
          message: "Unable to get team members",
        },
      });
    }
  };
}

export function getPracticeVideos(id) {
  return async function (dispatch: Dispatch) {
    try {
      await axios
        .get(`${config.defaults.api_url}/practices/videos?practice_id=${id}`)
        .then((res: any) => {
          dispatch({
            type: ActionType.GET_PRACTICE_VIDEOS,
            payload: res,
          });
        });
    } catch (error) {
      console.log(error);

      dispatch({
        type: ActionType.GET_PRACTICE_VIDEOS_ERROR,
        payload: {
          message: "Unable to get practice videos",
        },
      });
    }
  };
}

