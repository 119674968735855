import {
  NewQuestionIcon,
  OptionSelectIcon,
  ImageFileIcon,
  TeamMemberIcon,
} from './icons';
import NewImage from './new-image';
import NewQuestions from './new-questions';
import NewTeam from './new-team';
import OptionSelect from './option-select';

export const labels = {
  question: 'Add new question',
  select: 'Add new option select',
  image: 'Request a new image file',
  member: 'Request a new team member',
  request: 'Add new request (sent to admin for approval first)',
};

export const icons = {
  question: NewQuestionIcon,
  select: OptionSelectIcon,
  image: ImageFileIcon,
  member: TeamMemberIcon,
};

export const components = {
  question: NewQuestions,
  select: OptionSelect,
  image: NewImage,
  member: NewTeam,
};
