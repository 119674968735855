import { create } from 'zustand';
import { CustomInput, CustomType } from 'features/video-to-library';
import { generateId } from 'util/generated-id';

type ActionType = {
  setName: (value: string) => void;
  setDescription: (value: string) => void;
  setPrimaryCategory: (value: string) => void;
  setSecondaryCategory: (value: string) => void;
  setIsCountrySpecific: (value: boolean) => void;
  setCountry: (value: string) => void;
  setIsDateSpecific: (value: boolean) => void;
  setStartDate: (value: string) => void;
  setEndDate: (value: string) => void;
  setIsAddLogo: (value: boolean) => void;
  setIsAddPracticeName: (value: boolean) => void;
  setThumbnail: (value: string) => void;
  setSampleVideo: (value: string) => void;
  setVideo: (value: string) => void;
  setCustom: (value: CustomType | '') => void;
  setExpanded: (value: boolean | string) => void;
  addCustomItem: (type: CustomType) => void;
  removeCustomItem: (id: string) => void;
  setCustomField: (
    id: string | boolean,
    value: Record<string, any> | Record<string, any>[]
  ) => void;
  setInput: (id: string | boolean, value: CustomInput) => void;
  setInputs: (inputs: CustomInput[]) => void;
  reset: () => void;
};

type VideoStore = {
  name: string;
  description: string;
  primaryCategory: string;
  secondaryCategory: string;
  isCountrySpecific: boolean;
  country: string;
  isDateSpecific: boolean;
  startDate: string;
  endDate: string;
  isAddLogo: boolean;
  isAddPracticeName: boolean;
  thumbnail: string;
  sampleVideo: string;
  video: string;
  custom: CustomType | '';
  expanded: boolean | string;
  customItems: CustomInput[];
  actions: ActionType;
};

const useVideoStore = create<VideoStore>((set) => ({
  // step 1
  name: '',
  description: '',
  primaryCategory: '',
  secondaryCategory: '',
  isCountrySpecific: false,
  country: '',
  isDateSpecific: false,
  startDate: '',
  endDate: '',

  // step 2
  isAddLogo: false,
  isAddPracticeName: false,

  // step 3
  thumbnail: '',
  sampleVideo: '',
  video: '',

  // custom inputs
  custom: '',
  expanded: false,
  customItems: [],

  actions: {
    // step 1
    setName: (value) => set({ name: value }),
    setDescription: (value) => set({ description: value }),
    setPrimaryCategory: (value) => set({ primaryCategory: value }),
    setSecondaryCategory: (value) => set({ secondaryCategory: value }),
    setIsCountrySpecific: (value) => set({ isCountrySpecific: value }),
    setCountry: (value) => set({ country: value }),
    setIsDateSpecific: (value) => set({ isDateSpecific: value }),
    setStartDate: (value) => set({ startDate: value }),
    setEndDate: (value) => set({ endDate: value }),

    // step 2
    setIsAddLogo: (value) => set({ isAddLogo: value }),
    setIsAddPracticeName: (value) => set({ isAddPracticeName: value }),

    // step 3
    setThumbnail: (value) => set({ thumbnail: value }),
    setSampleVideo: (value) => set({ sampleVideo: value }),
    setVideo: (value) => set({ video: value }),

    // custom inputs
    setCustom: (value) => set({ custom: value }),
    setExpanded: (value) => set({ expanded: value }),
    addCustomItem: (type) =>
      set((state) => {
        const inputs: Record<CustomType, CustomInput> = {
          question: {
            id: generateId(),
            type,
            description: '',
            is_required: false,
          },
          select: {
            id: generateId(),
            type,
            description: '',
            options: [''],
            is_multiselect: false,
            min_options: 1,
            max_options: 3,
            is_required: false,
          },
          image: {
            id: generateId(),
            type,
            description: '',
            image_max_width: 1920,
            image_max_height: 1080,
            is_required: false,
          },
          member: {
            id: generateId(),
            type,
            description: '',
            is_photo_required: false,
            is_name_required: false,
            is_position_required: false,
            is_multiselect: false,
            min_members_required: 1,
            max_members_required: 3,
            is_required: false,
          },
        };
        return {
          customItems: [...state.customItems, inputs[type]],
        };
      }),
    removeCustomItem: (id) =>
      set((state) => ({
        customItems: state.customItems.filter((item) => item.id !== id),
      })),
    setCustomField: (id, value) =>
      set((state) => {
        const customInputs: CustomInput[] = state.customItems.map(
          (customInput) => {
            if (customInput.id === id) return { ...customInput, fields: value };
            return customInput;
          }
        );
        return { customItems: customInputs };
      }),
    setInput: (id, input) =>
      set((state) => {
        const customInputs: CustomInput[] = state.customItems.map(
          (customInput) => {
            if (customInput.id === id) return input;
            return customInput;
          }
        );
        return { customItems: customInputs };
      }),
    setInputs: (inputs) => set(() => ({ customItems: inputs })),
    reset: () =>
      set({
        // step 1
        name: '',
        description: '',
        primaryCategory: '',
        secondaryCategory: '',
        isCountrySpecific: false,
        country: '',
        isDateSpecific: false,
        startDate: '',
        endDate: '',

        // step 2
        isAddLogo: false,
        isAddPracticeName: false,

        // step 3
        thumbnail: '',
        sampleVideo: '',
        video: '',

        // custom inputs
        custom: '',
        expanded: false,
        customItems: [],
      }),
  },
}));

export const useVideo = () => useVideoStore((state) => state);

export const useVideoActions = () => useVideoStore((state) => state.actions);
