import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Theme from 'components/theme'
import { Body, Button, Headline, Stepper, useStepper } from 'components'
import {
	Overview,
	TemplateInputs,
	VideoFiles,
	removeId,
	useVideo,
	useVideoActions,
} from 'features/video-to-library'
import { createTemplate } from 'redux/actionCreators/videos'
import { formatDate } from 'util/format-date'

const NewVideo = () => {
	const { steps, currentStep, nextStep, setStep } = useStepper(
		'Overview',
		'Template Inputs',
		'Video Files'
	)
	const {
		custom,
		name,
		description,
		primaryCategory,
		secondaryCategory,
		isCountrySpecific,
		country,
		isDateSpecific,
		startDate,
		endDate,
		isAddLogo,
		isAddPracticeName,
		thumbnail,
		video,
		sampleVideo,
		customItems,
	} = useVideo()
	const { addCustomItem, setCustom, reset } = useVideoActions()
	const [publishing, setPublishing] = useState(false)
	const [saving, setSaving] = useState(false)
	const navigate = useNavigate()

	const forms = {
		1: <Overview />,
		2: <TemplateInputs />,
		3: <VideoFiles />,
	}

	const handleNext = () => {
		if (currentStep === 2 && custom) {
			addCustomItem(custom)
			return setCustom('')
		}
		nextStep()
	}

	const save = async (published: boolean) => {
		published ? setPublishing(true) : setSaving(true)
		const variables: Record<string, any> = {
			is_published: published,
			video_type_id: 3,
			video_description: description,
			category: '',
			secondary_category: secondaryCategory
				? secondaryCategory.split(', ').map((id) => parseInt(id))
				: [],
			needsToBeDeleted_secondary_category: [],
			country_specific: isCountrySpecific,
			date_specific: isDateSpecific,
			add_logo: isAddLogo,
			add_practice_name: isAddPracticeName,
			inputs: removeId(customItems),
		}
		if (name) variables.video_name = name
		if (primaryCategory) variables.primary_category = primaryCategory.toString()
		if (country) variables.country = country
		if (startDate) variables.start_date = formatDate(startDate)
		if (endDate) variables.end_date = formatDate(endDate)
		if (thumbnail) variables.video_thumbnail = thumbnail
		if (sampleVideo) variables.sample_video = sampleVideo
		if (video) variables.video = video

		const result = await createTemplate(variables)
		if (result) {
			published ? setPublishing(false) : setSaving(false)
			reset()
			navigate('/templates')
		}
	}

	return (
		<Theme>
			<div className='px-[30px] py-[34px]'>
				<Headline tag='h2' size='lg'>
					Add New Video to Library
				</Headline>
				<Body className='text-tailgrids-gray mt-4'>Add video template with custom inputs</Body>
			</div>

			<Stepper
				steps={steps}
				currentStep={currentStep}
				className='mt-[60px] mx-auto'
				setStep={setStep}
			/>

			<div className='max-w-[737px] w-full mx-auto mt-[40px] mb-[34px]'>
				{forms[currentStep]}

				<div className='mt-[24px]'>
					<Button
						className='w-full'
						onClick={currentStep === 3 ? () => save(true) : handleNext}
						isLoading={publishing}
					>
						{currentStep === 3 ? 'Save and Publish to Library' : 'Next'}
					</Button>
					<div className='mt-[16px] flex gap-[16px]'>
						<Button
							className='w-full'
							variant='secondary'
							isDisabled={
								!(
									name &&
									description &&
									primaryCategory &&
									secondaryCategory &&
									thumbnail &&
									video &&
									sampleVideo
								)
							}
						>
							Preview Pop up
						</Button>
						<Button
							className='w-full'
							variant='secondary'
							onClick={() => save(false)}
							isLoading={saving}
						>
							Save and Exit
						</Button>
					</div>
				</div>
			</div>
		</Theme>
	)
}

export default NewVideo
