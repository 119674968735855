/* eslint-disable */

import Theme from "components/theme";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import {
  ArrowLeftIcon as ArrowLongLeftIcon,
  CheckIcon,
  HandIcon as HandThumbUpIcon,
  UserIcon,
  ViewListIcon,
  CameraIcon,
} from "@heroicons/react/solid";

import DetaultProfile from "assets/img/team-member-profile.png";
import LoadingOverlay from "react-loading-overlay";

import DefaultThumbnail from "assets/img/default-thumbnail.jpg";

import moment from "moment-timezone";
import { getNotifications, impersonate } from "services/practices";
import Swal from "sweetalert2";
import {
  SaveAsIcon,
  UploadIcon,
  VideoCameraIcon,
  BriefcaseIcon,
  DownloadIcon

} from "@heroicons/react/outline";

import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ActionType } from "redux/actionTypes/index";
import {
  ChangeCustomizationRequestStatus,
  addCommentToCustomizationRequest,
  changeCustomizationRequestEditor,
  getCustomizationRequestComments,
  getVideos,
  uploadCustomizedVideo,
  uploadFile,
} from "services/videos";
import config from "config/config";
import {
  getVideoCustomizationMembers,
  getVideoCustomizationQuestions,
  getVideoCustomizationRequests,
  getVideoDescriptions,
  getVideoQuestions,
} from "redux/actionCreators/videos";
import DropDownMenu from "components/dropdown-menu";
import Avatar from "react-avatar";
import { currentUser } from "services/user";
import { getTeamMembers } from "redux/actionCreators/practice";
import { ToastContainer, toast } from "react-toastify";
import { FileUploader } from "react-drag-drop-files";

interface RootState {
  auth: any;
  practice: any;
  videos: any;
}

const CustomizationVideoDetail = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const state = useSelector((state: RootState) => state);

  console.log("=== APP STATE ===");

  console.log(state);

  const [loading, setLoading] = useState(false);

  const [addingCommentState, setAddingCommentState] = useState(false);

  const [UploadState, setUploadState] = useState(false);

  const [comment, setComment] = useState("");

  const [practice, setPractice]: any = useState([]);

  const dispatch = useDispatch();

  const [currentEditor, setCurrentEditor] = useState(null);

  const [currentStatus, setCurrentStatus] = useState(null);

  const [open, setOpen] = useState(true);

  const [statusColor, setStatusColor] = useState(null);

  const [user, setCurrentUser] = useState(null);

  const [teamMembers, setTeamMembers] = useState([]);

  const [comments, setComments] = useState([]);

  const fileTypes = ["mp4"];

  const [file, setFile] = useState(null);

  const [fileUploading, setFileUploading] = useState(false);

  const fileInput = useRef(null);

  const final_pack_input = useRef(null);


  const [finalFile,setFinalFile]  = useState();

  const [questions, setQuestions] = useState([]);

  const [customizationQuestions, setCustomizationQuestions] = useState([]);

  const [customizationMembers, setCustomizationMembers] = useState([]);

  const [descriptions, setDescriptions] = useState([]);

  const [final_pack_uploading,setFinalPackUploading] = useState(false);

  useEffect(() => {
    const init = async () => {
      const questions = await getVideoQuestions(practice.video_id);

      setQuestions(questions.data.results);

      const descriptions = await getVideoDescriptions(practice.video_id);

      setDescriptions(descriptions.data.results);

      if (id) {
        const cquestions = await getVideoCustomizationQuestions(id);

        const cmembers = await getVideoCustomizationMembers(id);

        setCustomizationQuestions(cquestions.data.results);
        setCustomizationMembers(cmembers.data.results);
      }
    };

    init();
  }, [practice, id]);

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleFinalPackInputClick = () => {
    final_pack_input.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    console.log(file);

    setFile(file);

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = async () => {
      console.log(reader.result);

      setFileUploading(true);

      await uploadCustomizedVideo({
        customization_id: id,
        video: reader.result,
        file_name: file.name,
      });

      setFileUploading(false);

      toast.success(`Video ${file.name} uploaded successfully`);
    };
  };

  const handleFinalPackUpload = (event) => {

    setFinalPackUploading(true);

    const file = event.target.files[0];

    console.log(file);

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = async () => {
      console.log(reader.result);

      await uploadFile({
        customization_id: id,
        video: reader.result,
        file_name: file.name,
      });

      setFinalPackUploading(false);

      toast.success(`Video ${file.name} uploaded successfully`);
    };
  };

  const editors = [
    {
      id: "ec5a9915-0065-4eff-8b9f-b2a96a9226cb",
      name: "Christoffer Dionisio",
    },
    {
      id: "dc32bd65-3da7-468d-8a70-26580141c095",
      name: "Kristoffer Rhey de Jesus",
    },
    {
      id: "2bb96e52-72de-4ba1-a8d4-8699dbc6069c",
      name: "Ryan Castilar",
    },
  ];

  const customization_statuses = [
    {
      id: 1,
      color: "bg-[#9ab3db]",
      name: "Pending",
    },
    {
      id: 2,
      color: "bg-[#38b2ac]",
      name: "Complete",
    },
    {
      id: 3,
      color: "bg-[#f56565]",
      name: "Cancelled",
    },
    {
      id: 4,
      color: "bg-[#4299e1]",
      name: "Edited",
    },
    {
      id: 5,
      color: "bg-[#d11111]",
      name: "Rejected",
    },
  ];

  useEffect(() => {
    const init = async () => {
      const auth = await currentUser();

      setCurrentUser(auth);

      fetchComments(id);
    };
    setLoading(true);
    dispatch(getVideoCustomizationRequests({ customization_id: id }));

    init();
  }, [id]);

  useEffect(() => {
    if (practice && practice.practice_id) {
      dispatch(getTeamMembers(practice?.practice_id));
    }
  }, [practice]);

  const fetchComments = async (id: any) => {
    const cmnts = await getCustomizationRequestComments(id);

    setComments(cmnts.data.results);
  };

  useEffect(() => {
    if (
      state.videos &&
      state.videos.type === ActionType.GET_VIDEOS_CUSTOMIZATION_REQUESTS
    ) {
      setLoading(false);

      setPractice(state.videos?.payload?.data?.results[0]);
    }
    if (state.practice.type === ActionType.GET_TEAM_MEMBERS) {
      console.log("=== Members === ");
      console.log(state.practice.payload.data.results);

      setTeamMembers(state.practice.payload.data.results);
    }
  }, [state]);

  /***** UPDATE  *******/

  console.log(teamMembers);

  const changeEditor = (data: any) => {
    console.log(" Change Editor ==== ", data);
    setCurrentEditor(data.name);
    changeCustomizationRequestEditor(id, { editor_id: data.id }).then(() =>
      console.log("== Editor Updated ==")
    );
  };

  const changeStatus = (data: any) => {
    setStatusColor(data.color);
    setCurrentStatus(data.name);
    ChangeCustomizationRequestStatus(id, {
      status: data.id,
      video_id: practice.video_file_id,
      thumbnail_id: practice.thumbnail_id,
      template_id: practice.template_id,
      practice_id: practice.practice_id,
      video_category_id: practice.video_category_id,
      video_title: practice.video_title,
    }).then((res) => {
      console.log("=== Status Update  ===");

      if (res.data.error) {
        toast.error("Final video does not exists");
        setCurrentStatus(null);
      }

      setLoading(true);

      dispatch(getVideoCustomizationRequests({ customization_id: id }));

      console.log(res);
    });
  };

  const addComment = async () => {
    if (comment) {
      setAddingCommentState(true);

      const response = await addCommentToCustomizationRequest({
        comment,
        customization_id: parseInt(id),
      });

      setComment("");

      await fetchComments(id);

      setAddingCommentState(false);
    } else {
      toast.error("Comment can't be empty");
    }
  };

  console.log(practice);

  console.log("=== Questions ===");
  console.log(questions);

  console.log("=== Descriptions ===");

  console.log(descriptions);

  console.log("=== Customization Questions===");

  console.log(customizationQuestions);

  console.log("=== Customization Members ===");

  console.log(customizationMembers);

  return (
    <>
      <Theme>
        <LoadingOverlay active={loading} spinner text="Loading...">
          <div className="container font-['inter'] mb-5">
            <div className="container px-[37px]  w-[1120px] m-[auto]">
              <div className="flex pb-[8px] items-center">
                <h2 className="text-[#212B36] text-[24px] font-[600]">
                  {practice?.video_title}
                </h2>
              </div>
              <p className="block text-[#637381] text-[14px] font-[500]">
                {practice?.practice_code} {practice?.practice_name} - &nbsp;
                <strong>Requested on: </strong>
                {moment(practice?.customization_created_on)
                  .utc()
                  .format("dddd, MMMM Do YYYY, h:mm:ss a")}{" "}
                (UTC)
              </p>
            </div>
            <div className="border pt-[30px] border-x-0 border-t-0" />
            <div className="container flex px-[37px] w-[1120px] m-[auto]">
              <div className="px-[13px] py-[22px] mt-[20px] inline-block border rounded-[8px] h-[540px] w-[415px]">
                <h3 className="text-[16px] font-[500] color-[#212B36] pb-[8px]">
                  Dental Clinic Name
                </h3>
                <h5 className="text-[14px] font-[400] underline color-[#212B36]">
                  {practice?.practice_code} {practice?.practice_name}
                </h5>
                <h3 className="text-[16px] font-[600] color-[#212B36] pt-[26px] pb-[8px]">
                  Submitted Questions
                </h3>
                {customizationQuestions?.length || descriptions?.length ? (
                  <>
                    <h5 className=" mt-3 text-[14px] border-x-0 border-t-0 border font-[400] color-[#212B36] pb-[26px]">
                      {customizationQuestions.map((x) => {
                        return (
                          <>
                            <label>{x.Question}</label>

                            <p className="mt-2">
                              <strong>Answer:</strong> &nbsp; {x.answer}
                            </p>
                          </>
                        );
                      })}

                      {descriptions.map((x, i) => {
                        const member = teamMembers.filter(
                          (x) =>
                            x.team_member_id ===
                            customizationMembers[i]?.team_member_id
                        )[0];

                        return (
                          <>
                            <div className="mt-2">
                              <label><strong>Question:</strong>&nbsp;{x.Description}</label>

                              <p className="mt-2">
                                <strong>Answer:</strong> &nbsp;{" "}
                                {member?.MemberName} &nbsp;|&nbsp;<a className="text-green-700 text-[12px]" href={member?.FilePath} target="_blank">View Photo</a>
                              </p>
                            </div>
                          </>
                        );
                      })}
                    </h5>
                  </>
                ) : (
                  "No submitted questions found"
                )}
                <h3 className="text-[16px] pt-[26px] font-[500] color-[#212B36] pb-[8px]">
                  Status
                </h3>

                {practice?.customization_status === "Complete" ? (
                  <>
                    <div
                      className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3"
                      role="alert"
                    >
                      <p className="font-bold">Completed</p>
                      <p className="text-sm">
                        This customiztion marked completed on{" "}
                        {moment(practice?.completed_on)
                          .utc()
                          .format("dddd, MMMM Do YYYY, h:mm:ss a")}{" "}
                        (UTC)
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      className={`${
                        currentStatus
                          ? statusColor
                          : customization_statuses
                              .filter(
                                (x) => x.name === practice?.customization_status
                              )
                              .shift()?.color
                      } text-[14px] h-[28px] text-white w-[115px] rounded-full`}
                    >
                      {currentStatus
                        ? currentStatus
                        : practice?.customization_status}{" "}
                    </button>
                    &nbsp;{" "}
                    <DropDownMenu
                      items={customization_statuses}
                      setItem={setCurrentStatus}
                      handleClick={changeStatus}
                    />
                  </>
                )}

                <div className="border pt-[26px] border-x-0 border-t-0" />
                <h3 className="text-[16px] pt-[26px] font-[500] color-[#212B36] pb-[8px]">
                  Assigned to
                </h3>
                <div className="inline-flex items-center">
                  {practice?.editor_id && (
                    <Avatar
                      size="50"
                      round={true}
                      name={
                        currentEditor
                          ? currentEditor
                          : practice.editor_first_name +
                            " " +
                            practice?.editor_last_name
                      }
                    />
                  )}

                  <h6 className="font-[600] mx-[10px] text-[16px] text-[#212B36]">
                    {currentEditor
                      ? currentEditor
                      : practice.editor_first_name && practice.editor_last_name
                      ? practice.editor_first_name +
                        " " +
                        practice.editor_last_name
                      : "Not Assigned"}
                  </h6>
                  <DropDownMenu
                    items={editors}
                    setItem={setCurrentEditor}
                    handleClick={changeEditor}
                  />
                </div>
                <div className="border pt-[26px] border-x-0 border-t-0" />
                <button
                  type="button"
                  onClick={() => {
                    if (practice?.final_video_path) {
                      window.open(`${practice?.final_video_path}`, "_blank");
                    } else {
                      toast.error("No Video Available");
                    }
                  }}
                  className="inline-flex mb-5 items-center rounded-md border border-transparent bg-blue-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  {practice?.final_video_path
                    ? "View Uploaded Video"
                    : "No Video Available"}
                  &nbsp;
                  <VideoCameraIcon
                    className="ml-2 -mr-0.5 h-4 w-4"
                    aria-hidden="true"
                  />
                </button>
                <input
                  className="hidden"
                  type="file"
                  onChange={(e) => handleFileChange(e)}
                  ref={fileInput}
                />
                <br />
                <button
                  onClick={handleClick}
                  className=" ml-1 inline-flex mb-5 items-center rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm  "
                >
                  {fileUploading ? (

                    <>
                      Uploading...
                      <svg
                        className=" text-center w-[28px] h-[28px] loader text-white animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </>
                  ) : (
                    <>
                      Upload Final Edited Video &nbsp;
                      <VideoCameraIcon
                        className="ml-2 -mr-0.5 h-4 w-4"
                        aria-hidden="true"
                      />
                    </>
                  )}
                </button>

                <hr/>

                <div className="mt-3 mb-4">
                   <h3> Final Pack: </h3>
                </div>

                <input
                  className="hidden"
                  type="file"
                  onChange={(e) => handleFinalPackUpload(e)}
                  ref={final_pack_input}
                />

                <button
                  disabled={final_pack_uploading}
                  onClick={handleFinalPackInputClick}
                  className=" ml-1 inline-flex mb-5 items-center rounded-md border border-transparent bg-blue-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm  "
                >
                  {final_pack_uploading ? (
                    <>
                      Uploading...
                      <svg
                        className=" text-center w-[18px] h-[18px] loader text-white animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </>
                  ) : (
                    <>
                      Upload &nbsp;
                      <UploadIcon
                        className="ml-2 -mr-0.5 h-4 w-4"
                        aria-hidden="true"
                      />
                    </>
                  )}
                </button>

                <button
                  onClick={() => {
                    if (practice?.final_pack) {
                      window.open(`${practice?.final_pack}`, "_blank");
                    } else {
                      toast.error("No final video pack available");
                    }
                  }}
                  className=" ml-1 inline-flex mb-5 items-center rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm  "
                >
                  
                      Download &nbsp;
                      <DownloadIcon
                        className="ml-2 -mr-0.5 h-4 w-4"
                        aria-hidden="true"
                      />
                </button>
                {/* <p className="mt-1 mb-5">{file.name}</p> */}
                {/* <h3 className="text-[16px] pt-[26px] font-[500] color-[#212B36] pb-[8px]">
                For review
              </h3>
              <h5 className="text-[14px] font-[400] color-[#212B36]">
                No upload yet
              </h5> */}
              </div>
              <div className="px-[13px] pb-[5px] pt-[22px] ml-[20px] mt-[20px] inline-block border rounded-[8px] w-[605px]">
                <h3 className="text-[16px] font-[500] color-[#212B36] pb-[8px]">
                  Comments
                </h3>
                {comments.length ? (
                  <>
                    {comments.map((comment) => {
                      return (
                        <>
                          <div className="inline-flex">
                            <Avatar
                              size="50"
                              round={true}
                              name={
                                comment.user_first_name +
                                " " +
                                comment.user_last_name
                              }
                            />
                            <h5 className="font-[600] mx-[10px] text-[16px] text-[#212B36]">
                              {comment.user_first_name} {comment.user_last_name}
                            </h5>
                          </div>
                          <div className="block">
                            <p className="text-[14px] relative bottom-[18px] ml-[auto] bg-[#F9FAFB] border w-[515px] rounded-[8px] px-[20px] py-[13px] text-[#212B36]">
                              {comment.comment}
                            </p>
                          </div>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <p className="text-center"> No comments found </p>
                )}

                <hr />

                <div className="inline-flex pt-[12px]">
                  <Avatar
                    size="50"
                    round={true}
                    name={user?.data?.FirstName + " " + user?.data?.LastName}
                  />

                  <h5 className="font-[600] mx-[10px] text-[16px] text-[#212B36]">
                    {user?.data?.FirstName} {user?.data?.LastName}
                  </h5>
                </div>
                <form className="w-[515px] ml-[auto]">
                  <div className="px-20px bottom-[18px] relative">
                    <textarea
                      placeholder="Comment here"
                      className="w-full bg-[#F9FAFB] text-[16px] rounded-[8px] border leading-normal resize-none  h-[230px] py-2 px-3 font-[400] placeholder-[#637381] focus:outline-none focus:bg-white"
                      value={comment}
                      disabled={addingCommentState}
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                    />
                    <button
                      onClick={addComment}
                      type="button"
                      className=" absolute left-[13px] bottom-[25px] w-[130px] h-[40px] cursor-pointer rounded-[5px] text-white text-[14px] bg-[#3056D3]"
                    >
                      {addingCommentState ? (
                        <>
                          <svg
                            className=" text-center w-[25px] h-[25px] loader text-white animate-spin"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              stroke-width="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </>
                      ) : (
                        "Add Comment"
                      )}
                    </button>
                    {/* <input
                    className="block bg-right bg-no-repeat bg-[url('https://rollingscents.wpengine.com/wp-content/uploads/2023/05/photograph.png')] absolute right-[13px] w-[30px] ml-[auto] bottom-[25px] cursor-pointer text-[#ffffffff] focus:outline-none"
                    type="file"
                  /> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </Theme>
    </>
  );
};

export default CustomizationVideoDetail;
