import ReactSelect, { components } from 'react-select'
import { cn } from 'util/cn'
import { generateId } from 'util/generated-id'
import { Option } from './types'

const Checkbox = ({ isFocused, isSelected, children, innerProps, ...rest }) => {
	return (
		// @ts-ignore
		<components.Option
			{...rest}
			isFocused={isFocused}
			isSelected={isSelected}
			innerProps={{ ...innerProps }}
		>
			<div className='flex items-center gap-1.5 text-black font-karla font-bold'>
				<div
					className={cn(
						'w-5 aspect-square rounded-[4px] border flex items-center justify-center',
						isSelected ? 'border-tailgrids-indigo' : 'border-tailgrids-gray'
					)}
				>
					<div
						className={cn(
							'bg-tailgrids-indigo w-2.5 aspect-square rounded-[2px]',
							isSelected ? 'block' : 'hidden'
						)}
					/>
				</div>
				{children}
			</div>
		</components.Option>
	)
}

type PropsType = {
	label?: string
	id?: string
	className?: string
	isMulti?: boolean
	value?: string
	onChange?: (value: string) => void
	options?: Option[]
	required?: boolean
	errorMessage?: string
	isSearchable?: boolean
}

const Select = ({
	label,
	className,
	id = generateId(),
	isMulti = false,
	value = '',
	onChange,
	options = [],
	required = false,
	errorMessage = '',
	isSearchable = false,
}: PropsType) => {
	return (
		<div className={cn('space-y-3', className)}>
			{label && (
				<label
					htmlFor={id}
					className='text-tailgrids-black font-karla font-bold block leading-[19px]'
				>
					{required ? <span className='text-accent-700 inline-block mr-0.5'>*</span> : ''}
					{label}
				</label>
			)}
			<div className='space-y-1.5'>
				<ReactSelect
					isMulti={isMulti}
					// @ts-ignore
					options={options}
					name={id}
					closeMenuOnSelect={!isMulti}
					hideSelectedOptions={false}
					placeholder='Choose'
					isSearchable={isSearchable}
					isClearable={false}
					components={{
						// @ts-ignore
						Option: Checkbox,
					}}
					defaultValue={
						isMulti
							? value.split(', ').map((v) => options.find((o) => o.value === v))
							: options.find((o) => o.value === value)
					}
					onChange={(options) => {
						if (Array.isArray(options)) {
							const values = []
							options.forEach(({ value }) => values.push(value))
							return onChange(values.join(', '))
						}
						// @ts-ignore
						onChange(options.value)
					}}
					styles={{
						menu: (baseStyles) => ({
							...baseStyles,
							border: '2px solid black',
							background: 'white',
							borderRadius: '8px',
							overflow: 'hidden',
						}),
						menuList: (baseStyles) => ({
							...baseStyles,
							padding: '8px 0',
						}),
						option: (baseStyles) => ({
							...baseStyles,
							height: '40px',
							display: 'flex',
							alignItems: 'center',
							padding: '0 8px',
							cursor: 'pointer',
						}),
						control: (baseStyles) => ({
							...baseStyles,
							minHeight: '50px',
							padding: '0 22px',
							display: 'flex',
							alignItems: 'center',
							border: errorMessage ? '2px solid #E85151' : '2px solid black',
							borderRadius: '8px',
							fontFamily: 'Karla Variable',
							fontWeight: 'bold',
							cursor: 'pointer',
							'&:hover': {
								borderColor: errorMessage ? '#E85151' : 'black',
							},
						}),
						indicatorSeparator: (baseStyles) => ({
							...baseStyles,
							display: 'none',
						}),
						dropdownIndicator: (baseStyles) => ({
							...baseStyles,
							padding: '8px 0 8px 8px',
							color: 'black',
						}),
						placeholder: (baseStyles) => ({
							...baseStyles,
							color: '#637381',
						}),
						valueContainer: (baseStyles) => ({
							...baseStyles,
							padding: '0',
							margin: '0 -2px',
						}),
						multiValueLabel: (baseStyles) => ({
							...baseStyles,
							color: 'white',
							paddingRight: '6px',
						}),
						multiValue: (baseStyles) => ({
							...baseStyles,
							background: 'black',
							borderRadius: '4px',
							color: 'white',
							overflow: 'hidden',
						}),
						multiValueRemove: (baseStyles) => ({
							...baseStyles,
							'&:hover': {
								color: 'white',
								background: 'black',
							},
						}),
						noOptionsMessage: (baseStyles) => ({
							...baseStyles,
							fontWeight: 'bold',
							color: 'black',
							fontFamily: 'Karla Variable',
						}),
						singleValue: (baseStyles) => ({
							...baseStyles,
							color: '#212B36',
						}),
						input: (baseStyles) => ({
							...baseStyles,
							caretColor: '#3056D3',
							'&>input:focus': {
								boxShadow: 'none',
							},
						}),
					}}
					theme={(theme) => ({
						...theme,
						colors: {
							...theme.colors,
							primary25: '#F2F5F9',
							primary50: '#F2F5F9',
							primary: '#F2F5F9',
						},
					})}
				/>
				{errorMessage && (
					<p className='text-accent-700 text-sm font-bold font-karla text-end'>{errorMessage}</p>
				)}
			</div>
		</div>
	)
}

export default Select
