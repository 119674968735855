import { effect, signal } from '@preact/signals-react'
import { Card, Divider, Input, Textarea, Title } from 'components'
import {
	category,
	categoryMessage,
	description,
	descriptionMessage,
	endDate,
	endDateMessage,
	isDate,
	practice,
	practiceMessage,
	startDate,
	startDateMessage,
	subCategory,
	subCategoryMessage,
	thumbnail,
	thumbnailMessage,
	title,
	titleMessage,
	video,
	videoMessage,
} from 'features/individual'
import { Dropzone } from 'lib/react-dropzone'
import { Option, Select } from 'lib/react-select'
import { Switch } from 'lib/react-switch'
import { DatePicker } from 'lib/shadcn'
import { getVideoCategories } from 'redux/actionCreators/videos'
import { getPractices } from 'services/practices'
import { getNextDay } from 'util/get-next-day'

const categories = signal<Option[]>([])
const practices = signal<Option[]>([])

effect(async () => {
	const _categories = await getVideoCategories()
	categories.value = _categories.data.results.map(({ category_id, category_name }) => ({
		value: category_id,
		label: category_name,
	}))

	const _practices = await getPractices({ page: 1, per_page: 100000 })
	practices.value = _practices.data.results.map(({ practiceId, PracticeName }) => ({
		value: practiceId,
		label: `${practiceId} ${PracticeName}`,
	}))
})

const IndividualForm = () => {
	return (
		<Card>
			<Title size='lg' className='py-5 px-7'>
				Individual Inputs
			</Title>
			<Divider />
			<div className='py-5 px-7 space-y-[26px]'>
				<Input
					label='Title'
					value={title.value}
					onChange={(e) => (title.value = e.target.value)}
					errorMessage={titleMessage.value}
					required
				/>
				<Select
					label='Category'
					onChange={(value) => (category.value = value)}
					errorMessage={categoryMessage.value}
					options={categories.value}
					required
					isSearchable
				/>
				<Select
					label='Sub-category'
					onChange={(value) => (subCategory.value = value)}
					errorMessage={subCategoryMessage.value}
					options={categories.value}
					required
					isMulti
					isSearchable
				/>
				<Textarea
					label='Description'
					value={description.value}
					onChange={(e) => (description.value = e.target.value)}
					errorMessage={descriptionMessage.value}
					required
				/>
				<Select
					label='Practices'
					options={practices.value}
					onChange={(value) => (practice.value = value)}
					errorMessage={practiceMessage.value}
					required
					isMulti
					isSearchable
				/>
				<Dropzone
					label='Upload Thumbnail'
					accept='image'
					onChange={(value) => (thumbnail.value = value)}
					errorMessage={thumbnailMessage.value}
					required
				/>
				<Dropzone
					label='Upload Video'
					accept='video'
					onChange={(value) => (video.value = value)}
					errorMessage={videoMessage.value}
					required
				/>
				<div className='flex gap-[57px]'>
					<Switch
						label='Date Specific'
						checked={isDate.value}
						onChange={() => (isDate.value = !isDate.value)}
					/>
					{isDate.value && (
						<div className='flex gap-4 items-start'>
							<DatePicker
								label='Start Date'
								value={startDate.value}
								onChange={(date) => (startDate.value = date)}
								errorMessage={startDateMessage.value}
								required
							/>
							<p className='font-karla font-bold text-tailgrids-black mt-[46px]'>to</p>
							<DatePicker
								label='End Date'
								value={endDate.value}
								onChange={(date) => (endDate.value = date)}
								disabled={{ before: getNextDay(new Date(startDate.value)) }}
								errorMessage={endDateMessage.value}
								required
							/>
						</div>
					)}
				</div>
			</div>
		</Card>
	)
}

export default IndividualForm
